import React from "react";
import styles from "./styles.module.scss";
import {connect} from "react-redux";
import {AlertObject, AlertType, removeAlert} from "../../store/reducers/alertSlice";
import {alertsSelector, disabledAlertsSelector} from "../../store/selectors/alertSelectors";
import {RootState} from "../../store/reducers";
import {isSameValue} from "../../utils/comparatorUtils";
import Translation from "../Translation";


interface IConnectedToastProps {
    readonly alerts: AlertObject[];
    readonly alertsDisabled: boolean;
    readonly removeAlert: typeof removeAlert;
}

interface IExternalToastProps {
  readonly alertType?: AlertType;
}

interface IToastProps extends IConnectedToastProps, IExternalToastProps {}

interface IToastState {
  alerts: AlertObject[];
  alertsDisabled: boolean;
}

class Toast extends React.Component<IToastProps, IToastState> {
    constructor(props: any) {
        super(props);

        this.state = {
            alerts: this.props.alerts,
            alertsDisabled: this.props.alertsDisabled
        };
    }


    componentDidMount() {
        if (this.props.alerts) {
            this.removeAlerts();
        }
    }

    componentDidUpdate(prevProps: Readonly<IToastProps>): void {
        if (this.props.alerts && !isSameValue(this.props.alerts, prevProps.alerts)) {
            this.removeAlerts();
        }
    }

    render() {
        if (this.props.alertsDisabled || !this.props.alerts.length) {
            return null;
        }

        return (
            <React.Fragment>
                {this.props.alerts.map((alert: AlertObject) => {
                    const message: any = !alert.isTranslated ? alert.message : <Translation text={alert.message}/>,
                        isTypeWarning = this.props.alertType === AlertType.WARNING || alert.type === AlertType.WARNING;

                    return (
                        <div className={`${styles.toast}
                        ${isTypeWarning ? styles.warning : ''}`}
                             key={alert.id}>
                            <div aria-hidden="true" className={styles.infoIcon}>

                            </div>

                            <div className={styles.toastMessage}>
                                {message}
                            </div>

                            <button
                                type="button"
                                onClick={() => this.closeToast(alert.id)}
                                className={`${styles.btnClose}`}>
                                <span className={styles.icon}/>
                            </button>
                        </div>)
                })
                }
            </React.Fragment>
        );
    }

    private closeToast = (id: string) => {
        this.props.removeAlert(id);
    };

    private removeAlerts = () => {
        this.props.alerts.map((alert: AlertObject) => {
            if (alert.displayFor !== 0) {
                setTimeout(() => {
                    this.props.removeAlert(alert.id);
                }, alert.displayFor);
            }

            return alert;
        });
    }
}

export default connect(
    (state: RootState) => ({
        alerts: alertsSelector(state),
        alertsDisabled: disabledAlertsSelector(state)
    }),
    {
        removeAlert
    }
)(Toast);
