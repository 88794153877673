import React from 'react';
import Loader, {LoaderType} from "../Loader";


export enum CustomCardType {
    MODAL_CARD = 'modal-card',
    BASIC = 'basic'
}

interface IConnectedCustomCartProps {
}

interface IExternalCustomCardProps {
    children?: any;
    showLocalLoader?: boolean;
    type?: CustomCardType;
    containerStyle?: string;
}

interface ICustomCardProps extends IConnectedCustomCartProps, IExternalCustomCardProps {
}

const CustomCard = (props: ICustomCardProps) => {
    const type = props.type ? ' ' + props.type : '';
    return (
        <React.Fragment>
            <div className={`custom-card${type} ${props.containerStyle ? props.containerStyle : ''}`}>
                {props.children}

                {props.showLocalLoader && <Loader showLoader={props.showLocalLoader} type={LoaderType.Local}/>}
            </div>
        </React.Fragment>
    )
};

CustomCard.Header = (props: React.PropsWithChildren<{}>) => (
    <div className="custom-card-header">
        <div className="custom-card-title">
            {props.children}
        </div>
    </div>
);

CustomCard.Body = (props: React.PropsWithChildren<{}>) => (
    <div className="custom-card-body">
        {props.children}
    </div>
);

CustomCard.Footer = (props: React.PropsWithChildren<{}>) => (
    <div className="custom-card-footer">
        {props.children}
    </div>
);

export default CustomCard;
