import React from 'react';
import BasicModal from "../../BasicModal";
import Translation from "../../Translation";
import {Formik} from "formik";
import {WithTranslation, withTranslation} from "react-i18next";
import styles from "./styles.module.scss";
import ProfileImage from "../AddAcquaintanceModal/ProfileImage";
import {ImageFormat} from "../../Settings/AccountImageHost";


interface IConnectedAddPhotoModalProps {
}

interface IExternalAddPhotoModalProps {
    readonly isModalShown: boolean;
    readonly toggleModal: () => void;
    readonly selectedContact: any;
}

interface IAddPhotoModalProps extends IConnectedAddPhotoModalProps, IExternalAddPhotoModalProps, WithTranslation {
}

interface IAddPhotoModalState {
    name: string;
}

class AddPhotoModal extends React.Component<IAddPhotoModalProps, IAddPhotoModalState> {
    constructor(props: IAddPhotoModalProps) {
        super(props);

        this.state = {
            name: ''
        }
    }

    render() {
        const {t} = this.props;

        return (
            <BasicModal isModalShown={this.props.isModalShown} closeModal={this.props.toggleModal}>
                <BasicModal.Header>
                    <Translation text="acquaintances.addPhoto"/>

                    <button className="btn btn-close" onClick={() => this.props.toggleModal()}/>
                </BasicModal.Header>

                <div className={styles.acquaintanceModal}>
                    <div className={styles.acquaintanceDetails}>
                        <Formik
                            initialValues={{firstName: this.props.selectedContact.name, lastName: this.props.selectedContact.surname}}
                            onSubmit={() => {}}>
                            {({values, handleSubmit}) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="form-control add-photo-modal">
                                        <label><Translation text="form.labels.firstName" /></label>
                                        <input
                                            type="text"
                                            value={values.firstName}
                                            name="firstName"
                                            disabled={true}
                                            placeholder={t('form.placeholders.type')}
                                        />
                                    </div>

                                    <div className="form-control add-photo-modal">
                                        <label><Translation text="form.labels.lastName" /></label>
                                        <input
                                            type="text"
                                            value={values.lastName}
                                            name="lastName"
                                            disabled={true}
                                            placeholder={t('form.placeholders.type')}
                                        />
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>

                    <ProfileImage acquaintanceId={this.props.selectedContact.id}
                                  profileImages={this.props.selectedContact.avatar}
                                  acceptedImageFormat={ImageFormat.JPEG}/>
                </div>
            </BasicModal>
        );
    }
}

export default withTranslation()(AddPhotoModal);
