export const REACT_APP_NODE_CHAT = 'http://192.168.0.80:9000';
export const REACT_APP_NODE_CHAT_PRODUCTION='https://oko.solvee.pl:9007';

export const REACT_APP_STUN_1_URL='stun:stun.l.google.com:19302';
export const REACT_APP_STUN_2_URL='stun:stun1.l.google.com:19302';

export const REACT_APP_TURN_1_URL='turn:turn.solvee.pl';
export const REACT_APP_TURN_1_CREDENTIAL='stolec123';
export const REACT_APP_TURN_1_USERNAME='solvee';

export const REACT_APP_NODE_RECONNECTION_DELAY = 15000;
