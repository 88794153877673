import React from 'react';
import styles from './styles.module.scss';
import Translation from '../../../Translation';
import {connect} from "react-redux";
import {changeCurrentView, CurrentViewType} from "../../../../store/reducers/sagaSlice";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {accountIdSelector} from "../../../../store/selectors/accountSelectors";
import {RootState} from "../../../../store/reducers";

interface IConnectedContactItemMenuProps {
    readonly changeCurrentView: typeof changeCurrentView;
    readonly userId: string | number | null;
}

interface IExternalContactItemMenuProps {
    readonly item: any;
    readonly toggleMenu: () => void;
    readonly toggleRemoveContactModal: () => void;
    readonly toggleContactProfileModal: () => void;
    readonly setSelectedChatRoomId: (id: string | number | null) => void;
    readonly inviteUser: (invitedId: string | number, invite?: boolean) => void
}

interface IContactItemMenuProps extends IConnectedContactItemMenuProps, IExternalContactItemMenuProps, RouteComponentProps {
}

interface IContactItemMenuState {
}

class ContactItemMenu extends React.Component<IContactItemMenuProps, IContactItemMenuState> {
    private menuRef: any;
    constructor(props: IContactItemMenuProps) {
        super(props);

        this.state = {
        };

        this.menuRef = React.createRef();
    }

    componentDidMount(): void {
        document.addEventListener('mousedown', this.handleOutsideClick);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleOutsideClick);
    }

    render() {
        return (
            <div className={styles.contactMenu} ref={this.menuRef}>
                <div className={styles.menuItem}>
                    <button onClick={() => this.redirectToVideo()}>
                        <Translation text="contacts.menu.startVideo" />
                    </button>
                </div>
                <div className={styles.menuItem}>
                    <button onClick={() => this.props.toggleContactProfileModal()}>
                        <Translation text="contacts.menu.showProfile" />
                    </button>
                </div>
                <div className={styles.menuItem}>
                    <button onClick={() => this.props.toggleRemoveContactModal()}>
                        <Translation text="contacts.menu.remove" />
                    </button>
                </div>
            </div>
        );
    };

    private redirectToVideo = () => {
        this.props.history.push("/panel/video");
        this.props.inviteUser(this.props.item.user.id)
        this.props.setSelectedChatRoomId(this.props.userId)
        this.props.changeCurrentView(CurrentViewType.VIDEO);
    };

    private handleOutsideClick = (event: any) => {
        const isWithinMenuContent = event.composedPath().includes(this.menuRef.current);
        if (this.menuRef && this.menuRef.current && !isWithinMenuContent) {
            this.props.toggleMenu();
        }
    };
}

export default connect(
    (state: RootState) => ({
        userId: accountIdSelector(state)
    }),
    {
        changeCurrentView
    }
)(withRouter(ContactItemMenu));
