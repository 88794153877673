export function defaultOnNullOrUndefined<T = any>(value: T, defaultValue: T) {
    return isNullOrUndefined(value) ?
        defaultValue :
        value;
}

export function isNotNullOrUndefined(value: any) {
    return !isNull(value) && !isUndefined(value);
}

export function isNullOrUndefined(value: any) {
    return isNull(value) || isUndefined(value);
}

export function isNull(value: any): value is null {
    return null === value;
}

export function isUndefined(value: any): value is undefined {
    return undefined === value;
}

export function isEmpty(value: any): boolean {
    return isNullOrUndefined(value) || '' === value || (Array.isArray(value) && 0 === value.length);
}

export function isNotEmpty(value: any): boolean {
    return !isEmpty(value);
}
