import {Observable} from 'rxjs';
import {okoUserPanelAPI} from './provider/okoUserPanelAPI';
import {RestQueryParams} from './base/queryParams';
import {IUpdateAccountPayload} from '../store/reducers/accountSlice'

export function updateUserDataAPI(authToken: string | null, id: string, payload: IUpdateAccountPayload): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return okoUserPanelAPI.put(
        `new-api/shop_users/${id}`,
        payload,
        new RestQueryParams(),
        headers
    );
}
