import React from "react";
import {
	AllUserConnections,
	ChatContact,
	ChatError,
	ConnectionState, FriendRequest, FriendRequestPayload, FriendRequestsTypes,
	Invite,
	PeerConnection,
	SignalAnswer,
	SignalCandidate,
	SignalOffer,
	SocketMessages,
	WithChatConnectionAddedProps
} from "../chatTypes";
import {of, Subject} from "rxjs";
import {
	REACT_APP_NODE_CHAT, REACT_APP_NODE_RECONNECTION_DELAY,
	REACT_APP_STUN_1_URL,
	REACT_APP_STUN_2_URL,
	REACT_APP_TURN_1_CREDENTIAL,
	REACT_APP_TURN_1_URL,
	REACT_APP_TURN_1_USERNAME
} from "../chatConfig";
import {io, Socket} from "socket.io-client";
import merge from 'lodash/merge';
import NULL_PEER_CONNECTION from "../NullConnection";


interface IWithChatConnectionProps {
	userId: string;
	authToken: string | null;
	RTCConfiguration?: any; // RTCConfiguration
	errorHandler?: (errorMessage: string) => any;
	// lastSentInvite?: string;
}

interface IWithChatConnectionState {
	userPeerConnections: AllUserConnections;
	chunksArrays: {[roomId: string]: ArrayBuffer[]};
	selectedChatRoomId: string | null;
	alert: string[];
	nodeServerConnected: boolean;
	allowedChannels: ChatContact[];
	hadConnectionError: boolean;
	localMediaDevices: MediaStream | undefined;
	selectedCameraId: string;
	isCameraSwitching: boolean;
}

export default <P extends WithChatConnectionAddedProps>(
	ConnectedComponent: React.ComponentType<P>,
	socketURLWithPort?: string,
) =>
	class WithChatConnection extends React.Component<
		IWithChatConnectionProps,
		IWithChatConnectionState
		> {
		private socket: Socket | null = null;
		private errors: Subject<ChatError> = new Subject();
		private invites = new Subject<Invite>();
		private serverCredentials: RTCIceServer[] | null = null;
		private friendRequests = new Subject<FriendRequest>();

		private configuration: RTCConfiguration = this.props.RTCConfiguration || {
			iceServers: [
				{urls: [REACT_APP_STUN_1_URL, REACT_APP_STUN_2_URL]},
				{
					urls: REACT_APP_TURN_1_URL,
					credential: REACT_APP_TURN_1_CREDENTIAL,
					username: REACT_APP_TURN_1_USERNAME,
				},
			],
		};

		constructor(props: IWithChatConnectionProps) {
			super(props);
			this.state = {
				allowedChannels: [],
				userPeerConnections: {},
				chunksArrays: {},
				selectedChatRoomId: '',
				alert: [],
				nodeServerConnected: false,
				hadConnectionError: false,
				localMediaDevices: undefined,
				selectedCameraId: '',
				isCameraSwitching: false,
			};

		}

		componentDidMount() {
			this.handleSocketConnection();
		}

		componentDidUpdate(
			prevProps: IWithChatConnectionProps,
			prevState: IWithChatConnectionState,
		) {
			if (prevState.selectedCameraId !== this.state.selectedCameraId) {
				//todo remove
				// this.state.localMediaDevices.release();
				this.getLocalMediaDevices(true);
			}

			if (this.state.selectedChatRoomId !== prevState.selectedChatRoomId) {
				if (
					this.state.selectedChatRoomId === null &&
					prevState.selectedChatRoomId !== null
				) {
					this.invites.next({userId: prevState.selectedChatRoomId, add: false});
					this.socket?.emit(SocketMessages.LEAVE_CHANNEL, {
						channelId: prevState.selectedChatRoomId,
					});
					return this.closeConnection(prevState.selectedChatRoomId);
				}

				if (this.state.selectedChatRoomId !== null) {
					if (prevState.selectedChatRoomId !== null) {
						this.invites.next({userId: prevState.selectedChatRoomId, add: false});
						this.closeConnection(prevState.selectedChatRoomId);
					}
					this.getLocalMediaDevices(true)
						.then(() => {
							if (this.state.selectedChatRoomId) {
								this.joinConference(this.state.selectedChatRoomId);
								// if (this.props.lastSentInvite && this.state.selectedChatRoomId === this.props.userId) {
								// 	this.inviteUserToggle(this.props.lastSentInvite, false);
								// }
							}
						})
						.catch(e => console.log('error in join ', e));
				}
			}

			if (
				prevState.localMediaDevices !== this.state.localMediaDevices &&
				prevState.localMediaDevices !== null
			) {
				// console.log('**** DCU R ****');
				// this.renegotiateConnections();
			}

			if (prevState.nodeServerConnected && !this.state.nodeServerConnected) {
				//todo
				Object.keys(this.state.userPeerConnections).forEach((room: string) =>
					this.closeConnection(room),
				);
			}

		}

		componentWillUnmount() {
			this.socket?.disconnect();
			Object.keys(this.state.userPeerConnections).forEach((room: string) =>
				this.closeConnection(room),
			);
		}

		render() {
			return (
				<ConnectedComponent
					{...((this.props as unknown) as P)}
					errors={this.errors}
					invites={this.invites}
					inviteUser={this.inviteUserToggle}
					hasNodeConnection={!!this.socket?.connected}
					selectedChatRoomId={this.state.selectedChatRoomId}
					setSelectedChatRoomId={this.setSelectedChatRoomId}
					peerConnections={this.state.userPeerConnections}
					localMediaDevices={this.state.localMediaDevices}
					setCameraId={this.setCameraId}
					selectedCameraId={this.state.selectedCameraId}
					joinConference={this.joinConference}
					kickUser={this.kickUser}
					restartConnection={this.restartConnection}
					closeConnection={(room: string) => this.closeConnection(room)}
					isCameraSwitching={this.state.isCameraSwitching}
					friendInvitesObservableSubject={this.friendRequests}
					sendFriendRequestChange={this.sendFriendRequestChange}
				/>
			);
		}

		private joinConference = (channelId: string | number) => {
			if (
				this.state.selectedChatRoomId &&
				this.state.userPeerConnections[this.state.selectedChatRoomId]
					?.connection?.iceConnectionState !== 'connected'
			) {
				this.socket?.emit(SocketMessages.JOIN_CHANNEL, {
					channelId,
					userId: this.props.userId,
				});
			}
		};

		private setCameraId = (selectedCameraId: string) => {
			this.setState({selectedCameraId});
		};

		private async getLocalMediaDevices(audio = false) {
			this.setState({isCameraSwitching: true});
			const constraints: MediaStreamConstraints = {
				audio: audio,
				video: { deviceId: { exact: this.state.selectedCameraId } } ,
			};
			if(!this.state.selectedCameraId ) {
				return this.getUserMedia({audio: true, video: false}).then(this.success).catch(this.failure);
			}
			return this.getUserMedia(constraints).then(this.success).catch(this.failure);
		}

		private async getUserMedia(constraints: any): Promise<MediaStream | undefined> {
			try {
				return await navigator.mediaDevices.getUserMedia(constraints);
			} catch(err) {
				console.log('TODO: error handling. ERROR ON GETTING Devices ', err);
			}
		}

		private success = (stream: MediaStream | undefined) => {
			if (this.state.localMediaDevices !== null && this.state.selectedChatRoomId) {
				const peerConnection = this.state.userPeerConnections[
					this.state.selectedChatRoomId
					].connection;
				if (peerConnection) {
					console.log('STREAM TO ADD TRACK ', stream);
					stream?.getTracks().forEach(track => peerConnection.addTrack(track))
				}
			}
			this.setState({
				localMediaDevices: stream,
				isCameraSwitching: false,
			});
		};

		private failure = (error: Error) => {
			console.log('user media failure')
			this.setState({isCameraSwitching: false});
		};

		private handleSocketConnection() {
			this.socket = io(socketURLWithPort || REACT_APP_NODE_CHAT, {
				reconnectionDelay: REACT_APP_NODE_RECONNECTION_DELAY,
				auth: cb => cb({token: this.props.authToken}),
			});
			if (this.socket instanceof Socket) {
				this.socket.on(SocketMessages.CONNECT, () => {
					console.log('in node');
					this.setState({nodeServerConnected: true});
				});

				this.socket.on(SocketMessages.TURN_CREDENTIALS, (credentials: RTCIceServer[] | null) => {
					// if (credentials) {
					// 	this.serverCredentials = credentials;
					// }
				});

				this.socket.on(SocketMessages.DISCONNECT, (reason: Socket.DisconnectReason) => {
					// this.handleConnectionError(reason);
					this.setState({nodeServerConnected: false})
				});

				this.socket.on(SocketMessages.USER_HAS_LEFT, ({room, reason}: {room: string; reason: string | null}) => {
					if (reason && reason !== 'client namespace disconnect') {
						return;
					}
					this.closeConnection(room);
				});

				this.socket?.on(SocketMessages.INVITE_FRIEND_REQUEST, ({friendUserId}: FriendRequestPayload) => {
					console.log('GOT invite ', friendUserId);
					this.friendRequests.next({
						friendUserId,
						type: FriendRequestsTypes.INVITE_FRIEND_REQUEST,
					});
				});

				this.socket?.on(SocketMessages.INVITE_FRIEND_REQUEST_CONFIRMED, ({friendUserId}: FriendRequestPayload) => {
					this.friendRequests.next({
						friendUserId,
						type: FriendRequestsTypes.INVITE_FRIEND_REQUEST_CONFIRMED,
					});
				});

				this.socket?.on(SocketMessages.INVITE_FRIEND_REQUEST_REJECTED, ({friendUserId}: FriendRequestPayload) => {
					this.friendRequests.next({
						friendUserId,
						type: FriendRequestsTypes.INVITE_FRIEND_REQUEST_REJECTED,
					});
				});

				this.socket.on(SocketMessages.HOST_OFFLINE, (room: string) => {
					this.updatePeerConnection({connectionState: ConnectionState.CONNECTION_ENDED}, room);
					this.invites.next({userId: room, add: false});
				});

				this.socket.on(SocketMessages.INVITE_TO_CONFERENCE, (inviterId: string) => {
					this.invites.next({userId: inviterId, add: true});
				});

				this.socket.on(SocketMessages.CANCEL_CONFERENCE_INVITE, (inviterId: string) => {
					this.invites.next({userId: inviterId, add: false});
				});

				this.socket.on(SocketMessages.INVITED_USER_IN_CALL, (roomId: string) => {
					this.updatePeerConnection({connectionState: ConnectionState.INVITE_SENT_TO_USER_IN_CALL}, roomId);
				});

				this.socket.on('got_kicked', ({channelId}: {channelId: string}) => {
					this.setState({selectedChatRoomId: null});
				});
				this.socket.on(SocketMessages.ROOM_NOT_READY, (roomId: string) => {
					console.log('NOT READY ', roomId, 'this.state.selectedChatRoomId ', this.state.selectedChatRoomId);
					setTimeout(() => {
						if (this.state.selectedChatRoomId) {
							this.socket?.emit(SocketMessages.JOIN_CHANNEL, {
								channelId: roomId,
								userId: this.props.userId,
							});
						}
					}, 15000);
				});

				// Handling token expiration and multi login
				this.socket.on(SocketMessages.CONNECT_ERROR, (error: ChatError) => {
					console.log('error CONNECT_ERROR', error);
					if (error.message === 'already_online') {
						setTimeout(() => {
							this.socket?.connect();
						}, 3000);
					}
					this.errors.next(error);
				});

				this.socket.on(SocketMessages.RESTART_CONNECTION, (toRoom: string) => {
					this.closeConnection(toRoom, true);
					this.setState({isCameraSwitching: true});
				});

				this.socket.on(SocketMessages.RESTART_CONNECTION_DONE, (toRoom: string) => {
					if (toRoom === this.props.userId) {
						this.sendOffer(toRoom);
					}
					this.setState({isCameraSwitching: false});
				});

				this.socket.io.on('reconnect', () => {
					this.setState(() => ({hadConnectionError: true}));
				});

				this.socket.on(
					SocketMessages.CHANNEL_JOINED,
					({id, clientsNumber, channelId}: any) => {
						if (clientsNumber > 1) {
							this.updatePeerConnection({peerIsOnline: true}, channelId);
						}
					},
				);

				this.socket.on(
					SocketMessages.ANSWER,
					async ({toRoom, answer}: SignalAnswer) => {
						const remoteDesc = await new RTCSessionDescription(answer);
						const peerConnection = this.state.userPeerConnections[toRoom]
							.connection;
						await (peerConnection as RTCPeerConnection).setRemoteDescription(remoteDesc);
						this.updatePeerConnection({connection: peerConnection}, toRoom);
					},
				);

				this.socket.on(
					SocketMessages.CANDIDATE,
					async ({toRoom, candidate}: SignalCandidate) => {
						try {
							if (!candidate || !toRoom) {
								return;
							}
							const peerConnection = this.state.userPeerConnections[toRoom]
								.connection;
							if (peerConnection) {
								await peerConnection.addIceCandidate(
									new RTCIceCandidate(candidate),
								);
								this.updatePeerConnection({connection: peerConnection}, toRoom);
							}
						} catch (e) {
							return;
						}
					},
				);

				this.socket.on(
					SocketMessages.OFFER,
					async ({toRoom, offer}: SignalOffer) => {
						const connectionRoom = this.state.userPeerConnections?.[toRoom];
						if (
							connectionRoom.connection &&
							connectionRoom.makingOffer &&
							!connectionRoom.peerIsPolite
						) {
							return;
						}
						let inboundStream = new MediaStream ();
						const peerConnection =
							this.state.userPeerConnections?.[toRoom]?.connection ||
							new RTCPeerConnection(
								this.serverCredentials
									? {
										iceServers: [...this.serverCredentials],
									}
									: this.configuration,
							);

						peerConnection.ontrack = (e: any) => {
							const [track] = e.streams
							if(track) {
								console.log('web mobile ?')
								this.updatePeerConnection({connection: peerConnection, remoteStream: track}, toRoom);
							} else {
								console.log('web web ?')
								inboundStream.addTrack(e.track);
								this.updatePeerConnection({connection: peerConnection, remoteStream: inboundStream}, toRoom);
							}
						}

						if (this.state.localMediaDevices && !this.state.userPeerConnections?.[toRoom]?.connection) {
							this.state.localMediaDevices.getTracks().forEach(track => peerConnection.addTrack(track))
						}

						peerConnection.onicecandidate = ({candidate}: SignalCandidate) =>
							this.socket?.emit(SocketMessages.CANDIDATE, {toRoom, candidate});

						peerConnection.oniceconnectionstatechange = () => {
							// console.log('STANY W ICE offer: ', peerConnection.iceConnectionState);
							if (!this.state.nodeServerConnected  && !this.state.userPeerConnections[toRoom].callStarted) {
								return this.updatePeerConnection({connectionState: ConnectionState.CONNECTION_FAILED}, toRoom);
							}

							if (peerConnection.iceConnectionState === 'failed') {
								if (this.state.userPeerConnections[toRoom].callStarted) {
									return this.updatePeerConnection({connectionState: ConnectionState.CONNECTION_ERROR}, toRoom);
									// return this.socket?.emit(SocketMessages.CONNECTION_PROBLEMS_DETECTED, this.props.userId);
								}
								if (
									this.state.userPeerConnections?.[toRoom]?.connectionAttemptNumber < 1 &&
									this.state.selectedChatRoomId === this.props.userId
								) {
									console.log('Failed once, retry!');
									this.sendOffer(toRoom);
								}
								this.updatePeerConnection(
									{connectionState: ConnectionState.CONNECTION_FAILED, connectionAttemptNumber: 1},
									toRoom,
								);
							}
							if (peerConnection.iceConnectionState === 'connected') {
								this.updatePeerConnection(
									{connectionState: ConnectionState.CONNECTED, connectionAttemptNumber: 0, callStarted: true},
									toRoom,
								);
							}
							if (
								peerConnection.iceConnectionState === 'disconnected' &&
								this.state.userPeerConnections?.[toRoom]?.callStarted
							) {
								this.updatePeerConnection({connectionState: ConnectionState.CONNECTION_ERROR}, toRoom);
							}

						};

						peerConnection.onnegotiationneeded = async () => {
							try {
								this.updatePeerConnection({makingOffer: true}, toRoom);

								const offer = await peerConnection.createOffer();
								await peerConnection.setLocalDescription(offer);

								if (this.state.localMediaDevices) {
									this.state.localMediaDevices.getTracks().forEach(track => peerConnection.addTrack(track))
								}

								this.updatePeerConnection({connection: peerConnection, connectionState: ConnectionState.CONNECTING}, toRoom);
								this.socket?.emit(SocketMessages.OFFER, {toRoom, offer});
							} catch (err) {
								//todo
								return
							} finally {
								this.updatePeerConnection({makingOffer: false}, toRoom);
							}
						};

						await peerConnection.setRemoteDescription(new RTCSessionDescription(offer));
						const answer = await peerConnection.createAnswer();
						await peerConnection.setLocalDescription(answer);
						this.updatePeerConnection({connection: peerConnection}, toRoom);
						this.socket?.emit(SocketMessages.ANSWER, {toRoom, answer});
					},
				);

				this.socket.on(
					SocketMessages.USER_JOINED_CHANNEL,
					(userId: string, room: string) => {
						console.log('Have one! ', room, typeof room, userId);
						this.updatePeerConnection({peerIsOnline: true, userInRoom: userId, peerIsPolite: true}, room);
						this.sendOffer(room).catch((err: Error) => console.log('error USER_JOINED_CHANNEL ', err));
					},
				);
			}
		}

		private handleError(error: ChatError) {
			this.errors.next(error);
			return of('');
		}

		private kickUser = (userId: string | number) => {
			console.log('kick userId ', userId);
			this.socket?.emit(SocketMessages.KICK_USER, {
				userId,
				channelId: this.state.selectedChatRoomId,
			});
		};

		private async sendOffer(room: string) {
			let inboundStream = new MediaStream ();
			if (this.state.userPeerConnections[room].makingOffer) {
				return;
			}
			const peerConnection = new RTCPeerConnection(
				this.serverCredentials
					? {
						iceServers: [...this.serverCredentials, {urls: REACT_APP_STUN_1_URL}],
					}
					: this.configuration,
			);

			if (this.state.localMediaDevices) {
				console.log('LOCAL ', this.state.localMediaDevices, this.state.localMediaDevices.getTracks())
				await this.state.localMediaDevices.getTracks().forEach(track => peerConnection.addTrack(track))
			}

			peerConnection.oniceconnectionstatechange = () => {
				// console.log('STANY W ICE: ', peerConnection.iceConnectionState);
				if (!this.state.nodeServerConnected  && !this.state.userPeerConnections[room].callStarted) {
					return this.updatePeerConnection({connectionState: ConnectionState.CONNECTION_FAILED}, room);
				}

				if (peerConnection.iceConnectionState === 'failed') {
					if (this.state.userPeerConnections[room].callStarted) {
						return this.updatePeerConnection({connectionState: ConnectionState.CONNECTION_ERROR}, room);
						// return this.socket?.emit(SocketMessages.CONNECTION_PROBLEMS_DETECTED, this.props.userId);
					}
					if (
						this.state.userPeerConnections?.[room]?.connectionAttemptNumber < 1 &&
						this.state.selectedChatRoomId === this.props.userId
					) {
						console.log('Failed once, retry!');
						this.sendOffer(room);
					}
					this.updatePeerConnection(
						{connectionState: ConnectionState.CONNECTION_FAILED, connectionAttemptNumber: 1},
						room,
					);
				}
				if (peerConnection.iceConnectionState === 'connected') {
					this.updatePeerConnection(
						{connectionState: ConnectionState.CONNECTED, connectionAttemptNumber: 0, callStarted: true},
						room,
					);
				}
				if (
					peerConnection.iceConnectionState === 'disconnected' &&
					this.state.userPeerConnections?.[room]?.callStarted
				) {
					this.updatePeerConnection({connectionState: ConnectionState.CONNECTION_ERROR}, room);
				}
			};

			peerConnection.ontrack = (e) => {
				const [track] = e.streams
				if(track) {
					console.log('web mobile ?')
					this.updatePeerConnection({connection: peerConnection, remoteStream: track}, room);
				} else {
					console.log('web web ?')
					inboundStream.addTrack(e.track);
					this.updatePeerConnection({connection: peerConnection, remoteStream: inboundStream}, room);
				}

			}

			peerConnection.onnegotiationneeded = async () => {
				try {
					this.updatePeerConnection({connectionState: ConnectionState.CONNECTING, makingOffer: true}, room);

					const offer = await peerConnection.createOffer();
					await peerConnection.setLocalDescription(offer);

					// if (this.state.localMediaDevices) {
					// 	await this.state.localMediaDevices.getTracks().forEach(track => peerConnection.addTrack(track))
					// }

					this.updatePeerConnection({connectionState: ConnectionState.CONNECTING, connection: peerConnection}, room);
					this.socket?.emit(SocketMessages.OFFER, {toRoom: room, offer});
				} catch (err) {
					console.log('ERROR ', err)
					//todo
					return
				} finally {
					this.updatePeerConnection({makingOffer: false}, room);
				}
			};

			peerConnection.onicecandidate = ({candidate}: SignalCandidate) =>
				this.socket?.emit(SocketMessages.CANDIDATE, {
					toRoom: room,
					candidate,
				});


		}

		private updatePeerConnection(
			update: Partial<PeerConnection> ,
			toRoom: string,
		) {
			const updatedConnection = merge({},NULL_PEER_CONNECTION, {channelId: toRoom}, this.state.userPeerConnections[toRoom], update)

			this.setState(state => ({
				userPeerConnections: {
					...state.userPeerConnections,
					[toRoom]: updatedConnection
				},
			}));
		}

		private closeConnection(room: string, restart: boolean = false) {
			console.log('CLOSING CONNECTION');
			const connection: PeerConnection = this.state.userPeerConnections?.[room];
			if (!connection || !connection.connection) {
				return;
			}
			connection.connection?.close();

			const closedConnection = Object.assign({}, NULL_PEER_CONNECTION, {
				connectionState: restart ? ConnectionState.RECONNECTING : ConnectionState.CONNECTION_ENDED,
				channelId: room,
			});

			this.updatePeerConnection(closedConnection, room);
		}

		private inviteUserToggle = (invitedId: string | number, invite = true) => {
			this.socket?.emit(invite ? SocketMessages.INVITE_TO_CONFERENCE : SocketMessages.CANCEL_CONFERENCE_INVITE, {
				invitedId,
				inviterId: this.props.userId,
			});
		}

		private setSelectedChatRoomId = (selectedChatRoomId: string | null) => {
			const roomId = selectedChatRoomId ? selectedChatRoomId.toString() : this.state.selectedChatRoomId;
			if (roomId) {
				return this.setState(state => ({
					selectedChatRoomId: selectedChatRoomId,
					alert: state.alert.filter(id => id !== selectedChatRoomId),
					userPeerConnections: {
						...state.userPeerConnections,
						[roomId]: {
							...state.userPeerConnections[roomId],
							connectionState: ConnectionState.INVITE_SENT,
						},
					},
				}));
			}
		};

		private handleConnectionError(reason: Socket.DisconnectReason) {
			console.log('enter in disconnect info');
			const selectedChatRoomId = this.state.selectedChatRoomId;
			if (selectedChatRoomId) {
				const connection = this.state.userPeerConnections[selectedChatRoomId];
				if (connection.connectionState !== ConnectionState.CONNECTED) {
					this.updatePeerConnection({connectionState: ConnectionState.CONNECTION_ERROR}, selectedChatRoomId);
				}
			}
		}

		private restartConnection() {
			const conferenceId = this.state.selectedChatRoomId;
			if (!conferenceId) {
				//todo switch camera in normal
				return;
			}
			if (this.state.isCameraSwitching) {
				console.log('restartConnection cancel');
				// Peer is switching camera
				return;
			}
			this.socket?.emit(SocketMessages.RESTART_CONNECTION, {
				toRoom: conferenceId,
			});
			this.closeConnection(conferenceId, true);
			this.getLocalMediaDevices(true)
				.then(() => {
					if (conferenceId === this.props.userId) {
						return this.sendOffer(conferenceId);
					}
					this.socket?.emit(SocketMessages.RESTART_CONNECTION_DONE, {
						toRoom: conferenceId,
					});
				})
				.catch(() => console.log('on switch camera local error'));
		}


		private sendFriendRequestChange = (request: FriendRequest) => {
			const serverRequest = {friendUserId: request.friendUserId, userId: this.props.userId};
			switch (request.type) {
				case FriendRequestsTypes.INVITE_FRIEND_REQUEST:
					this.socket?.emit(request.type, serverRequest);
					break;
				case FriendRequestsTypes.INVITE_FRIEND_REQUEST_CONFIRMED:
					this.socket?.emit(request.type, serverRequest);
					break;
				case FriendRequestsTypes.INVITE_FRIEND_REQUEST_REJECTED:
					this.socket?.emit(request.type, serverRequest);
					break;
				default:
					return;
			}
		};
};

