import React from 'react';
import styles from './styles.module.scss';
import Loader, {LoaderType} from "../Loader";
import {Formik} from "formik";
import {WithTranslation, withTranslation} from "react-i18next";
import Translation from "../Translation";
import ContactListItem from "./ContactListItem";
import AddContactModal from "./AddContact";
import {connect} from "react-redux";
import {RootState} from '../../store/reducers';
import {
    friendsContactListErrorSelector,
    friendsContactListLoadingSelector,
    friendsContactListSelector
} from '../../store/selectors/friendsContactListSelectors';
import {IModelFriend} from "../../model/friend";
import {isSameValue} from "../../utils/comparatorUtils";
import {refreshFriendsContactList} from "../../store/reducers/friendsContactListSlice";
import {FriendRequest} from "../../webrtcVideoChat/chatTypes";


interface IConnectedContactsProps {
    readonly friendsContactList: IModelFriend[] | [];
    readonly isListLoading: boolean;
    readonly error: string | null;
    readonly refreshFriendsContactList: typeof refreshFriendsContactList;
}

interface IExternalContactsProps {
    readonly setSelectedChatRoomId: (id: string | number | null) => void;
    readonly inviteUser: (invitedId: string | number, invite?: boolean) => void
    readonly sendFriendRequestChange: (request: FriendRequest) => void
}

interface IContactsProps extends IConnectedContactsProps,
    IExternalContactsProps,
    WithTranslation {
}

interface IContactsState {
    readonly isProcessing: boolean;
    readonly searchValue: string;
    readonly isAddContactModalShown: boolean;
    readonly contactList: IModelFriend[] | [];
}

class Contacts extends React.Component<IContactsProps, IContactsState> {
    constructor(props: IContactsProps) {
        super(props);

        this.state = {
            isProcessing: false,
            searchValue: '',
            isAddContactModalShown: false,
            contactList: []
        };
    }

    componentDidMount(): void {
        const invitedContacts = this.props.friendsContactList.filter((item: IModelFriend) => item.accepted === null && item.inviter || item.accepted);
        this.setState({contactList: invitedContacts});
    }

    componentDidUpdate(prevProps: Readonly<IContactsProps>, prevState: Readonly<IContactsState>, snapshot?: any): void {
        if (!isSameValue(this.props.friendsContactList, prevProps.friendsContactList)) {
            const invitedContacts = this.props.friendsContactList.filter((item: IModelFriend) => item.accepted === null && item.inviter || item.accepted);
            this.setState({contactList: invitedContacts});
        }
    }

    render() {
        const {t} = this.props;

        return (
            <>
                <div className={styles.contacts}>
                    <Loader showLoader={this.props.isListLoading} type={LoaderType.Local}/>

                    <div className={styles.searchContainer}>
                        <Formik
                            initialValues={{search: this.state.searchValue}}
                            onSubmit={(values) => {
                                this.onInputChange(values.search);
                            }}
                        >
                            {({
                                  values,
                                  handleSubmit,
                                  setFieldValue,

                              }) => (
                                <form onSubmit={handleSubmit}>
                                    <input
                                        type="text"
                                        onChange={changeEvent => {
                                            setFieldValue('search', changeEvent.target.value);
                                            this.onInputChange(changeEvent.target.value);
                                        }}
                                        value={values.search}
                                        name="search"
                                        placeholder={t('form.placeholders.search')}
                                        className="input-search"
                                    />
                                </form>
                            )}
                        </Formik>
                    </div>

                    <div className={styles.contactList}>
                        <h3 className={styles.title}>
                            <Translation text="contacts.title"/>
                        </h3>

                        {this.props.error ?
                            <p className={styles.error}><Translation text={this.props.error}/></p> : null}

                        <div>
                            {this.contacts.map((item: any) => {
                                return <ContactListItem setSelectedChatRoomId={this.props.setSelectedChatRoomId}
                                                        inviteUser={this.props.inviteUser}
                                                        key={item.id} item={item}/>
                            })}

                            {!this.state.contactList.length && !this.props.error && !this.props.isListLoading ?
                                <p className={styles.noContacts}><Translation text="contacts.noContacts"/></p>
                                : null
                            }
                        </div>
                    </div>

                    <div className={styles.contactFooter}>
                        <button className="btn btn-primary"
                                onClick={() => this.addContact()}>
                            <Translation text="contacts.addNewContact"/>
                        </button>
                    </div>
                </div>

                {this.state.isAddContactModalShown ?
                    <AddContactModal isModalShown={this.state.isAddContactModalShown}
                                     sendFriendRequestChange={this.props.sendFriendRequestChange}
                                     toggleModal={() => this.toggleAddContactModal()}/>
                    : null
                }
            </>
        );
    };

    private get contacts(): IModelFriend[] | [] {
        if(!this.state.contactList ||this.state.contactList.length === 0) {
            return [];
        }
        if(!this.state.searchValue) {
            return this.state.contactList
        }

        return this.state.contactList
            .filter(friend => `${friend.user?.firstName} ${friend.user?.lastName}`
            .toLocaleLowerCase()
                .indexOf(this.state.searchValue) !== -1)
    }

    private onInputChange = (value: string) => {
        this.setState({searchValue: value})
        //todo ? Zakomentowałem bo to chyba zbędne. W razie co odkomentować i wywalić metodę contacts - MD
        // this.props.refreshFriendsContactList(value);
    };

    private addContact = () => {
        this.toggleAddContactModal();
    };

    private toggleAddContactModal = () => {
        this.setState({isAddContactModalShown: !this.state.isAddContactModalShown})
    };
}

export default withTranslation()(connect(
    (state: RootState) => ({
        friendsContactList: friendsContactListSelector(state),
        isListLoading: friendsContactListLoadingSelector(state),
        error: friendsContactListErrorSelector(state)
    }),
    {
        refreshFriendsContactList
    }
)(Contacts));
