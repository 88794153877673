import React, { RefObject } from 'react';
import styles from './styles.module.scss';
import Translation from '../../Translation';
import CustomCard from '../../CustomCard';
import Loader, { LoaderType } from '../../Loader';
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {IAlertManagerService} from "../../../service/alertManagerService";

interface MenuOption {
	id: string;
	label: string;
}

interface ICameraSelectorProps {
	readonly setCameraId: (cameraId: string) => void;
	readonly selectedCameraId: string;
}

interface ICameraSelectorState {
	menuOptions: MenuOption[] | null;
	isMenuOpen: boolean;
	isLoading: boolean;
}

class CameraSelector extends React.Component<
	ICameraSelectorProps,
	ICameraSelectorState
	> {
	@lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;
	private menuRef: RefObject<HTMLDivElement> = React.createRef();

	constructor(props: ICameraSelectorProps) {
		super(props);
		this.state = {
			menuOptions: null,
			isMenuOpen: false,
			isLoading: true
		};
		fixInjectedProperties(this);
	}

	componentDidMount() {
		this.getAvailableCameras();
		document.addEventListener('mousedown', this.handleOutsideClick);
	}

	componentDidUpdate(
		prevProps: Readonly<ICameraSelectorProps>,
		prevState: Readonly<ICameraSelectorState>
	) {
		if (prevState.menuOptions !== this.state.menuOptions) {
			this.props.setCameraId(this.state.menuOptions?.[0]?.id || '');
		}
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleOutsideClick);
	}

	render() {
		return (
			<CustomCard containerStyle={styles.cardMargin}>
				<CustomCard.Header>
					<Translation text={'dashboard.selectedCamera'} />
					<button className="btn btn-primary" onClick={() => this.getAvailableCameras()}>
						<Translation text={'dashboard.refreshCamera'}/>
					</button>
				</CustomCard.Header>
				<div
					ref={this.menuRef}
					className={`btn btn-primary ${styles.wrapper}`}
					onClick={() => {
						this.toggleMenu(!this.state.isMenuOpen);
					}}
				>
					<Loader type={LoaderType.Local} showLoader={this.state.isLoading} />
					{this.selectedOption()}
					{this.state.isMenuOpen && (
						<div className={styles.optionsMenu}>
							{this.state.menuOptions &&
							this.state.menuOptions?.map(
								(option: MenuOption) => (
									<div
										className={styles.option}
										key={option.id}
										onClick={() => this.setCamera(option.id)}>
										{option.label}
									</div>
								)
							)}
						</div>
					)}
				</div>
			</CustomCard>
		);
	}

	private setCamera = (id: string) => {
		this.props.setCameraId(id);
		this.toggleMenu();
	};

	private selectedOption() {
		if (this.props.selectedCameraId && this.state.menuOptions) {
			const camera = this.state.menuOptions?.find(
				(opt) => opt.id === this.props.selectedCameraId
			);
			if (camera) {
				return camera.label;
			}
		}

		if (this.state.menuOptions?.[0]) {
			return this.state.menuOptions?.[0].label;
		}

		return <Translation text={'dashboard.noCamera'}/>
	}

	private toggleMenu = (openMenu = false) => {
		this.setState(() => ({ isMenuOpen: openMenu }));
	};

	private handleOutsideClick = (event: any) => {
		if (
			this.menuRef &&
			this.menuRef.current &&
			!this.menuRef.current.contains(event.target)
		) {
			this.toggleMenu();
		}
	};

	private async getAvailableCameras() {
		if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
			this.alertManager?.handleApiError("enumerateDevices() not supported.");
			return;
		}

		try {
			this.setState({isLoading: true});
			await navigator.mediaDevices.getUserMedia({audio: true, video: true});
			const sources = await navigator.mediaDevices.enumerateDevices()
			const menuOptions: MenuOption[] = sources
				.filter((device) => device.kind == 'videoinput')
				.map(device => ({ id: device.deviceId, label: device.label ? device.label : 'Unknown_Video_Device'}));
			this.setState({ menuOptions, isLoading: false });
		}
		catch (err) {
			this.alertManager?.handleApiError(err);
			this.setState({isLoading: false})
		}
	}
}

export default CameraSelector;
