import React from 'react';
import {connect} from 'react-redux';
import {Route, RouteComponentProps, Switch, withRouter} from 'react-router-dom';
import {first, of, Subscription, tap} from 'rxjs';
import {RootState} from '../../store/reducers';
import Dashboard from '../Dashboard';
import {WithTranslation, withTranslation} from "react-i18next";
import NotFound from "../NotFound";
import Toast from "../Toast";
import {authTokenSelector} from "../../store/selectors/authSelectors";
import Navigation, {INavMenu} from "../Navigation";
import styles from "./styles.module.scss";
import {changeCurrentView, CurrentViewType} from "../../store/reducers/sagaSlice";
import {currentViewSelector, isProfilePhotoSetSelector} from "../../store/selectors/sagaSelectors";
import navigation from "./menu-items";
import Contacts from "../Contacts";
import Video from "../Video";
import Settings from "../Settings";
import Payments from "../Payments";
import Functionality from "../Functionality";
import Acquaintances from "../Acquaintances";
import AddProfilePhotoModal from "./AddProfilePhotoModal";
import {BrowserView, MobileView} from 'react-device-detect';
import Tabs from "../Tabs";
import Translation, {translatePathToValue} from "../Translation";
import withChatConnection from '../../webrtcVideoChat/withChatConnection';
import {
    FriendRequest,
    FriendRequestsTypes,
    Invite,
    PeerConnection,
    WithChatConnectionAddedProps
} from "../../webrtcVideoChat/chatTypes";
import {changeFriendsContactList, refreshFriendsContactList} from "../../store/reducers/friendsContactListSlice";
import {refreshAccountStateData} from '../../store/reducers/accountSlice';
import {accountIdSelector, accountSelector} from "../../store/selectors/accountSelectors";
import {isNullOrUndefined} from "../../utils/runtimeUtils";
import {isSameValue} from "../../utils/comparatorUtils";
import InviteToConferenceModal from "./InviteToConferenceModal";
import {REACT_APP_NODE_CHAT_PRODUCTION} from "../../webrtcVideoChat/chatConfig";
import {getFriendsAPI} from "../../api/getFriends";
import {catchError} from "rxjs/operators";
import {fixInjectedProperties, lazyInject} from "../../ioc";
import {AlertType, IAlertManagerService} from "../../service/alertManagerService";
import {friendsContactListSelector} from "../../store/selectors/friendsContactListSelectors";
import {IModelFriend} from "../../model/friend";


interface IConnectedPanelHostProps {
    readonly authToken: string | null;
    readonly account: { [key: string]: any } | null;
    readonly currentView: CurrentViewType;
    readonly refreshFriendsContactList: typeof refreshFriendsContactList;
    readonly refreshAccountStateData: typeof refreshAccountStateData;
    readonly changeCurrentView: typeof changeCurrentView;
    readonly friendsList: [] | IModelFriend[];
    readonly changeFriendsContactList: typeof changeFriendsContactList;
}

interface IExternalPanelHostProps {
    fullWidthLayout?: any;
}

interface IPanelHostProps extends IConnectedPanelHostProps,
    IExternalPanelHostProps,
    RouteComponentProps,
    WithChatConnectionAddedProps,
    WithTranslation {
}

interface IPanelHostState {
    isSidebarCollapsed: boolean;
    isProfileModalShown: boolean;
    isInviteModalShown: boolean;
    invites: string[];
    chosenTab: number;
    invitedUserId: string | number | null;
}


class PanelHost extends React.Component<IPanelHostProps, IPanelHostState> {
    private readonly subscriptions: Subscription[] = [];
    private navMenu: INavMenu[];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;
    constructor(props: IPanelHostProps) {
        super(props);

        this.state = {
            isSidebarCollapsed: false,
            isProfileModalShown: false,
            isInviteModalShown: false,
            invites: [],
            chosenTab: 0,
            invitedUserId: null,
        };
        this.navMenu = navigation;
        fixInjectedProperties(this);
    }

    private get activePeerConnection(): PeerConnection | null {
        if (this.props.selectedChatRoomId) {
            return this.props.peerConnections?.[this.props.selectedChatRoomId] || null
        }

        return null
    }

    componentDidMount(): void {
       this.subscriptions.push(this.props.invites.subscribe( invite => this.handleInvite(invite)));

        if (this.props.authToken) {
            this.getUserDetails();
            this.getContacts();
        }

        if (this.props.account && this.props.account.avatar) {
            this.setState({isProfileModalShown: isNullOrUndefined(this.props.account.avatar)});
        }

        this.subscriptions.push(
            this.props.friendInvitesObservableSubject.subscribe((message: FriendRequest) => {
                this.getFriendsList(message);
            }),
        );
    }

    componentDidUpdate(prevProps: Readonly<IPanelHostProps>, prevState: Readonly<IPanelHostState>, snapshot?: any): void {
        if (this.props.authToken !== prevProps.authToken && (undefined !== this.props.authToken && null !== this.props.authToken)) {
            this.getUserDetails();
            this.getContacts();
        }

        if (this.props.account && prevProps.account && !isSameValue(this.props.account, prevProps.account)) {
            this.setState({isProfileModalShown: isNullOrUndefined(this.props.account.avatar)});
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        const collapsed = this.state.isSidebarCollapsed ? 'styles.collapsed' : '';

        return (
            <>
                <BrowserView>
                    <div className={styles.dashboardView}>
                        <div className={styles.mainContainerWrapper}>
                            <Navigation navMenu={this.navMenu}/>
                            <div className={`${styles.mainContainer} ${collapsed}`}>
                                <div className={styles.contentWrapper}>
                                    <main
                                        className={`${styles.content} ${this.props.currentView === CurrentViewType.VIDEO ? styles.videoContent : ''}`}>
                                        <Switch>
                                            <Route path="/panel/dashboard" exact key="dashboard">
                                                <Dashboard setSelectedChatRoomId={this.props.setSelectedChatRoomId}
                                                           invites={this.state.invites}
                                                           sendFriendRequestChange={this.props.sendFriendRequestChange}
                                                           inviteUser={this.toggleFriendToConferenceInvite}
                                                           selectedCameraId={this.props.selectedCameraId}
                                                           setCameraId={this.props.setCameraId}/>
                                            </Route>
                                            <Route path="/panel/video" exact key="video">
                                                <Video activeWebRTCConnection={this.activePeerConnection}
                                                       selectedChatRoomId={this.props.selectedChatRoomId}
                                                       closeConnection={this.props.closeConnection}
                                                       setSelectedChatRoomId={this.props.setSelectedChatRoomId}
                                                       inviteUser={this.toggleFriendToConferenceInvite}
                                                       invitedUserId={this.state.invitedUserId}
                                                       invites={this.state.invites}
                                                       kickUser={this.props.kickUser}
                                                       restartConnection={this.props.restartConnection}
                                                       // resetConnectionCallEndedState={this.props.resetConnectionCallEndedState}
                                                       localMediaDevices={this.props.localMediaDevices}/>
                                            </Route>
                                            <Route path="/panel/payments" component={Payments} exact key="payments"/>
                                            <Route path="/panel/functionality" component={Functionality} exact
                                                   key="functionality"/>
                                            <Route path="/panel/settings" component={Settings} exact key="settings"/>
                                            <Route path="/panel/acquaintances" component={Acquaintances} exact
                                                   key="acquaintances"/>
                                            <Route path="/" key="dashboard">
                                                <Dashboard setSelectedChatRoomId={this.props.setSelectedChatRoomId}
                                                           sendFriendRequestChange={this.props.sendFriendRequestChange}
                                                           invites={this.state.invites}
                                                           inviteUser={this.toggleFriendToConferenceInvite}
                                                           selectedCameraId={this.props.selectedCameraId}
                                                           setCameraId={this.props.setCameraId}/>
                                            </Route>
                                            <Route key="not-found" component={NotFound}/>
                                        </Switch>
                                    </main>
                                </div>
                            </div>
                            {this.props.currentView !== CurrentViewType.VIDEO ?
                                <Contacts inviteUser={this.toggleFriendToConferenceInvite}
                                          sendFriendRequestChange={this.props.sendFriendRequestChange}
                                          setSelectedChatRoomId={this.props.setSelectedChatRoomId}/> : null}
                        </div>
                        <Toast/>

                        {this.state.isProfileModalShown ?
                            <AddProfilePhotoModal isModalShown={this.state.isProfileModalShown}
                                                  toggleModal={this.toggleModal}/> : null}
                        <InviteToConferenceModal invitingUserId={this.state.invites[this.state.invites.length-1]} joinConference={this.joinConference} isInviteModalShown={this.state.isInviteModalShown} toggleModal={this.toggleConferenceInviteModal} />
                    </div>
                </BrowserView>

                <MobileView>
                    <div className={styles.dashboardMobile}>
                        <Tabs chosenTab={this.state.chosenTab} selectedTab={this.selectedTab}>
                            <div className="tab-headers">
                                <span>
                                  <Translation text={"mobileTabs.profile"}/>
                                </span>
                                <span>
                                    <Translation text={"mobileTabs.payments"}/>
                                </span>
                            </div>
                            <div className="tabs-content">
                                <Settings/>
                                <Payments/>
                            </div>
                        </Tabs>
                    </div>
                </MobileView>
            </>
        );
    }

    private getFriendsList(message: FriendRequest) {
        this.subscriptions.push(
            getFriendsAPI(this.props.authToken)
                .pipe(
                    first(),
                    tap(response => {
                        this.props.changeFriendsContactList(response['hydra:member']);
                        this.displayInviteAlert(message);
                    }),
                    catchError((error: any) => {
                        // this.props.addAlert(error);
                        return of(error);
                    }),
                )
                .subscribe(),
        );
    }

    private displayInviteAlert(message: FriendRequest) {
        const name = this.findFriendData(message.friendUserId) || '';
        switch (message.type) {
            case FriendRequestsTypes.INVITE_FRIEND_REQUEST:
                this.alertManager?.addAlert(translatePathToValue('dashboard.friendInvitations.friendRequestIncoming', {name: name}), AlertType.SUCCESS);
                break;
            case FriendRequestsTypes.INVITE_FRIEND_REQUEST_CONFIRMED:
                this.alertManager?.addAlert(translatePathToValue('dashboard.friendInvitations.friendRequestAccepted', {name: name}), AlertType.SUCCESS)
                break;
            case FriendRequestsTypes.INVITE_FRIEND_REQUEST_REJECTED:
                this.alertManager?.addAlert(translatePathToValue('dashboard.friendInvitations.friendRequestRejected', {name: name}))
                break;
            default:
                return;
        }
    }

    private findFriendData(id: string | number) {
        const friend = this.props.friendsList.find(user => user.user?.id == id);
        if (friend && friend.user?.firstName && friend.user?.lastName) {
            return `${friend.user.firstName} ${friend.user.lastName}`;
        }
        if (friend) {
            return friend.user?.email;
        }
        return '';
    }

    private toggleConferenceInviteModal = () => {
        this.setState(state => ({
            isInviteModalShown: !state.isInviteModalShown
        }))
    }

    toggleNavigation = (): void => {
        this.setState({isSidebarCollapsed: !this.state.isSidebarCollapsed});
    };

    private getContacts = () => {
        this.props.refreshFriendsContactList();
    };

    private toggleModal = (): void => {
        this.setState({isProfileModalShown: !this.state.isProfileModalShown});
    };

    private selectedTab = (tabNumber: number) => {
        this.setState({chosenTab: tabNumber});
    };

    private getUserDetails = () => {
        this.props.refreshAccountStateData();
    }

    private handleInvite = (invite: Invite) => {
        if(invite.add) {
            return this.setState(state => ({invites: [...state.invites, invite.userId]}),
                this.toggleConferenceInviteModal)
        }
        return this.setState(state => ({invites: state.invites.filter(id => `${id}` !== `${invite.userId}`)}))
    }

    private joinConference = () => {
        const roomId = this.state.invites[this.state.invites.length-1];
        if(roomId) {
            this.toggleConferenceInviteModal();
            this.props.setSelectedChatRoomId(roomId);
            this.props.changeCurrentView(CurrentViewType.VIDEO);
            this.props.history.push("/panel/video");
        }
    }

    private toggleFriendToConferenceInvite = (invitedUserId: string | number, inviting = true) => {
        if(invitedUserId) this.props.inviteUser(invitedUserId, inviting);
        this.setState({invitedUserId: inviting ? invitedUserId : null})
    }
}

export default connect(
    (state: RootState) => ({
        userId: accountIdSelector(state),
        authToken: authTokenSelector(state),
        account: accountSelector(state),
        currentView: currentViewSelector(state),
        isProfilePhotoSet: isProfilePhotoSetSelector(state),
        friendsList: friendsContactListSelector(state),
    }),
    {
        refreshFriendsContactList,
        refreshAccountStateData,
        changeCurrentView,
        changeFriendsContactList
    }
// @ts-ignore
)(withRouter(withTranslation()(withChatConnection(PanelHost,
    REACT_APP_NODE_CHAT_PRODUCTION,
))));
