import React from "react";
import styles from "./styles.module.scss";


export enum LoaderType {
    Local = "local",
    Global = "global"
}

interface IConnectedLoaderProps {}

interface IExternalLoaderProps {
    type?: string;
    showGlobalLoader?: boolean;
    showLocalLoader?: boolean;
    ref?: any;
    showLoader: boolean;
}

interface ILoaderProps extends IConnectedLoaderProps, IExternalLoaderProps {}

interface ILoaderState {
    showLocalLoader: boolean;
}

class Loader extends React.Component<ILoaderProps, ILoaderState> {
    constructor(props: ILoaderProps) {
        super(props);

        this.state = {
            showLocalLoader: false
        };
    }

    showLocalLoader = () => {
        this.setState(previousState => {
            return {
                showLocalLoader: !previousState.showLocalLoader
            };
        });
    };

    render() {
        const loaderType = this.props.type === LoaderType.Local ? styles.local : "";
        const showGlobalLoader =
            this.props.showLoader && this.isGlobal ? "" : styles.isHidden;
        const showLocalLoader = this.props.showLocalLoader ? styles.isVisible : "";

        return (
            <React.Fragment>
                <div className={`${styles.loaderContainer} ${loaderType} ${showGlobalLoader} ${showLocalLoader}`}>
                    <i className={`feather icon-loader ${styles.loader}`}/>
                </div>
            </React.Fragment>
        );
    }

    private isGlobal(): boolean {
        return (
            this.props.type === LoaderType.Global || this.props.type === undefined
        );
    }
}

export default Loader;
