import React from 'react';
import Translation from "../../Translation";
import styles from "./styles.module.scss";
import CustomCard from "../../CustomCard";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {RootState} from '../../../store/reducers';
import {friendsContactListSelector} from "../../../store/selectors/friendsContactListSelectors";
import {IModelFriend} from "../../../model/friend";
import {isSameValue} from "../../../utils/comparatorUtils";
import {authTokenSelector} from "../../../store/selectors/authSelectors";
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {IAlertManagerService} from "../../../service/alertManagerService";
import {of, Subscription} from 'rxjs';
import {acceptFriendInvitationAPI} from "../../../api/acceptFriendInvitation";
import {catchError, tap} from "rxjs/operators";
import {refreshFriendsContactList} from '../../../store/reducers/friendsContactListSlice';
import {FriendRequest, FriendRequestsTypes} from "../../../webrtcVideoChat/chatTypes";


interface IConnectedInvitationsProps {
    readonly authToken: string | null;
    readonly friendsContactList: IModelFriend[] | [];
    readonly refreshFriendsContactList: typeof refreshFriendsContactList;
    readonly sendFriendRequestChange: (request: FriendRequest) => void
}

interface IExternalInvitationsProps {
}

interface IInvitationsProps extends IConnectedInvitationsProps, IExternalInvitationsProps, RouteComponentProps {
}

interface IInvitationsState {
    readonly isProcessing: boolean;
    readonly invitationsList: IModelFriend[] | []
}

class Invitations extends React.Component<IInvitationsProps, IInvitationsState> {
    private readonly subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IInvitationsProps) {
        super(props);

        this.state = {
            isProcessing: true,
            invitationsList: []
        };
        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        const invitedContacts = this.props.friendsContactList.filter((item: IModelFriend) => item.accepted === null && !item.inviter);
        this.setState({invitationsList: invitedContacts, isProcessing: false});
    }

    componentDidUpdate(prevProps: Readonly<IInvitationsProps>, prevState: Readonly<IInvitationsState>, snapshot?: any): void {
        if (!isSameValue(this.props.friendsContactList, prevProps.friendsContactList)) {
            const invitedContacts = this.props.friendsContactList.filter((item: IModelFriend) => item.accepted === null && !item.inviter);
            this.setState({invitationsList: invitedContacts, isProcessing: false});
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <div className={styles.invitationsList}>
                <CustomCard showLocalLoader={this.state.isProcessing}>
                    <CustomCard.Header>
                        <Translation text="dashboard.invitations.title"/>
                    </CustomCard.Header>
                    <CustomCard.Body>
                        <div className={styles.cardContainer}>
                            {this.state.invitationsList.slice(0, 6).map((item: any) => {
                                return this.renderInvitationItem(item)
                            })}

                            {!this.state.invitationsList.length ? <p><Translation text="dashboard.invitations.noInvitations" /></p> : null}
                        </div>
                    </CustomCard.Body>
                </CustomCard>
            </div>
        );
    }

    private renderInvitationItem(item: any) {
        return (
            <div className={styles.invitations} key={item.id}>
                <div className={styles.contactDetailsWrapper}>
                    <div className={styles.avatar}/>
                    <div className={styles.contactDetails}>
                        <p className={styles.name}>{`${item.user.firstName} ${item.user.lastName}`}</p>
                    </div>
                </div>
                <div className={styles.btnContainer}>
                    <button className="btn btn-primary" onClick={() => this.handleInvitationRequest(item.id, true)}>
                        <Translation text="dashboard.invitations.accept"/>
                    </button>
                    <button className="btn btn-cancel" onClick={() => this.handleInvitationRequest(item.id, false)}>
                        <Translation text="dashboard.invitations.reject"/>
                    </button>
                </div>
            </div>
        )
    }

    private handleInvitationRequest = (id: string | number, isAccepted: boolean) => {
        this.setState({isProcessing: true});
        let successMessage = isAccepted ? 'dashboard.invitations.accepted' : 'dashboard.invitations.rejected';

        this.subscriptions.push(
            acceptFriendInvitationAPI(this.props.authToken, id.toString(), isAccepted).pipe(
                tap(() => {
                    this.alertManager?.addAlert(successMessage);
                    this.props.refreshFriendsContactList();
                    const friendToInformUser = this.props.friendsContactList.find(fr => fr.id === id);
                    if (friendToInformUser?.user?.id) {
                        this.props.sendFriendRequestChange(
                            {friendUserId: friendToInformUser.user.id,
                                type:  isAccepted ?
                                    FriendRequestsTypes.INVITE_FRIEND_REQUEST_CONFIRMED :
                                    FriendRequestsTypes.INVITE_FRIEND_REQUEST_REJECTED})
                    }
                    this.setState({isProcessing: false});
                }),
                catchError((error: any) => {
                    this.alertManager?.handleApiError(error);
                    this.setState({isProcessing: false});
                    return of(error);
                })
            ).subscribe()
        )
    };

}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        friendsContactList: friendsContactListSelector(state),
    }),
    {
        refreshFriendsContactList
    }
)(withRouter(Invitations));
