import React from 'react';
import BasicModal from "../../BasicModal";
import Translation from "../../Translation";
import styles from "./styles.module.scss";
import {connect} from "react-redux";
import {removeFriendsContact} from '../../../store/reducers/friendsContactListSlice';
import {IModelFriend} from "../../../model/friend";


export enum RemoveContactType {
    FRIEND = 'friend',
    ACQUAINTANCE = 'acquaintance'
}

interface IConnectedRemoveContactModalProps {
    readonly removeFriendsContact: typeof removeFriendsContact;
}

interface IExternalRemoveContactModalProps {
    readonly isModalShown: boolean;
    readonly toggleModal: () => void;
    readonly item: IModelFriend;
    readonly type: RemoveContactType;
    readonly removeAcquaintance?: (id: string, loaderCallback: () => void) => void;
}

interface IRemoveContactModalProps extends IConnectedRemoveContactModalProps, IExternalRemoveContactModalProps {
}

interface IRemoveContactModalState {
    isLoading: boolean;
}

class RemoveContactModal extends React.Component<IRemoveContactModalProps, IRemoveContactModalState> {
    constructor(props: IRemoveContactModalProps) {
        super(props);

        this.state = {
            isLoading: false
        };
    }

    render() {
        const item = this.props.item,
            contactName = item.user && item.user.firstName ? `${item.user.firstName} ${item.user?.lastName}` : '';

        return (
            <BasicModal isModalShown={this.props.isModalShown}
                        closeModal={this.props.toggleModal}
                        isModalCustom={true}
                        showLocalLoader={this.state.isLoading}>
                <BasicModal.Header>
                    <div className={styles.modalHeader}>
                        <button className="btn btn-close" onClick={() => this.props.toggleModal()}/>
                    </div>
                </BasicModal.Header>

                <BasicModal.Body>
                    <p className={styles.modalDescription}>
                        {this.props.type === RemoveContactType.FRIEND ?
                            <Translation text="contacts.removeContactModal.removeFriendContact"
                                         config={{contactName: contactName}}/> :
                            <Translation text="contacts.removeContactModal.removeAcquaintanceContact"
                                         config={{contactName: contactName}}/>
                        }
                    </p>
                    <div className={styles.actionBtns}>

                        <button className="btn btn-primary"
                                onClick={() => {
                                    this.setState({isLoading: true});
                                    if (this.props.type === RemoveContactType.ACQUAINTANCE && this.props.removeAcquaintance) {
                                        this.props.removeAcquaintance(this.props.item.id.toString(), () => this.setState({isLoading: false}));
                                    } else {
                                        this.props.removeFriendsContact(this.props.item.id.toString());

                                    }
                                }}>
                            <Translation text="contacts.removeContactModal.confirm"/>
                        </button>

                        <button className="btn btn-cancel"
                                onClick={() => this.props.toggleModal()}>
                            <Translation text="contacts.removeContactModal.cancel"/>
                        </button>
                    </div>
                </BasicModal.Body>
            </BasicModal>
        );
    }
}

export default connect(
    () => ({}),
    {
        removeFriendsContact
    }
)(RemoveContactModal);
