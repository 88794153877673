import {Observable} from 'rxjs';
import {okoUserPanelAPI} from './provider/okoUserPanelAPI';
import {RestQueryParams} from './base/queryParams';

export function changePasswordAPI(authToken: string | null, oldPassword: string, newPassword: string): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return okoUserPanelAPI.post(
        'new-api/shop_users/change_password',
        {
            oldPassword: oldPassword,
            newPassword: newPassword
        },
        new RestQueryParams(),
        headers
    );
}
