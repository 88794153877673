import React from 'react';
import Loader from "../Loader";
import Translation from "../Translation";
import UpcomingCall from "./UpcomingCall";
import RecentCalls from "./RecentCalls";
import CameraSelector from "./CameraSelector";
import Invitations from "./Invitations";
import {FriendRequest} from "../../webrtcVideoChat/chatTypes";

interface IConnectedDashboardProps {
}

interface IExternalDashboardProps {
    readonly setSelectedChatRoomId: (id: string | number) => void;
    readonly setCameraId: (cameraId: string) => void;
    readonly selectedCameraId: string;
    readonly invites: string[];
    readonly inviteUser: (invitedId: string | number, invite?: boolean) => void;
    readonly sendFriendRequestChange: (request: FriendRequest) => void;
}

interface IDashboardProps extends IConnectedDashboardProps, IExternalDashboardProps {}

interface IDashboardState {
    readonly isProcessing: boolean;
}

class Dashboard extends React.Component<IDashboardProps, IDashboardState> {
    constructor(props: IDashboardProps) {
        super(props);

        this.state = {
            isProcessing: false,
        };
    }

    render() {
        return (
            <section className="section">
                <Loader showLoader={this.state.isProcessing}/>

                <h1 className="section-title">
                    <Translation text="dashboard.title"/>
                </h1>

                <div className="section-content section-dashboard">
                    <div>
                        <CameraSelector selectedCameraId={this.props.selectedCameraId}
                                        setCameraId={this.props.setCameraId} />

                        <UpcomingCall  invites={this.props.invites} setSelectedChatRoomId={this.props.setSelectedChatRoomId} />
                    </div>
                    <div>
                        <Invitations sendFriendRequestChange={  this.props.sendFriendRequestChange} />
                        <RecentCalls inviteUser={this.props.inviteUser} setSelectedChatRoomId={this.props.setSelectedChatRoomId} />
                    </div>
                </div>
            </section>
        );
    }
}

export default Dashboard;
