import {Observable} from 'rxjs';
import {okoUserPanelAPI} from "./provider/okoUserPanelAPI";
import {RestQueryParams} from "./base/queryParams";


export function getOrdersAPI(authToken: string | null): Observable<any> {
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return okoUserPanelAPI.get(
        'new-api/orders',
        new RestQueryParams(),
        headers
    );
}
