import React from 'react';
import Loader from "../Loader";
import Translation from "../Translation";
import CustomCard from "../CustomCard";
import styles from "./styles.module.scss";
import {isMobile} from 'react-device-detect';
import {fixInjectedProperties, lazyInject} from "../../ioc";
import {IAlertManagerService} from "../../service/alertManagerService";
import {of, Subscription} from 'rxjs';
import {getOrdersAPI} from "../../api/getOrders";
import {connect} from "react-redux";
import {RootState} from "../../store/reducers";
import {authTokenSelector} from "../../store/selectors/authSelectors";
import {catchError, tap} from "rxjs/operators";
import {IModelOrder, IOrderItem} from "../../model/order";
import Moment from "react-moment";
import {isNull, isNullOrUndefined} from "../../utils/runtimeUtils";
import { saveAs } from 'file-saver';

interface IConnectedPaymentsProps {
    readonly authToken: string | null;
}

interface IExternalPaymentsProps {
}

interface IPaymentsProps extends IConnectedPaymentsProps, IExternalPaymentsProps {
}

interface IPaymentsState {
    isProcessing: boolean;
    orders: IModelOrder[] | [];
}

class Payments extends React.Component<IPaymentsProps, IPaymentsState> {
    private readonly subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IPaymentsProps) {
        super(props);

        this.state = {
            isProcessing: true,
            orders: []
        };
        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.getOrders();
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <section className={`section ${isMobile ? 'section-mobile' : ''}`}>
                <Loader showLoader={this.state.isProcessing}/>

                <h1 className="section-title">
                    <Translation text="payments.title"/>
                </h1>
                <div className={`${styles.products} ${isMobile ? styles.mobileView : ''}`}>
                    {!this.state.orders.length ? <p><Translation text="payments.noPayments"/></p> : null}
                    {this.state.orders.map((product: any) => this.renderProductItem(product))}
                </div>
            </section>
        );
    }

    private renderProductItem = (item: IModelOrder) => {
        return (
            <CustomCard key={item['@id']}>
                <CustomCard.Body>
                    <div className={styles.details}>
                        <div>
                            <p className={styles.productLabel}><Translation text="payments.table.labels.date"/></p>
                            <p className={styles.productValue}>
                                <Moment interval={0}
                                        locale={"pl"}
                                        format={"DD.MM.YYYY"}>
                                    {item.createdAt}
                                </Moment>
                            </p>
                        </div>
                        <div>
                            <p className={styles.productLabel}><Translation text="payments.table.labels.productsList"/>
                            </p>
                            {item.items.map((product: IOrderItem, index: number) => <p key={index} className={styles.productValue}>{product.productName}</p>)}
                        </div>
                        <div>
                            <p className={styles.productLabel}>
                                <span><Translation text="payments.table.labels.price"/></span>
                                <span><Translation text="payments.table.labels.priceVat"/></span>
                            </p>
                            <p className={styles.productValue}>{item.itemsTotal / 100 } PLN</p>
                            <p className={styles.productValue}>({item.total / 100} PLN)</p>
                        </div>
                        <div>
                            <p className={styles.productLabel}><Translation
                                text="payments.table.labels.downloadInvoice"/></p>
                            <button className="btn btn-download"
                                    disabled={isNullOrUndefined(item.invoiceUrl)}
                                    onClick={() => this.downloadInvoice(item.invoiceUrl)}/>
                        </div>
                        <div>
                            <p className={styles.productLabel}><Translation text="payments.table.labels.status"/></p>
                            <p className={styles.productValue}><Translation text={`payments.statuses.${item.paymentState}`} /></p>
                        </div>
                    </div>
                </CustomCard.Body>
            </CustomCard>
        )
    };

    private getOrders = () => {
        this.subscriptions.push(
            getOrdersAPI(this.props.authToken).pipe(
                tap((resp: any) => {
                    const orders = resp['hydra:member'];
                    this.setState({orders, isProcessing: false});
                }),
                catchError((error: any) => {
                    this.alertManager?.handleApiError(error);
                    this.setState({isProcessing: false});
                    return of();
                })
            ).subscribe()
        )
    };

    private downloadInvoice = (invoiceUrl: string | null) => {
        if (isNull(invoiceUrl)) {
            return;
        }

        saveAs(
            `${process.env.REACT_APP_API_URL}${invoiceUrl}`,
            "invoice.pdf"
        );
    };
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state)
    })
)(Payments);
