import {IAPIHandler} from './api';
import {QueryParams, RestQueryParams} from './queryParams';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

export class FilteringAPIHandlerDecorator<ParamsType extends QueryParams<any> = RestQueryParams> implements IAPIHandler<ParamsType> {
    constructor(
        private readonly next: IAPIHandler<ParamsType>,
        private readonly filter: (
            data?: any,
            payload?: any,
            params?: ParamsType,
            headers?: Object
        ) => any
    ) {
    }

    get<T>(path: string, params?: ParamsType, headers?: Object): Observable<T> {
        return this.next
            .get<T>(path, params, headers)
            .pipe(map(data => this.filter(data, null, params)));
    }

    post<T = any>(
        path: string,
        payload?: any,
        params?: ParamsType,
        headers?: Object
    ): Observable<T> {
        return this.next
            .post<T>(path, payload, params, headers)
            .pipe(map(data => this.filter(data, payload, params)));
    }

    put<T = any>(
        path: string,
        payload?: any,
        params?: ParamsType,
        headers?: Object
    ): Observable<T> {
        return this.next
            .put<T>(path, payload, params, headers)
            .pipe(map(data => this.filter(data, payload, params)));
    }

    delete<T = any>(
        path: string,
        params?: ParamsType,
        headers?: Object
    ): Observable<T> {
        return this.next
            .delete<T>(path, params, headers)
            .pipe(map(data => this.filter(data, params)));
    }
}
