import {Subject} from "rxjs";

export type ChatError = Error;

export enum UserRole {
	ADMIN = 'ROLE_ADMIN',
	CLINIC = 'ROLE_CLINIC',
	DOCTOR = 'ROLE_DOCTOR',
	PATIENT = 'ROLE_PATIENT',
	INVITATION = 'ROLE_INVITATION',
}

export enum NodeErrorTypes {
	NOT_AUTHORIZED = 'not_authorized',
	NO_TOKEN = 'no_token',
	ALREADY_ONLINE = 'already_online',
	EXPIRED_TOKEN = 'expired_token',
	WEBSOCKET_ERROR = 'websocket error',
}

export enum ConnectionState {
	CONNECTION_NOT_INITIATED = 'connection_not_initiated',
	INVITE_SENT = 'invite_sent',
	CONNECTING = 'connecting',
	CONNECTED = 'connected',
	NO_PEER_ACTIVITY = 'no_peer_activity',
	CONNECTION_ERROR = 'connection_error',
	CONNECTION_FAILED = 'connection_failed',
	CONNECTION_ENDED = 'connection_ended',
	RECONNECTING = 'reconnecting',
	SHORT_DISCONNECTION = 'short_disconnection',
	INVITE_SENT_TO_USER_IN_CALL = 'invite_sent_to_user_in_call',
}

export type FileStreamType = {
	file?: string | ArrayBuffer;
	done?: boolean;
	fileName?: string;
	fullFile?: any;
};

export type PeerConnection = {
	peerIsOnline: boolean;
	connection: any; // RTCPeerConnection | null;
	channelId: string;
	callStarted: boolean;
	peerIsPolite: boolean;
	makingOffer: boolean;
	connectionAttemptNumber: number;
	remoteStream: any; //RTCRemoteStream
	userInRoom: string | null;
	connectionState: ConnectionState;
};

export type AllUserConnections = {
	[roomId: string]: PeerConnection;
};

export type ChatContact = {
	roomId: string;
	accountId: string;
	userId: string;
	firstName: string;
	lastName: string;
	unreadMessagesCount: number;
	role: UserRole;
	name?: string;
	description?: string;
};

export class ChatMessage {
	from: string;
	to: string;
	date: Date;
	messageType: MessageType;
	messageContent?: string;
	messageMedia?: any;
	messageId?: string;
	messagePreview?: string;
	isFromServer?: boolean;
	constructor({from, to, messageType, messageContent, messageId}: any) {
		this.from = from || '';
		this.to = to || '';
		this.messageType = messageType || '';
		this.messageContent = messageContent || '';
		this.date = new Date();
		this.messageId = messageId;
	}
}

export type ChatServerMessage = {
	'@id': string;
	'@type': 'Message';
	content: string;
	from: {
		'@id': string;
		'@type': string;
		id: string;
		login: string;
		registrationConfirmed: boolean;
		resetPasswordComponentUrl: null;
	};
	id: string;
	mediaObject: {
		'@id': string;
		' @type': string;
		contentUrl: string;
		id: string;
		owner: any;
		public: boolean;
		contentUrlMappedByMode: {
			stream: string;
			view: string;
		};
	};
	receivedAt: null;
	sentAt: string;
	to: {
		'@id': string;
		'@type': string;
		id: string;
		login: string;
		registrationConfirmed: boolean;
		resetPasswordComponentUrl: null;
	};
};

export type ChatServerMessageConfirmPayload = {
	messageId: string;
	receivedAt: string;
};

export type ChatServerMessagePayload = {
	fromUserId: string;
	toUserId: string;
	content: string;
	mediaObjectId: string;
};

export type SignalAnswer = {
	toRoom: string;
	answer: any; // RTCSessionDescription;
};

export type SignalOffer = {
	toRoom: string;
	offer: any; // RTCSessionDescription;
};

export type SignalCandidate = {
	toRoom?: string;
	candidate: any; // RTCIceCandidate;
};

export enum MessageType {
	RESPONSE = 'response',
	MESSAGE = 'message',
	TEXT = 'text',
	IMAGE = 'image',
	FILE = 'file',
	PLACEHOLDER = 'placeholder',
}

export enum SocketMessages {
	LOGGED_IN = 'logged_in',
	JOIN_CHANNELS = 'join_channels',
	LEAVE_CHANNEL = 'leave_channel',
	CHANNEL_JOINED = 'channel_joined',
	USER_JOINED_CHANNEL = 'user_joined_channel',
	CANDIDATE = 'candidate',
	OFFER = 'offer',
	ANSWER = 'answer',
	CONNECTION = 'connection',
	DISCONNECTING = 'disconnecting',
	USER_HAS_LEFT = 'user_has_left',
	CONNECT = 'connect',
	DISCONNECT = 'disconnect',
	CONNECT_ERROR = 'connect_error',
	ALLOWED_CHANNELS_LIST = 'allowed_channels_list',
	ERROR = 'error',
	NOTIFICATION = 'notification',
	JOIN_CHANNEL = 'join_channel',
	KICK_USER = 'kick_user',
	ROOM_NOT_READY = 'room_not_ready',
	RESTART_CONNECTION = 'restart_connection',
	RESTART_CONNECTION_DONE = 'restart_connection_done',
	ASK_TO_RECONNECT = 'ask_to_reconnect',
	REFUSE_TO_RECONNECT = 'refuse_to_reconnect',
	INVITE_TO_CONFERENCE = 'invite_to_conference',
	CANCEL_CONFERENCE_INVITE = 'cancel_conference_invite',
	HOST_OFFLINE = 'host_offline',
	STORE_FCM_TOKEN = 'store_fcm_token',
	CALL_START = 'call_start',
	CALL_END = 'call_end',
	INVITED_USER_IN_CALL = 'invited_user_in_call',
	TURN_CREDENTIALS = 'turn_credentials',
	INVITE_FRIEND_REQUEST = 'invite_friend_request',
	INVITE_FRIEND_REQUEST_CONFIRMED = 'invite_friend_request_confirmed',
	INVITE_FRIEND_REQUEST_REJECTED = 'invite_friend_request_rejected',
}


export enum FCMType {
	INVITE = 'invite',
	CANCEL_INVITE = 'cancel_invite',
}

export type WithChatConnectionAddedProps = {
	errors: Subject<ChatError>;
	hasNodeConnection: boolean;
	// allowedChannels: ChatContact[];
	selectedChatRoomId: string | null;
	setSelectedChatRoomId: (roomId: string | number | null) => void;
	peerConnections: AllUserConnections;
	localMediaDevices: any;
	setCameraId: (selectedCameraId: string) => void;
	selectedCameraId: string;
	joinConference: (id: string) => void;
	kickUser: (id: string | number) => void;
	closeConnection: (room: string) => void;
	isCameraSwitching: boolean;
	restartConnection: () => void;
	invites: Subject<Invite>;
	inviteUser: (userId: string | number, invite?: boolean) => void;
	friendInvitesObservableSubject: Subject<FriendRequest>;
	sendFriendRequestChange: (request: FriendRequest) => void;
};

export type Invite = {
	userId: string;
	add: boolean;
};


export enum FriendRequestsTypes {
	INVITE_FRIEND_REQUEST = 'invite_friend_request',
	INVITE_FRIEND_REQUEST_CONFIRMED = 'invite_friend_request_confirmed',
	INVITE_FRIEND_REQUEST_REJECTED = 'invite_friend_request_rejected',
}

export type FriendRequest = {
	friendUserId: string | number;
	type: FriendRequestsTypes;
};

export type FriendRequestPayload = {
	friendUserId: string | number;
	userId: string | number;
};
