import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../reducers";
import {IFriendsContactListState} from "../reducers/friendsContactListSlice";

export const selectFriendsContactList = (state: RootState): IFriendsContactListState => {
    return state.friendsContactList;
};

export const friendsContactListSelector = createSelector(
    [selectFriendsContactList],
    (state: IFriendsContactListState) => state.friendsList
);

export const friendsContactListLoadingSelector = createSelector(
    [selectFriendsContactList],
    (state: IFriendsContactListState) => state.isLoading
);

export const friendsContactListErrorSelector = createSelector(
    [selectFriendsContactList],
    (state: IFriendsContactListState) => state.error
);
