import {createSlice, PayloadAction} from '@reduxjs/toolkit';


export interface IAddress {
    street: string | null;
    city: string | null;
    postCode: string | null;
    company: string | null;
    companyCode: string | null;
}

export interface IAccountData {
    id: number | null;
    firstName: string | null;
    lastName: string | null;
    phoneNumber: string | null;
    email: string | null;
    avatar: any | null;
    blind: boolean | null,
    shippingAddressOutput: IAddress | null;
    billingAddressOutput: IAddress | null;
}

export interface IAccount extends IAccountData {
}

export interface IAccountState {
    account: IAccount;
    isLoading: boolean;
    error: string | null;
}

export interface IAccountPayload {
    id: number;
    firstName: string | null;
    lastName: string | null;
    phoneNumber: string | null;
    email: string | null;
    avatar: any | null;
    blind: boolean | null;
    shippingAddressOutput: IAddress | null;
    billingAddressOutput: IAddress | null;
}

export interface IUpdateAccountPayload {
    firstName: string | null;
    lastName: string | null;
    phone: string | null;
    blind: boolean;
    shippingAddress: IAddress | null;
    billingAddress: IAddress | null;
}

export interface ISetAccountState {
    account: IAccountPayload;
}

export interface ISetAccountStateFailure {
    error: string;
}

export interface IChangeAccountStateLoading {
    isLoading: boolean;
}

const initialState: IAccountState = {
    account: {
        id: null,
        firstName: null,
        lastName: null,
        phoneNumber: null,
        email: null,
        avatar: null,
        blind: null,
        shippingAddressOutput: null,
        billingAddressOutput: null
    },
    isLoading: false,
    error: null,
};

const accountSlice = createSlice({
    name: 'account',
    initialState: initialState,
    reducers: {
        setAccountState: {
            reducer: (state: IAccountState, action: PayloadAction<ISetAccountState>) => {
                return {
                    account: action.payload.account,
                    isLoading: state.isLoading,
                    error: state.error
                };
            },
            prepare(account: IAccountPayload) {
                return {
                    payload: {
                        account: account,
                    }
                };
            }
        },
        updateAccount: {
            reducer: (state: IAccountState) => {
                return {
                    account: state.account,
                    isLoading: true,
                    error: state.error
                };
            },
            prepare(account: IUpdateAccountPayload) {
                return {
                    payload: {account: account}
                };
            }
        },
        setAccountStateFailure: {
            reducer: (state: IAccountState, action: PayloadAction<ISetAccountStateFailure>) => {
                return {
                    account: state.account,
                    isLoading: state.isLoading,
                    error: action.payload.error
                };
            },
            prepare(error: string) {
                return {
                    payload: {
                        error: error
                    }
                };
            }
        },
        changeAccountStateLoading: {
            reducer: (state: IAccountState, action: PayloadAction<IChangeAccountStateLoading>) => {
                return {
                    account: state.account,
                    isLoading: action.payload.isLoading,
                    error: state.error
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {
                        isLoading: isLoading
                    }
                };
            }
        },
        refreshAccountStateData: (state: IAccountState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
    }
});

export const {
    setAccountState,
    setAccountStateFailure,
    changeAccountStateLoading,
    updateAccount,
    refreshAccountStateData,
} = accountSlice.actions;

export default accountSlice.reducer;
