import React from 'react';
import Translation from "../../Translation";
import styles from "./styles.module.scss";
import CustomCard from "../../CustomCard";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {changeCurrentView, CurrentViewType} from "../../../store/reducers/sagaSlice";
import {connect} from "react-redux";
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {IAlertManagerService} from "../../../service/alertManagerService";
import {catchError, tap} from "rxjs/operators";
import {of, Subscription} from 'rxjs';
import {getCallHistoryAPI} from "../../../api/getCallHistory";
import {RootState} from "../../../store/reducers";
import {authTokenSelector} from "../../../store/selectors/authSelectors";
import {IModelCallHistoryItem} from "../../../model/callHistoryItem";
import Moment from "react-moment";
import {accountIdSelector} from "../../../store/selectors/accountSelectors";
import {isNull} from "../../../utils/runtimeUtils";
import moment from "moment";

interface IConnectedRecentCallsProps {
    readonly authToken: string | null;
    readonly accountId: number | null;
    readonly changeCurrentView: typeof changeCurrentView;
}

interface IExternalRecentCallsProps {
    readonly setSelectedChatRoomId: (id: string | number) => void;
    readonly inviteUser: (invitedId: string | number, invite?: boolean) => void
}

interface IRecentCallsProps extends IConnectedRecentCallsProps, IExternalRecentCallsProps, RouteComponentProps {}

interface IRecentCallsState {
    readonly callsHistory: IModelCallHistoryItem[] | [];
    readonly isProcessing: boolean;
}

class RecentCalls extends React.Component<IRecentCallsProps, IRecentCallsState> {
    private readonly subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IRecentCallsProps) {
        super(props);

        this.state = {
            callsHistory: [],
            isProcessing: true,
        };
        fixInjectedProperties(this);
    }

    componentDidMount(): void {
      if(this.props.authToken) {
          this.getCallHistory();
      }
    }

    componentDidUpdate(prevProps: Readonly<IRecentCallsProps>) {
      if(!prevProps.authToken && this.props.authToken && this.state.callsHistory.length === 0) {
          this.getCallHistory();
      }
    }


    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <CustomCard showLocalLoader={this.state.isProcessing}>
                <CustomCard.Header>
                    <Translation text="dashboard.recentCalls.title"/>
                </CustomCard.Header>
                <CustomCard.Body>
                    <div className={styles.cardContainer}>
                        {this.state.callsHistory.slice(0, 6).map((item: IModelCallHistoryItem) => {
                            return this.renderRecentCallItem(item)
                        })}
                    </div>
                </CustomCard.Body>
            </CustomCard>
        );
    }

    private renderRecentCallItem(item: IModelCallHistoryItem) {
        let guest = null;
        if (item.guest.id !== this.props.accountId) {
            guest = item.guest;
        }

        if (item.host.id !== this.props.accountId) {
            guest = item.host;
        }

        if (isNull(guest)) {
            return;
        }

        const callId = item.id.toString(),
            username = `${guest?.firstName} ${guest?.lastName}`;

        return(
            <div className={styles.contact} key={item.id}>
                <div className={styles.contactDetailsWrapper}>
                    <div className={styles.avatar}/>
                    <div className={styles.contactDetails}>
                        <p className={styles.name}>{username}</p>
                        <p className={styles.date}>
                            <Moment interval={0}
                                locale={"pl"}
                                format={"DD.MM.YYYY, HH:mm"}>
                                {moment(item.callStartedAt).add(moment(item.callStartedAt).utcOffset(), "m")}
                            </Moment>
                        </p>
                    </div>
                </div>
                <div onClick={() => this.callUser(item)}>
                    <button className="btn btn-primary">
                        <Translation text="dashboard.recentCalls.makeCall" />
                    </button>
                </div>
            </div>
        )
    }
    private callUser(item: IModelCallHistoryItem) {
        if(this.props.accountId) {
            this.props.inviteUser(item.host.id === this.props.accountId ? item.guest.id: item.host.id)
            this.props.setSelectedChatRoomId(this.props.accountId);
            this.props.changeCurrentView(CurrentViewType.VIDEO);
            this.props.history.push("/panel/video");
        }

    }

    private getCallHistory = () => {
        this.subscriptions.push(
            getCallHistoryAPI(this.props.authToken).pipe(
                tap((response: any) => {
                    this.setState({
                        callsHistory: response['hydra:member'].sort(this.sortMethod) || [],
                        isProcessing: false
                    });
                }),
                catchError((error: any) => {
                    this.setState({isProcessing: false});
                    this.alertManager?.handleApiError(error);
                    return of(error);
                })
            ).subscribe()
        )
    };

    private sortMethod(a: any, b: any): number {
        const aDate = new Date(a.callStartedAt),
            bDate = new Date(b.callStartedAt),
            aTime = aDate.getTime(),
            bTime = bDate.getTime();

        return aTime >= bTime ? -1 : 1;
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        accountId: accountIdSelector(state)
    }),
    {
        changeCurrentView
    }
)(withRouter(RecentCalls));
