import {configureStore, getDefaultMiddleware} from "@reduxjs/toolkit";
import {createEpicMiddleware} from "redux-observable";
import {persistReducer, persistStore} from 'redux-persist';
import {FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE} from "redux-persist/es/constants";
import storage from 'redux-persist/lib/storage';
import {rootEpic} from "./epics";
import rootReducer from './reducers';

export default function (key: string) {
    const defaultMiddleware = getDefaultMiddleware(({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            }
        })),
        epicMiddleware = createEpicMiddleware();

    defaultMiddleware.push(epicMiddleware);
    const persistConfig = {
        key: 'root:' + key,
        storage: storage,
        whitelist: ['auth', 'account', 'alert', 'saga'],
    };

    const pReducer = persistReducer(persistConfig, rootReducer);

    const store = configureStore({
        reducer: pReducer,
        middleware: defaultMiddleware
    });

    epicMiddleware.run(rootEpic);

    const hotModule = module as typeof module & { hot: any };
    if (process.env.NODE_ENV === "development" && hotModule.hot) {
        hotModule.hot.accept("./reducers", () => {
            const newRootReducer = require("./reducers").default;
            store.replaceReducer(newRootReducer);
        });
    }

    return {
        store,
        persistor: persistStore(store),
    };
}
