import {createSelector} from "@reduxjs/toolkit";
import {IAuthState} from "../reducers/authSlice";
import {RootState} from "../reducers";

export const selectAuth = (state: RootState): IAuthState => {
    return state.auth;
};

export const usernameSelector = createSelector(
    [selectAuth],
    (state: IAuthState) => state.username
);

export const authTokenSelector = createSelector(
    [selectAuth],
    (state: IAuthState) => state.authToken
);

export const refreshTokenSelector = createSelector(
    [selectAuth],
    (state: IAuthState) => state.refreshToken
);

export const isAuthenticatedSelector = createSelector(
    [selectAuth],
    (state: IAuthState) => state.isAuthenticated
);
