import React, {ReactNode} from "react";
import Translation from "../Translation";


export enum TooltipDirection {
    TOP = 'top',
    BOTTOM = 'bottom',
    LEFT = 'left',
    RIGHT = 'right'
}

interface IConnectedTooltipProps {}

interface IExternalTooltipProps  {
    content: string;
    direction: TooltipDirection;
    delay?: number;
    customComponent?: () => ReactNode;
    customStyles?: string;
}

interface ITooltipProps extends IConnectedTooltipProps, IExternalTooltipProps {}

interface ITooltipState {
    isTooltipVisible: boolean;
}

class Tooltip extends React.Component<ITooltipProps, ITooltipState> {
    timeout: any;
    constructor(props: ITooltipProps) {
        super(props);

        this.state = {
            isTooltipVisible: false
        }
    }

    private showTip = () => {
        this.timeout = setTimeout(() => {
            this.setState({isTooltipVisible: true});
        }, this.props.delay || 400);
    };

    private hideTip = () => {
        clearInterval(this.timeout);
        this.setState({isTooltipVisible: false});
    };

    componentWillUnmount(): void {
        if (this.timeout) {
            clearInterval(this.timeout);
        }
    }

    render() {

        const tooltipWrapper = document.querySelector('.tooltip-wrapper'),
            styles: {[key: string]: any} = {};
        if (tooltipWrapper) {
            if (this.props.direction === TooltipDirection.TOP) {
                styles.bottom = tooltipWrapper.clientHeight;
            }
            if (this.props.direction === TooltipDirection.BOTTOM) {
                styles.top = tooltipWrapper.clientHeight;
            }
            if (this.props.direction === TooltipDirection.RIGHT) {
                styles.left = tooltipWrapper.clientWidth + 6;
            }
        }

        return (
            <div
                className="tooltip-wrapper"
                onMouseEnter={this.showTip}
                onMouseLeave={this.hideTip}>

                {this.props.children}
                {this.state.isTooltipVisible && (
                    <div className={`tooltip-content ${this.props.direction} ${this.props.customStyles ? this.props.customStyles : ''}`} style={styles}>
                        {this.props.customComponent ? this.props.customComponent() :
                            <Translation text={this.props.content}/>
                        }
                    </div>
                )}
            </div>
        );
    }
}

export default Tooltip;
