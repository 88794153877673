import {CurrentViewType} from "../../store/reducers/sagaSlice";

const navigation = [
    {
        step: CurrentViewType.DASHBOARD,
        url: '/panel/dashboard',
        icon: 'dashboard',
        tooltipText: 'navbar.dashboard'
    },
    {
        step: CurrentViewType.VIDEO,
        url: '/panel/video',
        icon: 'video',
        tooltipText: 'navbar.video'
    },
    {
        step: CurrentViewType.PAYMENTS_HISTORY,
        url: '/panel/payments',
        icon: 'cart',
        tooltipText: 'navbar.payments'
    },
    {
        step: CurrentViewType.FUNCTIONALITY,
        url: '/panel/functionality',
        icon: 'functionality',
        tooltipText: 'navbar.functionality'
    },
    {
        step: CurrentViewType.SETTINGS,
        url: '/panel/settings',
        icon: 'settings',
        tooltipText: 'navbar.settings'
    }
];

export default navigation;
