import React from "react";
import BasicModal from "../../BasicModal";
import Translation from "../../Translation";
import styles from "./styles.module.scss";
import {connect} from "react-redux";
import {RootState} from "../../../store/reducers";
import {friendsContactListSelector} from "../../../store/selectors/friendsContactListSelectors";
import {IModelFriend} from "../../../model/friend";

interface IConnectedInviteToConferenceModalProps {
	friends: IModelFriend[] | []
}

interface IExternalInviteToConferenceModalProps {
	isInviteModalShown: boolean;
	toggleModal: () => void;
	invitingUserId: string | undefined;
	joinConference: () => void;
}

interface InviteToConferenceModalState {}

interface InviteToConferenceModalProps extends IConnectedInviteToConferenceModalProps, IExternalInviteToConferenceModalProps {}

class InviteToConferenceModal extends React.Component<InviteToConferenceModalProps, InviteToConferenceModalState> {

	componentDidUpdate(prevProps: Readonly<InviteToConferenceModalProps>) {
		// if(prevProps.invitingUserId !== this.props.invitingUserId && !this.props.invitingUserId) {
		// 	console.log('GG')
		// 	this.props.toggleModal();
		// }
	}

	render() {
		return (
			<BasicModal isModalShown={this.props.isInviteModalShown} closeModal={this.props.toggleModal}>
				<BasicModal.Header>
					<Translation text="conferenceInvites.title"/>
					<button className="btn btn-close" onClick={this.props.toggleModal}/>
				</BasicModal.Header>
				<BasicModal.Body>
					<div className={styles.description}>
						<h1>{this.friend}</h1>
						<p><Translation text={'conferenceInvites.info'} /></p>
						<p><Translation text={'conferenceInvites.infoQuestion'} /></p>
					</div>
				</BasicModal.Body>
				<BasicModal.Footer>
					<button className="btn btn-primary" onClick={this.props.joinConference}>
						<Translation text={'conferenceInvites.acceptInvite'} />
					</button>
					<button className={`btn btn-cancel ${styles.buttonMargin}`} onClick={this.props.toggleModal}>
						<Translation text={'conferenceInvites.declineInvite'} />
					</button>
				</BasicModal.Footer>
			</BasicModal>
		);
	}

	private get friend(): string {
		//todo popraw
		if(this.props.friends.length > 0 && this.props.invitingUserId) {
			const friend = this.props.friends?.find(fr => `${fr.user?.id}` === this.props.invitingUserId)
			return friend ? `${friend.user?.firstName} ${friend.user?.lastName}` : ''
		}

		return '';
	}
}

export default connect(
	(state: RootState) => ({
		friends: friendsContactListSelector(state)
	})
)(InviteToConferenceModal);
