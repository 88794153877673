import IOkoUserPanelWindow from "../runtime/window";


export interface ILoadExternalFile {
  url: string;
  modifier?: (javascript: string) => string;
}

export async function loadJavaScript(instance: IOkoUserPanelWindow, options: ILoadExternalFile) {
  const response = await fetch(options.url);
  let javascript = await response.text();

  const modifier = options.modifier ? options.modifier : (js: string) => js;
  javascript = modifier(javascript);

  instance.addExternalRawJavaScript(javascript);
}

export async function loadStylesheet(instance: IOkoUserPanelWindow, options: ILoadExternalFile) {
  const response = await fetch(options.url);
  let styles = await response.text();

  const modifier = options.modifier ? options.modifier : (css: string) => css;
  styles = modifier(styles);

  instance.addExternalRawStylesheet(styles);
}
