import {createSelector} from '@reduxjs/toolkit';
import {IAccount, IAccountState} from '../reducers/accountSlice';
import {RootState} from "../reducers";


export const selectAccount = (state: RootState): IAccountState => {
    return state.account;
};

export const accountSelector = createSelector(
    [selectAccount],
    (state: IAccountState) => state.account
);

export const accountIdSelector = createSelector(
    [selectAccount],
    (state: IAccountState) => state.account?.id
);

export const accountAvatarSelector = createSelector(
    [accountSelector],
    (account: IAccount) => account?.avatar,
);

export const accountLoadingSelector = createSelector(
    [selectAccount],
    (state: IAccountState) => state.isLoading
);
