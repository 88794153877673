import React from 'react';
import Loader from "../Loader";
import Translation from "../Translation";
import AcquaintancesItem from "./AcquaintancesItem";
import styles from "./styles.module.scss";
import AddAcquaintanceModal from "./AddAcquaintanceModal";
import {of, Subscription} from "rxjs";
import {getAcquaintancesAPI} from "../../api/getAcquaintances";
import {catchError, tap} from "rxjs/operators";
import {connect} from "react-redux";
import {RootState} from "../../store/reducers";
import {authTokenSelector} from "../../store/selectors/authSelectors";
import {IAlertManagerService} from "../../service/alertManagerService";
import {fixInjectedProperties, lazyInject} from "../../ioc";
import {deleteAcquaintanceAPI} from "../../api/deleteAcquaintance";


interface IConnectedAcquaintancesProps {
    readonly authToken: string | null;
}

interface IExternalAcquaintancesProps {
}

interface IAcquaintancesProps extends IConnectedAcquaintancesProps, IExternalAcquaintancesProps {
}

interface IAcquaintancesState {
    readonly acquaintancesList: {[key: string]: any}[] | [];
    readonly isProcessing: boolean;
    readonly isAddContactModalShown: boolean;
}

class Acquaintances extends React.Component<IAcquaintancesProps, IAcquaintancesState> {
    private readonly subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IAcquaintancesProps) {
        super(props);

        this.state = {
            acquaintancesList: [],
            isProcessing: false,
            isAddContactModalShown: false
        };
        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.getAcquaintances();
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <>
                <section className="section">
                    <Loader showLoader={this.state.isProcessing}/>

                    <h1 className="section-title">
                        <Translation text="acquaintances.title"/>
                        <button className="btn btn-primary"
                                onClick={() => this.toggleAddModal()}>
                            <Translation text="acquaintances.add"/>
                        </button>
                    </h1>

                    <div className={styles.contactsWrapper}>
                        {this.state.acquaintancesList.map((item: any) => {
                            return (<AcquaintancesItem key={item.id} contact={item} removeAcquaintance={this.removeAcquaintance}/>)
                        })}
                    </div>
                </section>

                {this.state.isAddContactModalShown &&
                <AddAcquaintanceModal isModalShown={this.state.isAddContactModalShown}
                                      toggleModal={this.toggleAddModal}
                                      authToken={this.props.authToken}
                                      refreshList={this.getAcquaintances}  />}
            </>
        );
    }

    private toggleAddModal = () => {
        this.setState({isAddContactModalShown: !this.state.isAddContactModalShown});
    };

    private getAcquaintances = () => {
        this.setState({isProcessing: true});
        this.subscriptions.push(
            getAcquaintancesAPI(this.props.authToken).pipe(
                tap((response: any) => {
                    this.setState({acquaintancesList: response['hydra:member'] || [], isProcessing: false});
                }),
                catchError((error: any) => {
                    this.setState({isProcessing: false});
                    this.alertManager?.handleApiError(error);
                    return of();
                })
            ).subscribe()
        )
    };

    private removeAcquaintance = (id: string, loaderCallback: () => void, ) => {
        this.subscriptions.push(
            deleteAcquaintanceAPI(this.props.authToken, id).pipe(
                tap(() => {
                    this.alertManager?.addAlert('acquaintances.acquaintanceRemoved');
                    this.getAcquaintances();
                    loaderCallback();
                }),
                catchError((error: any) => {
                    this.setState({isProcessing: false});
                    this.alertManager?.handleApiError(error);
                    return of();
                })
            ).subscribe()
        )
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state)
    }),
    {}
)(Acquaintances);
