import React from 'react';
import {isMobile} from 'react-device-detect';
import Loader, {LoaderType} from "../Loader";


interface IBasicModalProps {
    isModalShown: boolean;
    closeModal: any;
    children?: any;
    isModalLocal?: boolean;
    isModalCustom?: boolean;
    wrapperInlineStyles?: React.CSSProperties;
    showLocalLoader?: boolean;
}

const BasicModal = (props: IBasicModalProps) => (
    <div className={`modal-wrapper
                     ${props.isModalShown ? "visible" : ""}
                     ${props.isModalLocal ? "local" : ""}
                     ${props.isModalCustom ? "custom" : ""}
                     ${isMobile ? "mobile" : ""}`}
          style={props.wrapperInlineStyles}>
        <div className={`modal-background ${props.isModalLocal ? "bg-local" : ""}`}
             onClick={() => props.closeModal && props.closeModal()} />
        <div className="modal-content-container">
            {props.children}

            {props.showLocalLoader && <Loader showLoader={props.showLocalLoader} type={LoaderType.Local}/>}
        </div>
    </div>
);

BasicModal.Header = (props: React.PropsWithChildren<{}>) => (
    <div className="modal-header">
        {props.children}
    </div>
);

BasicModal.Body = (props: React.PropsWithChildren<{}>) => (
    <div className="modal-body">
        {props.children}
    </div>
);

BasicModal.Footer = (props: React.PropsWithChildren<{}>) => (
    <div className="modal-footer">
        {props.children}
    </div>
);

export default BasicModal;
