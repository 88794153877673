import React from 'react';
import Loader from "../Loader";
import WaitingRoom from "./WaitingRoom";
import {ConnectionState, PeerConnection} from "../../webrtcVideoChat/chatTypes";
import ConferenceView from "./ConferenceView";
import {connect} from "react-redux";
import {RootState} from "../../store/reducers";
import {accountIdSelector} from "../../store/selectors/accountSelectors";
import {Subscription, take, tap, timer} from "rxjs";
import {friendsContactListSelector} from "../../store/selectors/friendsContactListSelectors";
import {IModelFriend} from "../../model/friend";

interface IConnectedVideoProps {
    readonly userId: string | number | null;
    readonly friends: [] | IModelFriend[];
}

interface IExternalVideoProps {
    readonly activeWebRTCConnection: PeerConnection | null;
    readonly localMediaDevices: MediaStream | undefined;
    readonly closeConnection: (room: string) => void;
    readonly setSelectedChatRoomId: (id: string | number | null) => void;
    readonly selectedChatRoomId: string | null;
    readonly inviteUser: (invitedId: string | number, invite?: boolean) => void;
    readonly invites: string[];
    readonly invitedUserId: string | number | null;
    readonly kickUser: (id: string | number) => void;
    readonly restartConnection: () => void;
}

interface IVideoProps extends IConnectedVideoProps, IExternalVideoProps {}

interface IVideoState {
    readonly isProcessing: boolean;
    // readonly connectionState: RTCIceConnectionState | undefined
    readonly isConnected: boolean;
    readonly micEnabled: boolean;
    readonly counter: number;
}


class Video extends React.Component<IVideoProps, IVideoState> {
    timer: NodeJS.Timeout;
    reconnectSubscription: Subscription | null = null;

    constructor(props: IVideoProps) {
        super(props);

        this.state = {
            isProcessing: false,
            // connectionState: undefined,
            isConnected: false,
            micEnabled: true,
            counter: 0
        };
    }

    componentDidMount() {
        console.log('STATE IS STARTING: ', this.props.activeWebRTCConnection?.connectionState);
    }

    componentDidUpdate(prevProps: Readonly<IVideoProps> , prevState: Readonly<IVideoState>) {
        const connectionState = this.props.activeWebRTCConnection?.connectionState
        const prevConnectionState = prevProps.activeWebRTCConnection?.connectionState
        if(connectionState !== prevConnectionState) {
            console.log('STATE IS CHANGING: ', connectionState);
            if(connectionState === ConnectionState.CONNECTED) {
                this.timer = setTimeout(() => this.setState({
                    isConnected: true
                }), 3000)
            }
            if(connectionState === ConnectionState.SHORT_DISCONNECTION || connectionState === ConnectionState.CONNECTION_ERROR) {
                this.reconnectSubscription = timer(0, 1000)
                    .pipe(
                        take(31),
                        tap((value: number) => {
                            this.setState({counter: 30 - value});
                        }),
                    )
                    .subscribe(
                        () => null,
                        () => null,
                        () => {
                            //todo close
                            // this.props.closeConnection();
                            this.setState({isConnected: false, counter: 0});
                        },
                    );
            }
            if (connectionState === ConnectionState.CONNECTION_ERROR) {
                this.setState({isConnected: false})
            }
            if(connectionState === ConnectionState.CONNECTION_ENDED) {
                this.setState({isConnected: false})
            }
            if(connectionState === ConnectionState.RECONNECTING) {
                this.setState({isConnected: false})
            }
            if(connectionState === ConnectionState.CONNECTING) {
                this.setState({isConnected: false})
            }
            if(prevProps.selectedChatRoomId && this.props.selectedChatRoomId && prevProps.selectedChatRoomId !== this.props.selectedChatRoomId) {
                this.setState({isConnected: false})
            }
        }
    }

    componentWillUnmount() {
        if(this.props.activeWebRTCConnection) {
            if(this.props.invitedUserId) {
                this.props.inviteUser(this.props.invitedUserId, false);
            }
            this.props.setSelectedChatRoomId(null);
        }

        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.reconnectSubscription?.unsubscribe();
    }

    render() {
        return (
            <section className="section">
                <Loader showLoader={this.state.isProcessing}/>
                {!this.state.isConnected ?
                    <WaitingRoom contact={this.conferencePartner}
                                 selectedChatRoomId={this.props.selectedChatRoomId}
                                 restartConnection={this.props.restartConnection}
                                 toggleMicrophone={this.toggleMicrophone}
                                 micEnabled={this.state.micEnabled}
                                 connectionState={this.props.activeWebRTCConnection?.connectionState}/> :
                    <ConferenceView closeConnection={this.props.closeConnection}
                                    selectedChatRoomId={this.props.selectedChatRoomId}
                                    kickUser={this.isHost ? this.kickUser : null}
                                    contact={this.conferencePartner}
                                    toggleMicrophone={this.toggleMicrophone}
                                    micEnabled={this.state.micEnabled}
                                    localMediaDevices={this.props.localMediaDevices}
                                    activeWebRTCConnection={this.props.activeWebRTCConnection}  />
                }
            </section>
        );
    }

    private kickUser = () => {
        if(this.conferencePartner?.user?.id) {
            return this.props.kickUser(this.conferencePartner?.user?.id)
        }
       return null
    }

    private get isHost() {
        return this.props.selectedChatRoomId === this.props.userId;
    }

    private get conferencePartner(): IModelFriend | undefined {
        if(this.props.selectedChatRoomId !== this.props.userId) {
            // I'm guest
            return this.props.friends.find(friend => `${friend.user?.id}` === `${this.props.selectedChatRoomId}`)
        }
        //I'm host
        return this.props.friends.find(friend => `${friend.user?.id}` === `${this.props.invitedUserId}`)
    }

    private toggleMicrophone = () => {
        const localStream = this.props.localMediaDevices;
        if (!localStream) {
            return;
        }
        const audioTrack = localStream
            .getTracks()
            .find((track: any) => track.kind === 'audio');
        if (audioTrack) {
            this.setState({micEnabled: !audioTrack.enabled});
            audioTrack.enabled = !audioTrack.enabled;
        }
    }
}

export default  connect(
    (state: RootState) => ({
        friends: friendsContactListSelector(state),
        userId: accountIdSelector(state)
    }))(Video);
