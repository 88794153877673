import React from 'react';
import Translation from "../Translation";
import CustomCard from "../CustomCard";
import AccountImageHost, {AccountImageHostType} from "./AccountImageHost";
import {withTranslation, WithTranslation} from "react-i18next";
import AccountForm from "./AccountForm";
import {isMobile } from 'react-device-detect';
import {RootState} from "../../store/reducers";
import {connect} from "react-redux";
import {accountSelector} from "../../store/selectors/accountSelectors";
import {IAccount, refreshAccountStateData} from "../../store/reducers/accountSlice";
import {API_URL} from "../../config/entrypoint";
import {authTokenSelector} from "../../store/selectors/authSelectors";


interface IConnectedSettingsProps {
    readonly authToken: string | null;
    readonly account: IAccount | null;
    readonly refreshAccountStateData: typeof refreshAccountStateData;
}

interface IExternalSettingsProps {}

interface ISettingsProps extends IConnectedSettingsProps, IExternalSettingsProps, WithTranslation {}

interface ISettingsState {
}

class Settings extends React.Component<ISettingsProps, ISettingsState> {

    render() {
        return (
            <section className={`section ${isMobile ? 'section-mobile' : ''}`}>
                <h1 className="section-title">
                    <Translation text="settings.title"/>
                </h1>

                <div className="section-content section-settings">
                    <div className="profile-photo-container">
                        <CustomCard>
                            <CustomCard.Body>
                                <AccountImageHost account={this.props.account} accountImageHostType={AccountImageHostType.PROFILE} onImageChange={this.changeAvatar}/>
                            </CustomCard.Body>
                        </CustomCard>
                    </div>

                    <div>
                        <AccountForm account={this.props.account}/>
                    </div>
                </div>
            </section>
        );
    }

    private changeAvatar = (formData: any) => {
        window
            .fetch(`${API_URL as string}/new-api/shop_users/update_avatar`, {
                method: 'POST',
                body: formData,
                headers: new Headers({
                    Authorization: 'Bearer ' + this.props.authToken,
                }),
            })
            .then((resp: any) => {
                return resp.json().then((fileUploadObj: any) => {
                    if (fileUploadObj) {
                        this.props.refreshAccountStateData();
                    }
                });
            });
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        account: accountSelector(state)
    }),
    {
        refreshAccountStateData
    }
)(withTranslation()(Settings));
