import React from 'react';
import Translation from "../../../../Translation";
import {WithTranslation, withTranslation} from "react-i18next";
import styles from "./styles.module.scss";


interface IConnectedImageProps {
}

interface IExternalImageProps {
    readonly imageUrl: string;
}

interface IImageProps extends IConnectedImageProps, IExternalImageProps, WithTranslation {
}

interface IImageState {
    isMenuShown: boolean;
}

class Image extends React.Component<IImageProps, IImageState> {
    private menuRef: any;

    constructor(props: IImageProps) {
        super(props);

        this.state = {
            isMenuShown: false
        };

        this.menuRef = React.createRef();
    }
    componentDidMount(): void {
        document.addEventListener('mousedown', this.handleOutsideClick);
    }

    componentWillUnmount(): void {
        document.removeEventListener('mousedown', this.handleOutsideClick);
    }

    render() {
        const isProfilePhoto = false;
        return (
            <div className={styles.image}
                 style={this.props.imageUrl ? {backgroundImage: `url('../../../assets/images/${this.props.imageUrl}')`, backgroundColor: 'transparent'} : undefined}>
                {isProfilePhoto ? <div className={styles.profilePhotoIcon}/> : null}
                <button className={`btn btn-image-menu ${styles.menuBtn}`} onClick={() => this.toggleMenu()}/>
                {this.renderMenu()}
            </div>
        );
    }

    private renderMenu = () => {
        if (!this.state.isMenuShown) {
            return null;
        }

        return(
            <div className={styles.imageMenu} ref={this.menuRef}>
                <button onClick={() => this.setProfilePhoto()}>
                    <Translation text="acquaintances.addModal.setProfilePhoto" />
                </button>

                <button className={styles.btnRemove} onClick={() => this.removePhoto()}>
                    <Translation text="acquaintances.addModal.removePhoto" />
                </button>
            </div>
        )
    };

    private toggleMenu = () => {
      this.setState({isMenuShown: !this.state.isMenuShown});
    };

    private handleOutsideClick = (event: any) => {
        if (this.menuRef && this.menuRef.current && !this.menuRef.current.contains(event.target)) {
            this.toggleMenu();
        }
    };

    private removePhoto = () => {
        console.log('remove photo')
    };

    private setProfilePhoto = () => {
        console.log('set as profile photo');
    }
}

export default withTranslation()(Image);
