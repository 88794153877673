import React from 'react';
import {WithTranslation, withTranslation} from "react-i18next";
import styles from "./styles.module.scss";
import AccountImageHost, {AccountImageHostType, ImageFormat} from "../../../Settings/AccountImageHost";
import Image from "./Image";
import {isNotNullOrUndefined} from "../../../../utils/runtimeUtils";
import {connect} from "react-redux";
import {RootState} from "../../../../store/reducers";
import {authTokenSelector} from "../../../../store/selectors/authSelectors";
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {IAlertManagerService} from "../../../../service/alertManagerService";

interface IConnectedProfileImageProps {
    readonly authToken: string | null;
}

interface IExternalProfileImageProps {
    readonly profileImages: any[];
    readonly acquaintanceId?: string;
    readonly acceptedImageFormat?: ImageFormat;
}

interface IProfileImageProps extends IConnectedProfileImageProps, IExternalProfileImageProps, WithTranslation {
}

interface IProfileImageState {
}

class ProfileImage extends React.Component<IProfileImageProps, IProfileImageState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IProfileImageProps) {
        super(props);

        fixInjectedProperties(this);
    }

    render() {
        const hasImages = isNotNullOrUndefined(this.props.profileImages) && this.props.profileImages.length > 0;

        return (
            <div className={`${styles.acquaintanceImages} ${!hasImages ? styles.noImages : ''}`}>
                <div className={styles.imagesWrapper}>
                    <div className={styles.imageUploader}>
                        <AccountImageHost account={null}
                                          accountImageHostType={AccountImageHostType.GENERAL}
                                          acceptedImageFormat={this.props.acceptedImageFormat}
                                          onImageChange={this.onImageChange}
                        />
                    </div>
                    {hasImages ? (
                        this.props.profileImages.map((image: string, index: number) => (
                            <Image imageUrl={image} key={index}/>
                        ))
                    ) : null}
                </div>
            </div>
        );
    };

    private onImageChange = (formData: any) => {
        if (undefined === this.props.acquaintanceId) {
            return;
        }

        window.fetch(
            `${process.env.REACT_APP_API_URL}/new-api/face_recognition/friend/${this.props.acquaintanceId}/picture`,
            {
                method: 'POST',
                body: formData,
                headers: new Headers({
                    Authorization: 'Bearer ' + this.props.authToken,
                }),
            }).then((resp: any) => {
            return resp.json()
                .then((fileUploadObj: any) => {
                    if (fileUploadObj.code) {
                        this.alertManager?.handleApiError(fileUploadObj)
                    } else {
                        this.alertManager?.addAlert('acquaintances.addModal.photoAdded');
                    }
                });
        })
    }
}

export default withTranslation()(connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state)
    }),
    {}
)(ProfileImage));
