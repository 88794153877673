import React from 'react';
import BasicModal from "../../BasicModal";
import Translation from "../../Translation";
import styles from "./styles.module.scss";
import {changeCurrentView, CurrentViewType} from "../../../store/reducers/sagaSlice";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {connect} from "react-redux";


interface IConnectedAddProfilePhotoModalProps {
    readonly changeCurrentView: typeof changeCurrentView;
}

interface IExternalAddProfilePhotoModalProps {
    readonly isModalShown: boolean;
    readonly toggleModal: () => void;
}

interface IAddProfilePhotoModalProps extends IConnectedAddProfilePhotoModalProps, IExternalAddProfilePhotoModalProps, RouteComponentProps {
}

interface IAddProfilePhotoModalState {
}

class AddProfilePhotoModal extends React.Component<IAddProfilePhotoModalProps, IAddProfilePhotoModalState> {

    render() {

        return (
            <BasicModal isModalShown={this.props.isModalShown} closeModal={this.props.toggleModal}>
                <BasicModal.Header>
                    <Translation text="addProfilePhoto.title"/>

                    <button className="btn btn-close" onClick={() => this.props.toggleModal()}/>
                </BasicModal.Header>

                <BasicModal.Body>
                    <div className={styles.description}>
                        <p><Translation text="addProfilePhoto.description"/></p>
                        <button className="btn btn-primary no-outline" onClick={() => this.redirectToSettings()}>
                            <Translation text="addProfilePhoto.addPhoto"/>
                        </button>
                    </div>
                </BasicModal.Body>
            </BasicModal>
        );
    }

    private redirectToSettings = () => {
        this.props.history.push("/panel/settings");
        this.props.changeCurrentView(CurrentViewType.SETTINGS);
        this.props.toggleModal();
    }
}

export default connect(
    () => ({}),
    {
        changeCurrentView
    }
)(withRouter(AddProfilePhotoModal));
