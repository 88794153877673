import {ConnectionState, PeerConnection} from "./chatTypes";


const NULL_PEER_CONNECTION: PeerConnection = {
	callStarted: false,
	peerIsOnline: false,
	connection: null,
	channelId: '',
	peerIsPolite: false,
	makingOffer: false,
	connectionAttemptNumber: 0,
	remoteStream: null,
	userInRoom: null,
	connectionState: ConnectionState.CONNECTION_NOT_INITIATED,
};

export default NULL_PEER_CONNECTION;
