import { combineReducers } from "redux";
import {connectRouter} from "connected-react-router";
import {createMemoryHistory} from "history";
import authSlice, { initLogout } from "./authSlice";
import accountSlice from "./accountSlice";
import alertSlice from "./alertSlice";
import sagaSlice from "./sagaSlice";
import friendsContactListSlice from "./friendsContactListSlice";

const history = createMemoryHistory();

const appReducer = combineReducers({
    auth: authSlice,
    account: accountSlice,
    alert: alertSlice,
    saga: sagaSlice,
    friendsContactList: friendsContactListSlice,
    router: connectRouter(history)
});

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: any) => {
    if (action.type === initLogout.type) {
        return appReducer(undefined, { type: undefined });
    }

    return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
