import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IModelFriend} from "../../model/friend";


export interface IFriendsContactListState {
    friendsList: IModelFriend[] | [];
    isLoading: boolean;
    error: string | null;
}

export interface IChangeFriendsContactList {
    friendsList: IModelFriend[] | [];
}

export interface IChangeFriendsContactListLoading {
    isLoading: boolean;
}

export interface ISetFriendsContactListError {
    error: string | null;
}

export interface IRemoveFriendsContact {
    id: string;
}

export interface IRefreshFriendsList {
    credentials?: string;
}

const initialState: IFriendsContactListState = {
    friendsList: [],
    isLoading: true,
    error: null
};

const friendsContactListSlice = createSlice({
    name: "friendsContactList",
    initialState: initialState,
    reducers: {
        changeFriendsContactList: {
            reducer: (state: IFriendsContactListState, action: PayloadAction<IChangeFriendsContactList>) => {
                return {
                    friendsList: action.payload.friendsList,
                    isLoading: false,
                    error: null
                }
            },
            prepare(friendsList: IModelFriend[] | []) {
                return {
                    payload: {friendsList: friendsList}
                };
            }
        },
        changeFriendsContactListLoading: {
            reducer: (state: IFriendsContactListState, action: PayloadAction<IChangeFriendsContactListLoading>) => {
                return {
                    friendsList: state.friendsList,
                    isLoading: action.payload.isLoading,
                    error: state.error
                }
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading}
                };
            }
        },
        setFriendsContactListError: {
            reducer: (state: IFriendsContactListState, action: PayloadAction<ISetFriendsContactListError>) => {
                return {
                    friendsList: state.friendsList,
                    isLoading: false,
                    error: action.payload.error
                }
            },
            prepare(error: string) {
                return {
                    payload: {error: error}
                };
            }
        },
        refreshFriendsContactList: {
            reducer: (state: IFriendsContactListState) => {
                return {
                    friendsList: state.friendsList,
                    isLoading: true,
                    error: state.error
                };
            },
            prepare(credentials?: string) {
                return {
                    payload: {credentials: credentials}
                }
            }
        },
        removeFriendsContact: {
            reducer: (state: IFriendsContactListState) => {
                return {
                    friendsList: state.friendsList,
                    isLoading: state.isLoading,
                    error: state.error
                }
            },
            prepare(id: string) {
                return {
                    payload: {id: id}
                };
            }
        },
    }
});

export const {
    changeFriendsContactList,
    changeFriendsContactListLoading,
    setFriendsContactListError,
    refreshFriendsContactList,
    removeFriendsContact
} = friendsContactListSlice.actions;
export default friendsContactListSlice.reducer;
