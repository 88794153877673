import {combineEpics, Epic, ofType, StateObservable} from "redux-observable";
import {of} from "rxjs";
import {catchError, debounceTime, mergeMap, switchMap} from 'rxjs/operators';
import {
    changeFriendsContactList,
    IRefreshFriendsList,
    IRemoveFriendsContact,
    refreshFriendsContactList,
    removeFriendsContact,
    setFriendsContactListError
} from "../reducers/friendsContactListSlice";
import {authTokenSelector} from "../selectors/authSelectors";
import {getFriendsAPI} from "../../api/getFriends";
import {addAlert, AlertType} from "../reducers/alertSlice";
import {deleteFriendAPI} from "../../api/deleteFriend";
import {PayloadAction} from "@reduxjs/toolkit";
import {RestQueryParams} from "../../api/base/queryParams";

const refreshFriendsContactListEpic: Epic = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(refreshFriendsContactList.type),
        debounceTime(250),
        switchMap((action: PayloadAction<IRefreshFriendsList>) => {
            const authToken = authTokenSelector(state$.value);
            let queryParams = new RestQueryParams;
            if (action.payload.credentials) {
                queryParams = queryParams.add('credentials', action.payload.credentials);
            }

            return getFriendsAPI(authToken, queryParams).pipe(
                mergeMap((response: any) => {
                    const actions: any[] = [
                        changeFriendsContactList(response['hydra:member'])
                    ];

                    return of(...actions);
                }),
                catchError((error: any) => {
                    const message = getErrorMessage(error);

                    return of(
                        setFriendsContactListError(message),
                        addAlert({
                            message: message,
                            type: AlertType.WARNING,
                        }),
                    );
                })
            )
        }),
    );

const removeFriendsContactEpic: Epic = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(removeFriendsContact.type),
        switchMap((action: PayloadAction<IRemoveFriendsContact>) => {
            const authToken = authTokenSelector(state$.value);

            return deleteFriendAPI(authToken, action.payload.id).pipe(
                mergeMap(() => {
                    const actions: any[] = [
                        refreshFriendsContactList(),
                        addAlert({
                            message: 'contacts.removeContactModal.contactRemoved',
                            type: AlertType.INFO
                        })
                    ];

                    return of(...actions);
                }),
                catchError((error: any) => {
                    const message = getErrorMessage(error);

                    return of(
                        setFriendsContactListError(message),
                        addAlert({
                            message: message,
                            type: AlertType.WARNING,
                        }),
                    );
                })
            )
        }),
    );

const getErrorMessage = (error: any): string => {
    let message = 'contacts.contactListError';

    if (error['hydra:description']) {
        message = error['hydra:description']
    }

    if (error.message) {
        message = error.message
    }

    return message;
};

const friendsContactListEpic = combineEpics(
    refreshFriendsContactListEpic,
    removeFriendsContactEpic
);

export default friendsContactListEpic;
