import React from 'react';
import BasicModal from "../../BasicModal";
import Translation from "../../Translation";
import {Formik} from "formik";
import {WithTranslation, withTranslation} from "react-i18next";
import * as Yup from 'yup';
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {IAlertManagerService} from "../../../service/alertManagerService";
import {of, Subscription} from 'rxjs';
import {addFriendAPI} from "../../../api/addFriend";
import {connect} from "react-redux";
import {RootState} from '../../../store/reducers';
import {authTokenSelector} from "../../../store/selectors/authSelectors";
import {catchError, tap, first} from "rxjs/operators";
import {
    changeFriendsContactList,
    refreshFriendsContactList,
    setFriendsContactListError
} from '../../../store/reducers/friendsContactListSlice';
import {FriendRequest, FriendRequestsTypes} from "../../../webrtcVideoChat/chatTypes";
import {getFriendsAPI} from "../../../api/getFriends";
import {addAlert, AlertType} from "../../../store/reducers/alertSlice";
import {IModelFriend} from "../../../model/friend";


const emailValidationSchema = Yup.object().shape({
    email: Yup.string().email('form.errors.email').required('form.errors.isRequired')
});

interface IConnectedAddContactModalProps {
    readonly authToken: string | null;
    readonly refreshFriendsContactList: typeof refreshFriendsContactList;
    readonly changeFriendsContactList: typeof changeFriendsContactList;
    readonly addAlert: typeof addAlert
}

interface IExternalAddContactModalProps {
    readonly isModalShown: boolean;
    readonly toggleModal: () => void;
    readonly sendFriendRequestChange: (request: FriendRequest) => void
}

interface IAddContactModalProps extends IConnectedAddContactModalProps, IExternalAddContactModalProps, WithTranslation {
}

interface IAddContactModalState {
    email: string;
    isLoading: boolean;
}

class AddContactModal extends React.Component<IAddContactModalProps, IAddContactModalState> {
    private readonly subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IAddContactModalProps) {
        super(props);

        this.state = {
            email: '',
            isLoading: false
        };

        fixInjectedProperties(this);
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        const {t} = this.props;

        return (
            <BasicModal isModalShown={this.props.isModalShown}
                        closeModal={this.props.toggleModal}
                        showLocalLoader={this.state.isLoading}>
                <BasicModal.Header>
                    <Translation text="contacts.inviteContactModal.title"/>

                    <button className="btn btn-close" onClick={() => this.props.toggleModal()}/>
                </BasicModal.Header>

                <BasicModal.Body>
                    <Formik
                        initialValues={{email: this.state.email}}
                        validationSchema={emailValidationSchema}
                        onSubmit={(values) => {
                            this.sendInvitation(values.email);
                        }}>
                        {({
                              values,
                              errors,
                              touched,
                              isValid,
                              handleSubmit,
                              setFieldValue,
                              setFieldTouched,

                          }) => (
                            <form onSubmit={handleSubmit}>
                                <label>Email</label>
                                <input
                                    type="email"
                                    value={values.email}
                                    onChange={changeEvent => {
                                        setFieldTouched('email');
                                        setFieldValue('email', changeEvent.target.value);
                                    }}
                                    name="search"
                                    placeholder={t('form.placeholders.email')}
                                />
                                {errors.email && <div className="form-error">{t(`${errors.email}`)}</div>}
                                <div className="form-btn-wrapper">
                                    <button className="btn btn-primary"
                                            disabled={!isValid || (Object.keys(touched).length === 0 && touched.constructor === Object)}>
                                        <Translation text="contacts.inviteContactModal.inviteContact"/>
                                    </button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </BasicModal.Body>
            </BasicModal>
        );
    }

    private getFriends(email: string) {
        const authToken = this.props.authToken;
        getFriendsAPI(authToken).pipe(
             first(),
             tap((response: any) => {
                 const friends: IModelFriend[] | [] | undefined  = response['hydra:member'];
                 const friendToInvite= friends?.find((fr: IModelFriend) => fr?.user?.email === email);
                 if(friendToInvite?.user?.id) {
                     this.props.sendFriendRequestChange({
                         friendUserId: friendToInvite?.user?.id,
                         type: FriendRequestsTypes.INVITE_FRIEND_REQUEST
                     });
                 }
                 if(friends) {
                     this.props.changeFriendsContactList(response['hydra:member'])
                 }
             }),
             catchError((error: any) => {
                 this.props.addAlert({
                     message: error.message,
                     type: AlertType.ERROR,
                 });
                 return of(error);
             })
        ).subscribe()
    }

    private sendInvitation = (email: string) => {
        this.setState({isLoading: true});
        this.subscriptions.push(
            addFriendAPI(this.props.authToken, email).pipe(
                tap(() => {
                    this.alertManager?.addAlert('contacts.invitationSent');
                    this.getFriends(email);
                    this.setState({isLoading: false});
                    this.props.toggleModal();
                }),
                catchError((error: any) => {
                    this.alertManager?.handleApiError(error);
                    this.setState({isLoading: false});
                    return of(error);
                })
            ).subscribe()
        )
    }
}

export default withTranslation()(connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state)
    }),
    {
        refreshFriendsContactList,
        changeFriendsContactList,
        addAlert
    }
)(AddContactModal));
