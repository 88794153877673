import React from 'react';
import Translation from "../../Translation";
import styles from "./styles.module.scss";
import CustomCard from "../../CustomCard";
import VideoAvatarWrapper from "../../Video/VideoAvatarWrapper";
import {changeCurrentView, CurrentViewType} from "../../../store/reducers/sagaSlice";
import {connect} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {IModelFriend} from "../../../model/friend";
import {RootState} from "../../../store/reducers";
import {friendsContactListSelector} from "../../../store/selectors/friendsContactListSelectors";


interface IConnectedUpcomingCallProps {
    readonly changeCurrentView: typeof changeCurrentView;
    readonly friends: [] | IModelFriend[]
}

interface IExternalUpcomingCallProps {
    readonly setSelectedChatRoomId: (id: string | number) => void;
    readonly invites: string[];
}

interface IUpcomingCallProps extends IConnectedUpcomingCallProps, IExternalUpcomingCallProps, RouteComponentProps {}

interface IUpcomingCallState {
    readonly isProcessing: boolean;
}

class UpcomingCall extends React.Component<IUpcomingCallProps, IUpcomingCallState> {
    constructor(props: IUpcomingCallProps) {
        super(props);

        this.state = {
            isProcessing: false,
        };
    }

    render() {
        return (
            <CustomCard>
                <CustomCard.Header>
                    <Translation text="dashboard.upcomingCalls.title"/>
                </CustomCard.Header>
                <CustomCard.Body>
                    {this.invitesList.map(invite => (
                        <div key={invite.id} className={styles.cardContainer}>
                            <VideoAvatarWrapper contact={null} isSmall={true}/>
                            <h4 className={styles.contactName}>{invite.user?.firstName} {invite.user?.lastName}</h4>
                            <p className={styles.info}><Translation text="dashboard.upcomingCalls.awaitsCall"/></p>
                            <button onClick={() => invite.user?.id ? this.joinConference(invite.user?.id) : null}
                                    className="btn btn-primary">
                                <Translation text="dashboard.upcomingCalls.joinCall"/>
                            </button>
                        </div>
                    ))}
                </CustomCard.Body>
            </CustomCard>
        );
    }
    private joinConference = (id: string | number) => {
        this.props.setSelectedChatRoomId(id);
        this.props.changeCurrentView(CurrentViewType.VIDEO);
        this.props.history.push("/panel/video");
    }

    private get invitesList(): IModelFriend[] {
        const list: IModelFriend[]= []
        this.props.invites.forEach(invite => {
            const friend = this.props.friends.find(fr => fr.user?.id.toString() === invite.toString())
            if(friend) {
                list.push(friend)
            }
        })
        return list;
    }
}

export default connect(
    (state: RootState) => ({
        friends: friendsContactListSelector(state)
    }), {changeCurrentView}
)(withRouter(UpcomingCall));
