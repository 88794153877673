import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import retargetEvents from 'react-shadow-dom-retarget-events';
import storeFactory from "./store";
import {Provider} from "react-redux";
import bootstrapWidgetComponentRuntime, {attachWidgetStore} from "./runtime/add-global-script";
import {ConnectedRouter} from "connected-react-router";
import {PersistGate} from "redux-persist/lib/integration/react";
import {createMemoryHistory} from "history";
import {STORE_KEY} from './constants/etc';
import "./i18n";

const memoryHistory = createMemoryHistory();

class OkoUserPanel extends HTMLElement {

    disconnectedCallback(): void {
    }

    adoptedCallback(): void {
    }

    connectedCallback(): void {
        const widgetParams: any = {
            'authToken': null
        };

        Object.keys(widgetParams).forEach(key => {
            if (this.hasAttribute(key)) {
                widgetParams[key] = this.getAttribute(key) as string;
                if (
                    null !== widgetParams[key] &&
                    undefined !== widgetParams[key] &&
                    (widgetParams[key] as string).trim().length === 0
                ) {
                    widgetParams[key] = null;
                }
            }
        });

        let baseUrl = '/';
        if (this.hasAttribute('baseurl')) {
            baseUrl = this.getAttribute('baseurl') as string;
            if (!baseUrl.endsWith('/')) {
                baseUrl += '/';
            }
        }

        const mountPoint = document.createElement('div');
        const shadowRoot = this.attachShadow({mode: 'open'});
        shadowRoot.appendChild(mountPoint);
        const store = storeFactory(STORE_KEY).store;
        const instance = bootstrapWidgetComponentRuntime(STORE_KEY, shadowRoot, shadowRoot, shadowRoot);
        attachWidgetStore(STORE_KEY, store);
        widgetParams.okoUserPanelAppId = STORE_KEY;

        const linkElem = document.createElement('link');
        linkElem.setAttribute('rel', 'stylesheet');
        linkElem.setAttribute('href', `${baseUrl}current/oko-user-panel.css`);
        instance.addExternalStylesheet(linkElem);

        ReactDOM.render(
            <React.StrictMode>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={storeFactory(STORE_KEY).persistor}>
                        <ConnectedRouter history={memoryHistory}>
                            <App {...widgetParams}/>
                        </ConnectedRouter>
                    </PersistGate>
                </Provider>
            </React.StrictMode>,
            mountPoint
        );

        retargetEvents(shadowRoot);
    }
}

if (undefined === customElements.get('x-oko-user-panel')) {
    customElements.define('x-oko-user-panel', OkoUserPanel);
}
