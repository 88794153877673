import {Observable} from 'rxjs';
import {okoUserPanelAPI} from './provider/okoUserPanelAPI';
import {RestQueryParams} from './base/queryParams';

export function acceptFriendInvitationAPI(authToken: string | null, id: string, isAccepted: boolean): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return okoUserPanelAPI.put(
        `new-api/friends/${id}/resolve_invitation`,
        {accept: isAccepted},
        new RestQueryParams(),
        headers
    );
}
