import React from 'react';
import styles from "./styles.module.scss";
import noProfile from '../../../assets/images/no_profile_img.png';

interface IConnectedVideoAvatarWrapperProps {}

interface IExternalVideoAvatarWrapperProps {
    readonly contact: any;
    readonly isSmall?: boolean;
    readonly class?: string;
}

interface IVideoAvatarWrapperProps extends IConnectedVideoAvatarWrapperProps, IExternalVideoAvatarWrapperProps {}

interface IVideoAvatarWrapperState {}

class VideoAvatarWrapper extends React.Component<IVideoAvatarWrapperProps, IVideoAvatarWrapperState> {

    render() {
        const avatar = this.props.contact?.user?.avatar;

        return (
            <div className={`${styles.avatarWrapper} ${this.props.isSmall ? styles.avatarSm : ''} ${this.props.class ? styles[this.props.class] : ''}`}>
                <div>
                    <div>
                        <div className={styles.avatar}
                             style={{backgroundImage: `url(${avatar ? avatar : noProfile})`, backgroundColor: 'transparent', backgroundSize: 'contain'}} />
                    </div>
                </div>
            </div>
        );
    }
}

export default VideoAvatarWrapper;
