const en = {
  navbar: {
    dashboard: "Kokpit",
    video: "Wideo",
    payments: "Historia płatności",
    functionality: "Funkcjonalności",
    settings: "Ustawienia",
  },
  maintenance: {
    pageNotFound: 'Błąd - nie znaleziono strony'
  },
};

export default en;
