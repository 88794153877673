import React from 'react';
import './App.scss';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import {RootState} from "./store/reducers";
import {currentViewSelector} from "./store/selectors/sagaSelectors";
import {CurrentViewType} from "./store/reducers/sagaSlice";
import {setAuthTokens} from './store/reducers/authSlice';
import PanelHost from "./components/PanelHost";

interface IConnectedAppProps {
    readonly currentView: CurrentViewType;
    readonly setAuthTokens: typeof setAuthTokens;
    readonly authToken?: string | null;
    readonly okoUserPanelAppId?: string | null;
}

interface IAppProps extends IConnectedAppProps,
    RouteComponentProps {
}

interface IAppState {
}

class App extends React.Component<IAppProps, IAppState> {
    componentDidMount(): void {
        // this.props.setAuthTokens(
        //     "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE2NTM5MTAyNDQsImV4cCI6MTY1MzkxMzg0NCwicm9sZXMiOlsiUk9MRV9VU0VSIl0sInVzZXJuYW1lIjoibWFjaWVqLmR1ZGExQGdhemV0YS5wbCIsImVtYWlsIjoibWFjaWVqLmR1ZGExQGdhemV0YS5wbCIsInJvbGUiOiJ1c2VyIiwiaWQiOiJkMDlmMjc5NS1kZGY4LTRmNDMtODNjOC00M2Q5OTZiZDZlOWEiLCJ1c2VyX2lkIjoyNywiZnVsbG5hbWUiOiJNYWNpZWogRHVkYSJ9.pJoLH_4qNF4nRSSlsaCuaDTF2OXxXc6rJLMJjI8i2xvkh1tfItQdR8vt0kODdeEfGZt5cuOaNgmYGqQwuC622tW7UdwkLQ3s_77ebWj1d0-88YjBAp9cSDRmD01I6y9wCB0STARdbLpZv3w6h_DQXEiWwWyosYJfSY2BcqU0999Ht98pl95PlEI-7cvpR3t1rYRGJXAaf4KeP_PQtIKgXAkOgSnu3iUZ-l09TN4ZQmj-t_VsNQ8c2bUjEAgDSPkXefFRSAWIh6zIFc3HhTmIAgMw4bzOfS3ZlF823z_bBhgB0Pk82ApLRzHzGGPqdi_eBYPOxKECRC9JO6VtSpXoGjRSIzVI78SHvFzKbEufR4IJRJfQdGMnjTllzL9D6F-7P7LrwZFX93yzPkjAfc7nJqvUf8Hy1h3J7I_XIXn4-uo3RB3OeuT80otjVycW09ffyg27-0VNQGzE_vJQZLXi9dob26i8suboSZ1J8yY-0d0t6MqcguBjBreQZlTz0Yt1i1YcX3xEck-LVeQWufoLxPuLJC7AICZXzTkHyHwsGOCwHx535KBppsyvrHG1fYrVxlNDsT14rzK8iy_7PfpP82PptQM2x5gw3-tES65UoHHuu9Vv-lZybuIalupasG0UEauo_Pnxbotsy-QVEowyHeduqFFWIA1T6T-WAQtJhUw"
        //     , ''
        // )
        if (undefined !== this.props.authToken && null !== this.props.authToken) {
            this.props.setAuthTokens(
                this.props.authToken, ''
            )
        }
    }

    componentDidUpdate(
        prevProps: Readonly<IAppProps>,
        prevState: Readonly<IAppState>,
        snapshot?: any
    ): void {

        if (this.props.authToken !== prevProps.authToken && (undefined !== this.props.authToken && null !== this.props.authToken)) {
            this.props.setAuthTokens(
                this.props.authToken, ''
            )
        }
    }

    render() {
        return (
            <div className="App">
                <PanelHost/>
            </div>
        );
    }
}

export default connect(
    (state: RootState) => ({
        currentView: currentViewSelector(state)
    }),
    {
        setAuthTokens
    }
)(withRouter(App));
