import {Observable} from 'rxjs';
import {okoUserPanelAPI} from './provider/okoUserPanelAPI';
import {RestQueryParams} from './base/queryParams';
import {IApplicationSettings} from "../store/reducers/sagaSlice";


export function changeApplicationSettingsAPI(authToken: string | null, payload: IApplicationSettings): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return okoUserPanelAPI.put(
        `new-api/face_recognition/config/my`,
        payload,
        new RestQueryParams(),
        headers
    );
}
