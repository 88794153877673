import {Observable} from 'rxjs';
import {okoUserPanelAPI} from './provider/okoUserPanelAPI';
import {RestQueryParams} from './base/queryParams';


export interface IAddAcquaintancePayload {
    name: string;
    surname: string;
    sex: string;
}

export function addAcquaintanceAPI(authToken: string | null, payload: IAddAcquaintancePayload): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return okoUserPanelAPI.post(
        'new-api/face_recognition/friend',
        payload,
        new RestQueryParams(),
        headers
    );
}
