import React from 'react';
import BasicModal from "../../BasicModal";
import Translation from "../../Translation";
import styles from "./styles.module.scss";
import VideoAvatarWrapper from "../../Video/VideoAvatarWrapper";
import {IModelFriend} from "../../../model/friend";
import {connect} from "react-redux";
import {removeFriendsContact} from "../../../store/reducers/friendsContactListSlice";


enum ProfileViewType {
    ViewContact = 'view-contact',
    RemoveContact = 'remove-contact'
}

interface IConnectedContactProfileModalProps {
    readonly removeFriendsContact: typeof removeFriendsContact;
}

interface IExternalContactProfileModalProps {
    readonly isModalShown: boolean;
    readonly toggleModal: () => void;
    readonly item: IModelFriend;
}

interface IContactProfileModalProps extends IConnectedContactProfileModalProps, IExternalContactProfileModalProps {
}

interface IContactProfileModalState {
    profileViewType: ProfileViewType;
    isLoading: boolean;
}

class ContactProfileModal extends React.Component<IContactProfileModalProps, IContactProfileModalState> {
    constructor(props: IContactProfileModalProps) {
        super(props);

        this.state = {
            profileViewType: ProfileViewType.ViewContact,
            isLoading: false
        }
    }

    render() {
        return (
            <BasicModal isModalShown={this.props.isModalShown}
                        closeModal={this.props.toggleModal}
                        isModalCustom={true}
                        showLocalLoader={this.state.isLoading}>
                <BasicModal.Header>
                    <div className={styles.modalHeader}>
                        <button className="btn btn-close" onClick={() => {
                            if (this.state.profileViewType === ProfileViewType.ViewContact) {
                                return this.props.toggleModal();
                            }
                            this.setState({profileViewType: ProfileViewType.ViewContact});
                        }}/>
                    </div>
                </BasicModal.Header>

                <BasicModal.Body>
                    {this.state.profileViewType === ProfileViewType.ViewContact ?
                        this.renderProfileContent() :
                        this.renderRemoveContactContent()
                    }
                </BasicModal.Body>
            </BasicModal>
        );
    }

    private renderProfileContent = () => {
        const item = this.props.item,
            email = item.user ? item.user.email : item.invitationEmail,
            contactName = item.user && item.user.firstName ? `${item.user.firstName} ${item.user?.lastName}` : item.invitationEmail;

        return (
            <>
                <div className={styles.modalDescription}>
                    <VideoAvatarWrapper contact={this.props.item} isSmall={true}/>
                    <p className={styles.name}>{contactName}</p>
                    <p>{email}</p>
                    <p>{this.props.item.user?.phoneNumber}</p>
                </div>
                <div className={styles.actionBtns}>
                    <button className="btn btn-primary no-outline"
                            onClick={() => this.setState({profileViewType: ProfileViewType.RemoveContact})}>
                        <Translation text="contacts.removeContact"/>
                    </button>
                </div>
            </>
        )
    };

    private renderRemoveContactContent = () => {
        const item = this.props.item,
            contactName = item.user && item.user.firstName ? `${item.user.firstName} ${item.user?.lastName}` : item.invitationEmail;

        return (
            <>
                <p className={styles.removeDescription}>
                    <Translation text="contacts.removeContactModal.removeFriendContact"
                                 config={{contactName: contactName}}/>
                </p>
                <div className={`${styles.actionBtns} ${styles.actionsRemove}`}>
                    <button className="btn btn-primary"
                            onClick={() => {
                                this.setState({isLoading: true});
                                this.props.removeFriendsContact(this.props.item.id.toString());
                            }}>
                        <Translation text="contacts.removeContactModal.confirm"/>
                    </button>

                    <button className="btn btn-cancel"
                            onClick={() => this.setState({profileViewType: ProfileViewType.ViewContact})}>
                        <Translation text="contacts.removeContactModal.cancel"/>
                    </button>
                </div>
            </>
        )
    };
}

export default connect(
    () => ({}),
    {
        removeFriendsContact
    }
)(ContactProfileModal);
