import React from 'react';
import styles from '../../styles.module.scss';
import Translation from '../../../Translation';
import micOnIcon from '../../../../assets/images/microphone-on.png';
import micOffIcon from '../../../../assets/images/microphone-off.png';

interface IConnectedConferencePartnerMenuProps {
}

interface IExternalConferencePartnerMenuProps {
	readonly toggleMenu: () => void;
	readonly toggleSound: () => void;
	readonly kickUser: null | (() => void);
	readonly remoteStreamMuted: boolean;
}

interface IConferencePartnerMenuProps extends IConnectedConferencePartnerMenuProps, IExternalConferencePartnerMenuProps {
}

interface IConferencePartnerMenuState {
}

class ConferencePartnerMenu extends React.Component<IConferencePartnerMenuProps, IConferencePartnerMenuState> {
	private readonly menuRef: any;
	constructor(props: IConferencePartnerMenuProps) {
		super(props);

		this.state = {
		};

		this.menuRef = React.createRef();
	}

	componentDidMount(): void {
		document.addEventListener('mousedown', this.handleOutsideClick);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleOutsideClick);
	}

	render() {
		return (
			<div className={styles.contactMenu} ref={this.menuRef}>
				{this.props.kickUser && (
					<div className={`${styles.menuItem} ${styles.danger}`}>
						<button className={styles.kickButton} onClick={this.props.kickUser}>
							<Translation text="video.conference.kickUser" />
						</button>
					</div>
				)}
				<div className={styles.menuItem}>
					<button className={styles.micButton} onClick={this.props.toggleSound}>
						<img className={styles.mic} src={this.props.remoteStreamMuted ? micOffIcon : micOnIcon}/>
						<Translation text={this.props.remoteStreamMuted ? "video.conference.unmuteUser" : "video.conference.muteUser"} />
					</button>
				</div>
			</div>
		);
	};

	private handleOutsideClick = (event: any) => {
		if (this.menuRef && this.menuRef.current && !this.menuRef.current.contains(event.target)) {
			this.props.toggleMenu();
		}
	};
}

export default ConferencePartnerMenu;
