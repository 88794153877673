import React from 'react';
import styles from "./styles.module.scss";
import {changeCurrentView, CurrentViewType} from "../../store/reducers/sagaSlice";
import Tooltip, {TooltipDirection} from "../Tooltip";
import {connect} from "react-redux";
import {RootState} from "../../store/reducers";
import {currentViewSelector} from "../../store/selectors/sagaSelectors";
import {initLogout} from "../../store/reducers/authSlice";
import {RouteComponentProps, withRouter } from 'react-router-dom';


export interface INavMenu {
    step: CurrentViewType;
    icon: string;
    url?: string;
    title?: string;
    isDisabled?: boolean;
    tooltipText?: string;
    onClick?: (event: any, item: any) => void;
}

interface IConnectedNavigationProps {
    readonly currentView: CurrentViewType;
    readonly changeCurrentView: typeof changeCurrentView;
    readonly initLogout: typeof initLogout;
}

interface IExternalNavigationProps {
    navMenu: INavMenu[];
}

interface INavigationProps extends IConnectedNavigationProps, IExternalNavigationProps, RouteComponentProps {}

interface INavigationState {
    activeStep: CurrentViewType;
}

class Navigation extends React.Component<INavigationProps, INavigationState> {
    constructor(props: INavigationProps) {
        super(props);

      this.state = {
        activeStep: CurrentViewType.DASHBOARD,
      };
    }

    componentDidMount(): void {
        this.setState({activeStep: this.props.currentView});
    }

    render() {
        return(
            <div className={styles.navigationWrapper} role="navigation">
                <ul className={styles.navigation}>
                    {this.props.navMenu.map((item: any, index: number) => {
                        return(
                            <li className={styles.navigationItem} key={index}>
                                {this.renderNavItem(item)}
                            </li>
                        )
                    })}
                </ul>

                <button className={styles.btnLogout}
                        onClick={() => this.onLogout()}>
                </button>
            </div>
        );
    }

    private renderNavItem(item: INavMenu) {
        return(
            <button className={`${item.isDisabled ? styles.disabled : ''} 
                     ${this.state.activeStep === item.step ? styles.active : ''}`}
                     onClick={(e) => this.handleClick(e, item)}>
                {item.tooltipText ?
                    <Tooltip content={item.tooltipText} direction={TooltipDirection.RIGHT} >
                                        <span className={`${styles.itemIcon} ${styles[item.icon]}`}/>
                    </Tooltip>
                    : (<span className={`${styles.itemIcon} ${styles[item.icon]}`}/>)
                }
            </button>
        );
    }

    private handleClick = (e: any, item: INavMenu) => {
        if(!item.isDisabled && !item.onClick) {
             this.setState({activeStep: item.step});
             this.props.history.push(`/panel/${item.step}`);
             this.props.changeCurrentView(item.step);

             if (item.url) {
                 this.props.history.push(item.url);
             }
        }

        if(!item.isDisabled && item.onClick) {
            this.setState({activeStep: item.step});
            return item.onClick(e, item);
        }
    };

    private onLogout = () => {
        this.props.initLogout();
    };
}

export default connect(
    (state: RootState) => ({
        currentView: currentViewSelector(state)
    }),
    {
        changeCurrentView,
        initLogout
    }
)(withRouter(Navigation));
