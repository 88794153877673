import React from 'react';
import {isNull} from "../../utils/runtimeUtils";


interface ITabsProps {
    chosenTab: number;
    selectedTab: any;
}

interface ITabsState {
    activeTab: number;
}

class Tabs extends React.Component<ITabsProps, ITabsState> {
    constructor(props: ITabsProps) {
        super(props);
        this.state = {
            activeTab: 0
        }
    }

    componentDidMount() {
        if (this.props.chosenTab !== null) {
            this.select(this.props.chosenTab);
        }
    }

    componentDidUpdate(prevProps: Readonly<ITabsProps>, prevState: Readonly<ITabsState>, snapshot?: any) {
        if (this.props.chosenTab !== prevProps.chosenTab && this.props.chosenTab !== null) {
            this.setState({activeTab: this.props.chosenTab});
        }
    }

    render() {
        return (
            <div className="tabs">
                <ul className="nav-tabs" id="nav-tab" role="tablist">
                    {this.renderTabs()}
                </ul>
                <div className="tab-content-container">
                    {this.renderContent()}
                </div>
            </div>
        );
    }

    private renderContent() {
        return React.Children.map(this.props.children, (item: any) => {
            if (item.props.className !== 'tabs-content') {
                return null;
            }

            let activeTabBody = null;
            item.props.children.forEach((tabBodyDiv: any, index: number) => {
                if (!tabBodyDiv) {
                    return;
                }
                if (index === this.state.activeTab) {
                    activeTabBody = tabBodyDiv
                }
            });

            return activeTabBody;
        });
    };


    private renderTabs = () => {
        return React.Children.map(this.props.children, (item: any) => {
            if (item.props.className !== 'tab-headers') {
                return null;
            }
            const header: any = [];
            item.props.children.forEach((tabHeaderDiv: any, index: number) => {
                if (!tabHeaderDiv) {
                    return;
                }
                const disabled = tabHeaderDiv.props.disabled ? 'disabled' : '';
                const active = this.state.activeTab === index ? 'active' : '';

                header.push(
                    <li id={'header' + index} className={`nav-item ${active}`}
                        onClick={() => this.select(index)}
                        data-toggle="tab"
                        role="tab">
                        <button className={`nav-link ${active} ${disabled}`} id={'header-tab-' + index} data-toggle="tab" role="tab"
                           aria-controls={'header-tab-' + index} aria-selected="false">
                            {tabHeaderDiv}
                        </button>
                    </li>
                );
            });
            return header;
        });
    };

    private select = (activeTab: number | null) => {
        if (isNull(activeTab)) {
            return;
        }
        if (activeTab === this.state.activeTab) {
            return;
        }
        this.props.selectedTab(activeTab);
        this.setState({activeTab: activeTab});
    };
}

export default Tabs;
