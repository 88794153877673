import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface IAuthEntry {
    username: string | null;
    authToken: string | null;
    refreshToken: string | null;
}

export interface IAuthTokens {
    authToken: string;
    refreshToken: string;
}

export interface IAuthState {
    username: string | null;
    authToken: string | null;
    refreshToken: string | null;
    error: string | null;
    isAuthenticated: boolean;
}

export interface ISetAuthTokens {
    readonly authToken: string | null;
    readonly refreshToken: string | null;
}

export interface ISetAuthState {
    readonly username: string | null;
    readonly authToken: string | null;
    readonly refreshToken: string | null;
    readonly isAuthenticated: boolean;
}

export interface ISetAuthError {
    readonly error: string;
}

export interface IChangeIsAuthenticated {
    readonly isAuthenticated: boolean;
}

export interface IRenewAuthToken {
    readonly refreshToken: string;
}

const initialState: IAuthState = {
    username: null,
    authToken: null,
    refreshToken: null,
    error: null,
    isAuthenticated: true
};

const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        setAuthState: {
            reducer: (state: IAuthState, action: PayloadAction<ISetAuthState>) => {
                return {
                    username: action.payload.username,
                    authToken: action.payload.authToken,
                    refreshToken: action.payload.refreshToken,
                    error: state.error,
                    isAuthenticated: action.payload.isAuthenticated
                };
            },
            prepare(
                username: string | null,
                authToken: string | null,
                refreshToken: string | null,
                isAuthenticated: boolean
            ) {
                return {
                    payload: {
                        username: username,
                        authToken: authToken,
                        refreshToken: refreshToken,
                        isAuthenticated: isAuthenticated
                    }
                };
            }
        },
        setAuthStateFailure: {
            reducer: (state: IAuthState, action: PayloadAction<ISetAuthError>) => {
                return {
                    username: state.username,
                    authToken: state.authToken,
                    refreshToken: state.refreshToken,
                    error: action.payload.error,
                    isAuthenticated: state.isAuthenticated
                }
            },
            prepare(error: string) {
                return {
                    payload: {
                        error: error
                    }
                }
            }
        },
        setAuthTokens: {
            reducer: (state: IAuthState, action: PayloadAction<ISetAuthTokens>) => {
                return {
                    username: state.username,
                    authToken: action.payload.authToken,
                    refreshToken: action.payload.refreshToken,
                    error: state.error,
                    isAuthenticated: state.isAuthenticated
                }
            },
            prepare(authToken: string, refreshToken: string) {
                return {
                    payload: {
                        authToken: authToken,
                        refreshToken: refreshToken
                    }
                };
            }
        },
        changeIsAuthenticated: {
            reducer: (state: IAuthState, action: PayloadAction<IChangeIsAuthenticated>) => {
                return {
                    username: state.username,
                    authToken: state.authToken,
                    refreshToken: state.refreshToken,
                    error: state.error,
                    isAuthenticated: action.payload.isAuthenticated
                }
            },
            prepare(isAuthenticated: boolean) {
                return {
                    payload: {
                        isAuthenticated: isAuthenticated
                    }
                };
            }
        },
        renewAuthToken: {
            reducer: (state: IAuthState, action: PayloadAction<IRenewAuthToken>) => {
                return {
                    username: state.username,
                    authToken: state.authToken,
                    refreshToken: state.refreshToken,
                    error: state.error,
                    isAuthenticated: state.isAuthenticated
                }
            },
            prepare(refreshToken: string) {
                return {
                    payload: {
                        refreshToken: refreshToken
                    }
                };
            }
        },
        initLogout: {
            reducer: (state: IAuthState) => {
                return {
                    username: null,
                    authToken: null,
                    refreshToken: null,
                    error: null,
                    isAuthenticated: false
                }
            },
            prepare() {
                return {
                    payload: {}
                }
            }
        }
    }
});

export const {
    setAuthState,
    setAuthTokens,
    setAuthStateFailure,
    changeIsAuthenticated,
    renewAuthToken,
    initLogout
} = authSlice.actions;

export default authSlice.reducer;
