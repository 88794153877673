import {loadJavaScript, loadStylesheet} from "../utils/javaScriptUtils";
import IOkoUserPanelWindow from "./window";

declare const window: any;

function createWidgetInstance(key: string): IOkoUserPanelWindow {
    if (!window.__widgetInstances) {
        window.__widgetInstances = {};
    }
    if (window.__widgetInstances[key]) {
        return window.__widgetInstances[key];
    }
    const instance: any = {};
    window.__widgetInstances[key] = instance;

    return instance;
}

function bootstrapWidgetComponentRuntime(
    id: string,
    root: ShadowRoot | Document,
    scriptTarget: Node,
    stylesheetTarget: Node
): IOkoUserPanelWindow {
    const instance: any = createWidgetInstance(id);
    const _env: { [key: string]: any } = {};

    instance.addRuntimeEnvValue = (key: string, value: any): void => {
        _env[key] = value;
    };

    instance.getRuntimeEnvValue = <T = any>(key: string): T => {
        return _env[key];
    };

    instance.addExternalJavaScript = <T extends Node>(newChild: T): T => {
        return scriptTarget.appendChild(newChild);
    };
    instance.addExternalStylesheet = <T extends Node>(newChild: T): T => {
        return stylesheetTarget.appendChild(newChild);
    };
    instance.addExternalAnyNode = <T extends Node>(newChild: T): T => {
        if (newChild instanceof HTMLLinkElement) {
            return instance.addExternalStylesheet(newChild);
        }

        return instance.addExternalJavaScript(newChild);
    };
    instance.addExternalRawJavaScript = (content: string): void => {
        const script = document.createElement('script');
        script.textContent = content;
        script.async = false;
        instance.addExternalJavaScript(script);
    };

    instance.addExternalRawStylesheet = (content: string): void => {
        const style = document.createElement('style');
        style.textContent = content;
        instance.addExternalStylesheet(style);
    };
    instance.querySelector = (selectors: any): any => {
        return root.querySelector(selectors);
    };
    instance.loadExternalJavaScript = (url: string) => loadJavaScript(instance, {url});
    instance.loadExternalStylesheet = (url: string) => loadStylesheet(instance, {url});
    instance.removeExternalNode = <T extends Node>(child: T): void => {
        if (child instanceof HTMLLinkElement) {
            stylesheetTarget.removeChild(child);
        } else {
            scriptTarget.removeChild(child);
        }
    };
    instance.root = root;
    instance.okoUserPanelAppId = id;

    return instance;
}

export default bootstrapWidgetComponentRuntime;

export function attachWidgetStore(key: string, store: any): void {
    if (!window.__widgetInstances[key]) {
        throw new Error(`Instance with id "${key}" is not defined.`);
    }
    window.__widgetInstances[key].store = store;
}

export function fetchWidgetStore(key: string): any {
    if (null === window.__widgetInstances[key] || undefined === window.__widgetInstances[key]) {
        throw new Error(`Instance with id "${key}" is not defined.`);
    }

    return window.__widgetInstances[key].store;
}
