import React from 'react';
import styles from "./styles.module.scss";
import Translation from "../Translation";

class NotFound extends React.Component {
    render() {
        return (
            <div className={styles.container}>
                <h2 className={styles.header}>404</h2>
                <p className={styles.description}>
                    <Translation text="maintenance.pageNotFound"/>
                </p>
            </div>
        );
    }
}

export default NotFound;
