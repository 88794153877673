import React from 'react';
import {Uploader} from 'rsuite';
import Translation from '../../Translation';
import styles from './styles.module.scss';
import Loader, {LoaderType} from "../../Loader";
import {isSameValue} from "../../../utils/comparatorUtils";


export type FileType = {
    name?: string;
    fileKey?: number | string;
    blobFile?: File;
    status?: "inited" | "uploading" | "error" | "finished";
    progress?: number;
    url?: string;
}

export enum AccountImageHostType {
    PROFILE = 'profile',
    GENERAL = 'general'
}

export enum ImageFormat {
    PNG = ".png",
    JPEG = ".jpeg"
}

export interface IFileUploadListElement {
    name: string;
    fileKey: string;
    url: string;
}

interface IConnectedAccountImageHostProps {
}

interface IExternalAccountImageHostProps {
    readonly onImageChange?: (formData: any) => void;
    readonly account: any;
    readonly accountImageHostType: AccountImageHostType;
    readonly acceptedImageFormat?: ImageFormat;
}

interface IAccountImageHostProps extends IConnectedAccountImageHostProps, IExternalAccountImageHostProps {
}

interface IAccountImageHostState {
    accountImage: string | Blob | null;
    value: any[];
    userImage: IFileUploadListElement | null;
    isProcessing: boolean;
    inputFileUploaded: boolean;
}

class AccountImageHost extends React.Component<IAccountImageHostProps, IAccountImageHostState> {
    constructor(props: IAccountImageHostProps) {
        super(props);
        this.state = {
            accountImage: null,
            userImage: null,
            value: [],
            isProcessing: false,
            inputFileUploaded: false,
        };
    }

    componentDidMount() {
        this.getAccountImage();
    }

    componentDidUpdate(prevProps: Readonly<IAccountImageHostProps>): void {
        if (!isSameValue(this.props.account, prevProps.account)) {
            this.getAccountImage();
        }
    }

    render() {
        if (!this.props.account && this.props.accountImageHostType === AccountImageHostType.PROFILE) {
            return null;
        }

        return (
            <div className={`${styles.userInfoImageWrapper} ${styles['edit-mode']}`}>
                <Loader type={LoaderType.Local} showLoader={this.state.isProcessing}/>
                {this.renderPlaceholder()}
                <Uploader
                    draggable
                    removable={false}
                    multiple={false}
                    shouldQueueUpdate={this.shouldUpdate}
                    className={`${styles.uploadHost} ${this.state.accountImage || this.state.userImage ? 'visible' : ''}`}
                    accept={this.props.acceptedImageFormat ? this.props.acceptedImageFormat : "image/png, image/jpeg"}
                    listType="picture"
                    onChange={this.addFile}
                    autoUpload={false}
                    fileList={this.state.value}
                    action={''}>
                    <div>
                        <div>
							<span className={styles.uploadBtn}>
                                {this.props.accountImageHostType === AccountImageHostType.PROFILE ?
                                    <Translation
                                        text={`settings.${this.state.userImage ? 'changeProfilePhoto' : 'addProfilePhoto'}`}/> :
                                    <Translation text="acquaintances.addModal.addPhoto"/>
                                }
							</span>
                            <span className="sr-only">
								<Translation text={`profileImageComponent.srButtons.${this.state.userImage ? 'edit' : 'add'}`}/>
							</span>
                        </div>
                    </div>
                </Uploader>
            </div>
        );
    }

    private shouldUpdate = (fileList: FileType[], newFile: FileType | FileType[]) => {
        const clonedNewFileObj = Object.assign({}, newFile as any),
            fileItem = clonedNewFileObj[0];
        if (fileItem && fileItem.blobFile) {
            const itShouldUpdate = fileItem.blobFile.size < 1048576;
            if (!itShouldUpdate) {
                // ToDo check if the size of the file is limited
                return true;
                // this.alertManagerService.addAlert('profileImageComponent.buttons.imageTooBig');
            }
            return itShouldUpdate;
        }
        return false;
    };

    private addFile = (file: any) => {
        return this.setState({
            isProcessing: true,
            value: file.slice(file.length - 1),
            accountImage: file.length > 1 ? file[1].blobFile : file[0].blobFile,
        }, () => this.saveImage(this.state.accountImage));
    };

    private saveImage = (file?: any) => {
        if (!this.state.accountImage && !file) {
            return;
        }
        this.setState({isProcessing: false});
        const formData = new FormData();
        const imageData = this.state.accountImage;
        if (!imageData) {
            return;
        }
        formData.append('file', imageData);
        formData.append('public', 'true');

        if (this.props.onImageChange) {
            this.props.onImageChange(formData);
        }

        // window
        //     .fetch(`${API_URL as string}/media_objects`, {
        //         method: 'POST',
        //         body: formData,
        //         headers: new Headers({
        //             Authorization: 'Bearer ' + this.props.authToken,
        //         }),
        //     })
        //     .then((resp: any) => {
        //         return resp.json().then((fileUploadObj: any) => {
        //             if (fileUploadObj) {
        //                 const imageId = fileUploadObj['@id'].replace('/media_object/', '');
        //                 let updatedAccount = deepCloneObject(this.props.account);
        //                 updatedAccount.logo = updatedAccount.logo?.id ? updatedAccount.logo.id : updatedAccount.logo;
        //                 updatedAccount.avatar = imageId;
        //                 this.setState({isProcessing: false, accountImage: null});
        //             }
        //         });
        //     });
    };

    private getAccountImage = () => {
        if (this.props.account && this.props.account.avatar) {
            const imageFile = this.props.account.avatar;
            let mediaList: IFileUploadListElement = {
                name: this.props.accountImageHostType === AccountImageHostType.PROFILE ? 'profile' : 'general',
                fileKey: this.props.account.id,
                url: imageFile,
            };
            this.setState({userImage: mediaList, value: [mediaList]});
        }
        if (!this.props.account?.avatar) {
            this.setState({userImage: null, value: []});
        }
    };

    private renderPlaceholder = () => {
        if (this.state.userImage || this.state.accountImage || this.state.value.length) {
            return null;
        }

        return (
            <div className={`${styles.userPhotoPlaceholder} ${this.props.accountImageHostType === AccountImageHostType.GENERAL ? styles.generalPlaceholder : ''}`}>
                {this.props.accountImageHostType === AccountImageHostType.PROFILE ? <span>{this.renderUserInitials()}</span> : null}
            </div>
        );
    };

    private renderUserInitials = () => {
        if (!this.props.account || !this.props.account.firstName || !this.props.account.lastName) {
            return 'XX';
        }

        if (this.props.account.name) {
            return this.props.account.name.charAt(0) || '';
        }

        return `${this.props.account.firstName.charAt(0)} ${this.props.account.lastName.charAt(0)}`.toUpperCase();
    };
}

export default AccountImageHost;
