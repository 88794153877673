import React from 'react';
import Translation from "../../Translation";
import styles from "./styles.module.scss";
import controlsStyles from '../styles.module.scss';
import VideoAvatarWrapper from "../VideoAvatarWrapper";
import endCallIcon from "../../../assets/images/call-end.png";
import micOnIcon from "../../../assets/images/microphone-on.png";
import micOffIcon from '../../../assets/images/microphone-off.png';
import {RouteComponentProps, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {changeCurrentView, CurrentViewType} from "../../../store/reducers/sagaSlice";
import {ConnectionState} from "../../../webrtcVideoChat/chatTypes";
import {IModelFriend} from "../../../model/friend";
import {Subscription, take, tap, timer} from "rxjs";
import {RootState} from "../../../store/reducers";
import {accountIdSelector} from "../../../store/selectors/accountSelectors";

interface IConnectedWaitingRoomProps {
    readonly changeCurrentView: typeof changeCurrentView;
    readonly userId: number | null;
}

interface IExternalWaitingRoomProps {
    readonly contact: IModelFriend | undefined;
    readonly connectionState:  ConnectionState | undefined;
    readonly selectedChatRoomId: string | null;
    readonly micEnabled: boolean;
    readonly toggleMicrophone: () => void;
    readonly restartConnection: () => void;
}

interface IWaitingRoomProps extends IConnectedWaitingRoomProps, IExternalWaitingRoomProps, RouteComponentProps {}

interface IWaitingRoomState {
    readonly isProcessing: boolean;
    readonly counter: number;
}

class WaitingRoom extends React.Component<IWaitingRoomProps, IWaitingRoomState> {
    callEndEventListener: NodeJS.Timeout;
    reconnectSubscription: Subscription;
    constructor(props: IWaitingRoomProps) {
        super(props);
        this.state = {
            isProcessing: false,
            counter: 30,
        };
    }

    componentDidMount() {
        if(this.props.connectionState === ConnectionState.CONNECTION_ENDED) {
            this.callEndEventListener = setTimeout(() => this.navigateList(), 3000);
        }
    }


    componentDidUpdate(prevProps: Readonly<IWaitingRoomProps>, prevState: Readonly<IWaitingRoomState>) {
        if(this.props.connectionState !== prevProps.connectionState) {
            if(this.props.connectionState === ConnectionState.CONNECTING) {
                this.reconnectSubscription?.unsubscribe();
                this.setState({counter: 30});
            }
            if(this.props.connectionState === ConnectionState.CONNECTION_ENDED) {
                this.callEndEventListener = setTimeout(() => this.navigateList(), 3000);
            }
            if(this.props.connectionState === ConnectionState.CONNECTION_ERROR) {
                this.reconnectSubscription = timer(0, 1000)
                    .pipe(
                        take(31),
                        tap((value: number) => {
                            this.setState({counter: 30 - value});
                        }),
                    )
                    .subscribe(
                        () => null,
                        () => null,
                        () => {
                            console.log('completed! timer');
                            this.setState({counter: 30}, () => this.navigateList());
                        },
                    );
            }
            if(this.props.connectionState === ConnectionState.CONNECTION_FAILED) {

            }
        }
    }

    componentWillUnmount() {
        clearTimeout(this.callEndEventListener);
        this.reconnectSubscription?.unsubscribe();
    }


    render() {
        return (
            <div className={`${styles.waitingRoom} ${this.props.connectionState ? styles?.[this.props.connectionState] : ''}`}>
                <div className={styles.details}>
                    <Translation text={this.message} />
                    {this.props.connectionState !== null && this.props.selectedChatRoomId && (
                        <>
                            <VideoAvatarWrapper class={this.props.connectionState} contact={this.props.contact} />
                            {this.props.contact && <p>{this.props.contact?.user?.firstName} {this.props.contact?.user?.lastName}</p>}
                            {this.props.connectionState === ConnectionState.CONNECTION_FAILED && this.isHost && (
                            <button className={controlsStyles.retryButton} onClick={this.props.restartConnection}>
                                <Translation text={'video.waitingRoom.retryCall'}/>
                            </button>
                            )}
                        </>
                    )}
                </div>

                {this.props.selectedChatRoomId &&
                    <div className={controlsStyles.conferenceButtons}>
                        <button onClick={this.navigateList} className={controlsStyles.endCall}>
                            <img className={controlsStyles.icon} src={endCallIcon}/>
                        </button>
                        <button onClick={this.props.toggleMicrophone}>
                            <img className={controlsStyles.icon} src={this.props.micEnabled ? micOnIcon : micOffIcon}/>
                        </button>
                    </div>
                }
            </div>
        );
    }

    private get isHost(): boolean {
        if(!this.props.selectedChatRoomId || !this.props.userId) {
            return false
        }
        return this.props.selectedChatRoomId.toString() === this.props.userId.toString()
    }

    private navigateList = () => {
        this.props.history.push("/panel/dashboard");
        this.props.changeCurrentView(CurrentViewType.DASHBOARD);
    }

    private get message() {
        if(!this.props.selectedChatRoomId) {
            return "video.waitingRoom.noPartner";
        }
        switch (this.props.connectionState) {
            case ConnectionState.INVITE_SENT_TO_USER_IN_CALL:
                return  'video.waitingRoom.InvitedUserIsInCall'
            case ConnectionState.RECONNECTING:
                return 'video.waitingRoom.reconnecting';
            case ConnectionState.INVITE_SENT:
                return "video.waitingRoom.newConnection";
            case ConnectionState.CONNECTING:
                return "video.waitingRoom.connecting";
            case ConnectionState.CONNECTED:
                return "video.waitingRoom.connected";
            case ConnectionState.CONNECTION_ENDED:
                return "video.waitingRoom.ended";
            case ConnectionState.CONNECTION_ERROR:
            case ConnectionState.SHORT_DISCONNECTION:
                return "video.waitingRoom.connectionError";
            case ConnectionState.CONNECTION_FAILED:
                if(this.isHost) {
                    return "video.waitingRoom.connectionFailedHost";
                }
                return "video.waitingRoom.connectionFailedGuest";
            default:
                return "video.waitingRoom.waiting"
        }
    }
}

export default connect((state: RootState) =>({
    userId: accountIdSelector(state),
}), {changeCurrentView})(withRouter(WaitingRoom));
