const pl = {
    navbar: {
        dashboard: 'Kokpit',
        video: 'Wideo',
        payments: 'Twoje zakupy',
        functionality: 'Funkcjonalności',
        settings: 'Ustawienia',
    },
    mobileTabs: {
      profile: 'Profil użytkownika',
      payments: 'Historia sprzedaży'
    },
    addProfilePhoto: {
        title: 'Uzupełnij Profil!',
        description: 'Brakuje zdjęcia profilowego.',
        addPhoto: 'Ustaw zdjęzie profilowe'
    },
    contacts: {
        title: 'Znajomi',
        status: {
            active: 'Aktywny',
            inactive: 'Nieaktywny',
            invited: 'Zaproszony'
        },
        menu: {
            startVideo: 'Rozpocznij wideokonferencje',
            showProfile: 'Pokaż profil ',
            remove: 'Usuń ze znajomych'
        },
        addNewContact: 'Dodaj nową osobę',
        invitationSent: 'Zaproszenie zostało wysłane',
        removeContact: 'Usuń znjaomego',
        inviteContactModal: {
            title: 'Zaproś znajomego',
            inviteContact: 'Zaproś'
        },
        removeContactModal: {
            removeFriendContact: 'Chcesz usunąć osobę {{ contactName }} z grona znajomych?',
            removeAcquaintanceContact: 'Chcesz usunąć osobę {{ contactName }} z grona osób rozpoznawanych?',
            confirm: 'Potwierdź',
            cancel: 'Anuluj',
            contactRemoved: 'Osoba została usunięta z grona znajomych'
        },
        contactListError: 'Wystąpił błąd. Spróbuj ponownie później',
        noContacts: 'Brak znajomych'
    },
    dashboard: {
        noCamera: 'Brak dostępnych kamer',
        refreshCamera: 'Odśwież',
        selectCamera: 'Wybierz kamerę',
        selectedCamera: 'Wybrana kamera',
        title: 'Pulpit',
        upcomingCalls: {
            title: 'Oczekujące połączenia',
            awaitsCall: 'Czeka aż dołączysz do videokonferencji',
            joinCall: 'Dołącz'
        },
        recentCalls: {
            title: 'Ostatnie połączenia',
            makeCall: 'Zadzwoń ponownie'
        },
        invitations: {
            title: 'Oczekujące zaproszenia do grona znajomych',
            accept: 'Zaakceptuj',
            reject: 'Odrzuc',
            accepted: 'Zaproszenie zostało przyjęte',
            rejected: 'Zaproszenie zostało odrzucone',
            noInvitations: 'Nie masz aktywnych zaproszeń od znajomych'
        },
        friendInvitations: {
            friendRequestIncoming: 'Użytkownik {{name}} zaprasza Cię do znajomych',
            friendRequestAccepted: 'Użytkownik {{name}} zaakceptował zaproszenie',
            friendRequestRejected: 'Użytkownik {{name}} odrzucił zaproszenie',
        }
    },
    video: {
        title: 'Wideo',
        waitingRoom: {
            InvitedUserIsInCall: 'Zaproszony użytkownik prowadzi rozmowę',
            reconnecting: 'Połączenie jest restartowane.',
            waiting: '  Oczekiwanie na:',
            connecting: 'Rozpoczynanie rozmowy z:',
            newConnection: 'Konfiguruję połączenie z:',
            connected: 'Udało się nawiązać połączenie z:',
            ended: 'Połączenie się zakończyło.',
            connectionError: 'Połączenie zostało zerwane. Staramy się je przywrócić',
            connectionFailedHost: 'Nie udało się nawiązać połączenia. Kliknij poniżej by spróbować ponownie.',
            connectionFailedGuest: 'Nie udało się nawiązać połączenia. Poczekaj na dalsze komunikaty.',
            noPartner: 'Nikogo nie zaproszono do rozmowy.'
        },
        conference: {
          kickUser: 'Rozłącz partnera',
          muteUser: 'Wycisz partnera',
          unmuteUser: 'Włącz dźwięk partnera'
        }
    },
    payments: {
        title: 'Twoje zakupy',
        noPayments: 'Brak zakupów',
        table: {
            labels: {
                date: 'Data',
                productsList: 'Listing kupionych przedmiotów',
                priceVat: '(cena brutto)',
                price: 'Cena netto',
                downloadInvoice: 'Pobierz fakturę',
                status: 'Status'
            }
        },
        statuses: {
            cart: 'W koszyku',
            awaiting_payment: 'Oczekiwanie na zapłatę',
            partially_authorized: 'Częściowo autoryzowane',
            authorized: 'Autoryzowane',
            partially_paid: 'Częściowo opłacone',
            cancelled: 'Anulowane ',
            paid: 'Opłacone',
            partially_refunded: 'Częściowo zwrócone',
            refunded: 'Zwrócone'
        }
    },
    functionality: {
        title: 'Funkcjonalności',
        turnVideo: 'Włącz wideo',
        aboutFunctionality: 'Zobacz jak działa ta funkcja:',
        recognizedPeople: 'Osoby rozpoznawane',
        faceRecognition: {
            title: 'Rozpoznawanie twarzy',
            description: 'Today’s users have access to a variety of devices and interfaces that each offer distinct experiences. With the Samsung Design System, we’re able to provide a consistent experience across design elements such as icons, fonts, and charts.',
        },
        trafficLightsRecognition: {
            title: 'Rozpoznawanie sygnalizacji świetlnej',
            description: 'Today’s users have access to a variety of devices and interfaces that each offer distinct experiences. With the Samsung Design System, we’re able to provide a consistent experience across design elements such as icons, fonts, and charts.',
        },
        busNumberRecognition: {
            title: 'Rozpoznawanie numerów (np. Tramwaju)',
            description: 'Today’s users have access to a variety of devices and interfaces that each offer distinct experiences. With the Samsung Design System, we’re able to provide a consistent experience across design elements such as icons, fonts, and charts.',
        },
        textRecognition: {
            title: 'Czytanie tekstu',
            description: 'Today’s users have access to a variety of devices and interfaces that each offer distinct experiences. With the Samsung Design System, we’re able to provide a consistent experience across design elements such as icons, fonts, and charts.',
        },
        personRecognition: {
            title: 'Wykrywanie osób wokół użytkownika i ustalenie odległosci',
            description: 'Today’s users have access to a variety of devices and interfaces that each offer distinct experiences. With the Samsung Design System, we’re able to provide a consistent experience across design elements such as icons, fonts, and charts.',
        },
        trafficRecognition: {
            title: 'Wykrywanie pojazdów wokół użytkownika i ustalenie odległości',
            description: 'Today’s users have access to a variety of devices and interfaces that each offer distinct experiences. With the Samsung Design System, we’re able to provide a consistent experience across design elements such as icons, fonts, and charts.',
        },
        functionalityUpdated: 'Funkcjonalności aplikacji zostały zmienione'
    },
    settings: {
        title: 'Twoje dane',
        addProfilePhoto: 'Dodaj zdjęcie profilowe',
        changeProfilePhoto: 'Zmień zdjęcie profilowe',
        editProfile: {
            title: 'Edytuj dane',
            edit: 'Edytuj',
            save: 'Zapisz'
        },
        editPassword: {
            title: 'Edytuj hasło',
            changePassword: 'Zmień hasło',
            passwordChanged: 'Hasło zostało zmienione'
        },
        personalData: {
            title: 'Dane osobowe',
            firstName: 'Imię',
            lastName: 'Nazwisko',
            password: 'Hasło',
            isBlindPerson: 'Jestem osobą niewidomą',
            yesOption: 'Tak',
            noOption: 'Nie'
        },
        contactData: {
            title: 'Adres odbiorcy',
            email: 'Adres e-mail',
            phone: 'Tel. komórkowy',
        },
        deliveryData: {
            title: 'Adres do faktury',
            companyName: 'Nazwa firmy',
            identificationNumber: 'NIP',
            streetHouseNo: 'Ulica, nr. domu/lokalu',
            postcode: 'Kod pocztowy',
            city: 'Miasto',
            country: 'Kraj/Region'
        }
    },
    acquaintances: {
        title: 'Lista osób rozpoznawanych',
        add: 'Dodaj nową osobę',
        added: 'Dodany: ',
        remove: 'Usuń osobę',
        acquaintanceRemoved: 'Osoba została usunięta',
        acquaintanceAdded: 'Osoba została dodana',
        addPhoto: 'Dodaj nowe zdjęcie',
        addModal: {
            title: 'Dodaj nową osobę rozpoznawaną',
            addAcquaintance: 'Dodaj',
            addPhoto: 'Dodaj zdjęcie',
            setProfilePhoto: 'Ustaw jako profilowe',
            removePhoto: 'Usuń zdjęcie',
            photoAdded: 'Zdjęcie zostało pomyślnie dodane'
        }
    },
    form: {
        labels: {
            email: 'Email',
            firstName: 'Imię',
            lastName: 'Nazwisko',
            currentPassword: 'Obecne hasło',
            newPassword: 'Nowe hasło',
            confirmPassword: 'Powtórz nowe hasło'
        },
        placeholders: {
            search: 'Wyszukaj',
            type: 'Wpisz',
            email: 'Email',
            firstName: 'Imię',
            lastName: 'Nazwisko'
        },
        errors: {
            isRequired: 'Pole wymagane',
            email: 'Adres email jest nieprawidłowy',
            samePasswords: 'Nowe hasło i powtórz hasło muszą się zgadzać'
        }
    },
    conferenceInvites: {
        info: 'Zaprasza do swojej konferencji. Zaproszenie jest aktywne do czasu opuszczenia konferencji przez zapraszającego.',
        infoQuestion: 'Czy chcesz już teraz dołączyć?',
        title: 'Zaproszenie do konferencji',
        acceptInvite: 'Zaakceptuj',
        declineInvite: 'Nie dołączaj'
    },
    maintenance: {
        pageNotFound: 'Błąd - nie znaleziono strony'
    },
    alerts: {
        baseError: 'Coś poszło nie tak. Spróbuj ponownie później',
        accountUpdated: 'Dane zostały pomyślnie zaktualizowane'
    }
};

export default pl;
