import React from 'react';
import BasicModal from "../../BasicModal";
import Translation from "../../Translation";
import {Formik} from "formik";
import {WithTranslation, withTranslation} from "react-i18next";
import * as Yup from 'yup';
import styles from "./styles.module.scss";
import ProfileImage from "./ProfileImage";
import AccountImageHost, {AccountImageHostType} from "../../Settings/AccountImageHost";
import {of, Subscription} from "rxjs";
import {addAcquaintanceAPI, IAddAcquaintancePayload} from "../../../api/addAcquaintance";
import {catchError, tap} from "rxjs/operators";
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {IAlertManagerService} from "../../../service/alertManagerService";


const addAcquaintanceValidationSchema = Yup.object().shape({
    firstName: Yup.string().required('form.errors.isRequired'),
    lastName: Yup.string().required('form.errors.isRequired'),
});

interface IConnectedAddAcquaintanceModalProps {
}

interface IExternalAddAcquaintanceModalProps {
    readonly isModalShown: boolean;
    readonly toggleModal: () => void;
    readonly authToken: string | null;
    readonly refreshList: () => void;
}

interface IAddAcquaintanceModalProps extends IConnectedAddAcquaintanceModalProps, IExternalAddAcquaintanceModalProps, WithTranslation {
}

interface IAddAcquaintanceModalState {
    isLoading: boolean;
}

class AddAcquaintanceModal extends React.Component<IAddAcquaintanceModalProps, IAddAcquaintanceModalState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;
    private readonly subscriptions: Subscription[] = [];

    constructor(props: IAddAcquaintanceModalProps) {
        super(props);

        this.state = {
            isLoading: false
        };
        fixInjectedProperties(this);
    }
    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        const {t} = this.props;

        return (
            <BasicModal isModalShown={this.props.isModalShown}
                        closeModal={this.props.toggleModal}
                        showLocalLoader={this.state.isLoading}>
                <BasicModal.Header>
                    <Translation text="acquaintances.addModal.title"/>

                    <button className="btn btn-close" onClick={() => this.props.toggleModal()}/>
                </BasicModal.Header>

                <div className={styles.acquaintanceModal}>
                    <div className={styles.acquaintanceDetails}>
                        <Formik
                            initialValues={{firstName: '', lastName: ''}}
                            validationSchema={addAcquaintanceValidationSchema}
                            onSubmit={() => {}}>
                            {({
                                  values,
                                  errors,
                                  touched,
                                  isValid,
                                  handleSubmit,
                                  setFieldValue,
                                  setFieldTouched
                              }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="form-control">
                                        <label><Translation text="form.labels.firstName" /></label>
                                        <input
                                            type="text"
                                            value={values.firstName}
                                            onChange={changeEvent => {
                                                setFieldTouched('firstName');
                                                setFieldValue('firstName', changeEvent.target.value);
                                            }}
                                            name="firstName"
                                            placeholder={t('form.placeholders.type')}
                                        />
                                        {errors.firstName && <div className="form-error">{t(`${errors.firstName}`)}</div>}
                                    </div>

                                    <div className="form-control">
                                        <label><Translation text="form.labels.lastName" /></label>
                                        <input
                                            type="text"
                                            value={values.lastName}
                                            onChange={changeEvent => {
                                                setFieldTouched('lastName');
                                                setFieldValue('lastName', changeEvent.target.value);
                                            }}
                                            name="lastName"
                                            placeholder={t('form.placeholders.type')}
                                        />
                                        {errors.lastName && <div className="form-error">{t(`${errors.lastName}`)}</div>}
                                    </div>
                                    <div className="form-btn-wrapper">
                                        <button className="btn btn-primary"
                                                onClick={() => this.addAcquaintance(values)}
                                                disabled={!isValid || (Object.keys(touched).length === 0 && touched.constructor === Object)}>
                                            <Translation text="acquaintances.add"/>
                                        </button>
                                    </div>
                                </form>
                            )}
                        </Formik>

                        <div className={styles.profilePhoto}>
                            {/*todo add images to account*/}
                            <AccountImageHost account={null} accountImageHostType={AccountImageHostType.PROFILE} />
                        </div>
                    </div>

                    {/*todo add profileImages*/}
                    <ProfileImage profileImages={[]} />
                </div>
            </BasicModal>
        );
    }

    private addAcquaintance = (values: {[key: string]: any}) => {
        this.setState({isLoading: true});
        const payload: IAddAcquaintancePayload = {
            name: values.firstName,
            surname: values.lastName,
            sex: ''
        };

        this.subscriptions.push(
            addAcquaintanceAPI(this.props.authToken, payload).pipe(
                tap(() => {
                    this.alertManager?.addAlert('acquaintances.acquaintanceAdded');
                    this.props.refreshList();
                    this.setState({isLoading: false});
                    this.props.toggleModal();
                }),
                catchError((error: any) => {
                    this.setState({isLoading: false});
                    this.alertManager?.handleApiError(error);
                    return of();
                })
            ).subscribe()
        )
    };
}

export default withTranslation()(AddAcquaintanceModal);
