import {Observable} from 'rxjs';
import {okoUserPanelAPI} from "./provider/okoUserPanelAPI";
import {RestQueryParams} from "./base/queryParams";


export function getFriendsAPI(authToken: string | null, params?: RestQueryParams): Observable<any> {
    let headers = undefined,
        queryParams = params ? params : new RestQueryParams();

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return okoUserPanelAPI.get(
        'new-api/friends',
        queryParams,
        headers
    );
}
