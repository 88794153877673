import {createSlice, PayloadAction} from '@reduxjs/toolkit';


const { v4: uuidv4 } = require('uuid');

export enum AlertType {
    INFO = "info",
    WARNING = "warning",
    SUCCESS = "success",
    ERROR = "error",
}

export interface AlertObject {
    message: string;
    id: string;
    displayFor?: number;
    displayDate?: number;
    priority?: number;
    type?: AlertType;
    isTranslated?: boolean;
}

export interface IAddAlertObject {
    message: string;
    displayFor?: number;
    id?: string;
    displayDate?: number;
    priority?: number;
    type?: AlertType;
    isTranslated?: boolean;
}

export interface IAlertState {
    alerts: AlertObject[] | [];
    alertsDisabled: boolean;
}

export interface IAddAlert {
    alert: IAddAlertObject;
}

export interface IRemoveAlert {
    id: string;
}

export interface IChangeAlertsDisabled {
    alertsDisabled: boolean;
}

const initialState: IAlertState = {
    alerts: [],
    alertsDisabled: false
};


const alertSlice = createSlice({
    name: "alert",
    initialState: initialState,
    reducers: {
        addAlert: {
            reducer: (state: IAlertState, action: PayloadAction<IAddAlert>) => {
                const alerts = [...state.alerts];
                const alert: AlertObject = Object.assign(action.payload.alert);

                if (!action.payload.alert.id) {
                    alert.id = uuidv4();
                }
                if (!action.payload.alert.displayDate) {
                    alert.displayDate = Date.now()
                }
                if (!action.payload.alert.displayFor) {
                    alert.displayFor = 5000;
                }
                if (!action.payload.alert.hasOwnProperty('isTranslated')) {
                    alert.isTranslated = true;
                }

                return {
                    alerts: [...alerts, alert],
                    alertsDisabled: state.alertsDisabled
                };
            },
            prepare(alert: IAddAlertObject) {
                return {
                    payload: {alert: alert}
                };
            }
        },
        removeAlert: {
            reducer: (state: IAlertState, action: PayloadAction<IRemoveAlert>) => {
                const alerts = [...state.alerts];

                return {
                    alerts: alerts.filter(alert => {
                        return alert.id !== action.payload.id;
                    }),
                    alertsDisabled: state.alertsDisabled
                };
            },
            prepare(id: string) {
                return {
                    payload: {id: id}
                };
            }
        },
        changeAlertsDisabled: {
            reducer: (state: IAlertState, action: PayloadAction<IChangeAlertsDisabled>) => {
                return {
                    alerts: state.alerts,
                    alertsDisabled: action.payload.alertsDisabled
                };
            },
            prepare(disableErrors: boolean) {
                return {
                    payload: {alertsDisabled: disableErrors}
                };
            }
        }
    }
});

export const {
    addAlert,
    removeAlert,
    changeAlertsDisabled
} = alertSlice.actions;
export default alertSlice.reducer;
