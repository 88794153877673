import React from 'react';
import Loader from "../../Loader";
import Translation from "../../Translation";
import styles from "./styles.module.scss";
import {Field, Formik} from "formik";
import {withTranslation, WithTranslation} from "react-i18next";
import EditPasswordModal from "../EditPassword";
import {deepCloneObject} from "../../../utils/objectUtils";
import {isMobile} from 'react-device-detect';
import {connect} from "react-redux";
import {RootState} from "../../../store/reducers";
import {authTokenSelector} from "../../../store/selectors/authSelectors";
import {IAccount, IUpdateAccountPayload, updateAccount} from "../../../store/reducers/accountSlice";
import {isNotNullOrUndefined} from "../../../utils/runtimeUtils";
import {accountLoadingSelector} from "../../../store/selectors/accountSelectors";


interface IConnectedAccountFormProps {
    readonly authToken: string | null;
    readonly isLoading: boolean;
    readonly updateAccount: typeof updateAccount;
}

interface IExternalAccountFormProps {
    readonly account: IAccount | null;
}

interface IAccountFormProps extends IConnectedAccountFormProps, IExternalAccountFormProps, WithTranslation {
}

interface IAccountFormState {
    readonly isEditPasswordShown: boolean;
    readonly isFormEditable: boolean;
    readonly formValues: any;
}

class AccountForm extends React.Component<IAccountFormProps, IAccountFormState> {
    constructor(props: IAccountFormProps) {
        super(props);

        this.state = {
            isEditPasswordShown: false,
            isFormEditable: false,
            formValues: this.getInitialFormValues()
        };
    }

    render() {
        return (
            <div className={`${styles.formContainer} ${isMobile ? styles.mobileView : ''}`}>
                <Loader showLoader={this.props.isLoading}/>

                <Formik
                    initialValues={this.state.formValues}
                    onSubmit={(values) => {
                        this.toggleEditMode(values);
                    }}>
                    {({values, errors, handleSubmit, setFieldValue, setFieldTouched}) => (
                        <>
                            <div className={styles.actionBtn}>
                                <button className="btn btn-primary" onClick={() => handleSubmit()}>
                                    {this.state.isFormEditable ?
                                        <Translation text="settings.editProfile.save"/> :
                                        <Translation text="settings.editProfile.edit"/>
                                    }
                                </button>

                                <button className="btn btn-primary" onClick={() => this.toggleEditPasswordModal()}>
                                    <Translation text="settings.editPassword.title"/>
                                </button>
                            </div>

                            <form onSubmit={handleSubmit}>

                                <h6 className={styles.profileSubtitle}>
                                    <Translation text="settings.personalData.title"/>
                                </h6>

                                {this.renderInputFormControl(
                                    'email',
                                    'email',
                                    'settings.contactData.email',
                                    'settings.contactData.email',
                                    values,
                                    errors,
                                    setFieldTouched,
                                    setFieldValue
                                )}
                                {this.renderInputFormControl(
                                    'firstName',
                                    'text',
                                    'settings.personalData.firstName',
                                    'settings.personalData.firstName',
                                    values,
                                    errors,
                                    setFieldTouched,
                                    setFieldValue
                                )}
                                {this.renderInputFormControl(
                                    'lastName',
                                    'text',
                                    'settings.personalData.lastName',
                                    'settings.personalData.lastName',
                                    values,
                                    errors,
                                    setFieldTouched,
                                    setFieldValue
                                )}
                                {this.renderInputFormControl(
                                    'phone',
                                    'tel',
                                    'settings.contactData.phone',
                                    'settings.contactData.phone',
                                    values,
                                    errors,
                                    setFieldTouched,
                                    setFieldValue
                                )}

                                {this.renderRadioControl()}


                                <h6 className={styles.profileSubtitle}>
                                    <Translation text="settings.contactData.title"/>
                                </h6>

                                {this.renderInputFormControl(
                                    'deliveryStreet',
                                    'text',
                                    'settings.deliveryData.streetHouseNo',
                                    'settings.deliveryData.streetHouseNo',
                                    values,
                                    errors,
                                    setFieldTouched,
                                    setFieldValue
                                )}

                                {this.renderInputFormControl(
                                    'deliveryPostcode',
                                    'text',
                                    'settings.deliveryData.postcode',
                                    'settings.deliveryData.postcode',
                                    values,
                                    errors,
                                    setFieldTouched,
                                    setFieldValue
                                )}

                                {this.renderInputFormControl(
                                    'deliveryCity',
                                    'text',
                                    'settings.deliveryData.city',
                                    'settings.deliveryData.city',
                                    values,
                                    errors,
                                    setFieldTouched,
                                    setFieldValue
                                )}

                                {this.renderInputFormControl(
                                    'deliveryCountry',
                                    'text',
                                    'settings.deliveryData.country',
                                    'settings.deliveryData.country',
                                    values,
                                    errors,
                                    setFieldTouched,
                                    setFieldValue
                                )}

                                <h6 className={styles.profileSubtitle}>
                                    <Translation text="settings.deliveryData.title"/>
                                </h6>

                                {this.renderInputFormControl(
                                    'companyName',
                                    'text',
                                    'settings.deliveryData.companyName',
                                    'settings.deliveryData.companyName',
                                    values,
                                    errors,
                                    setFieldTouched,
                                    setFieldValue
                                )}

                                {this.renderInputFormControl(
                                    'companyIdentificationNumber',
                                    'text',
                                    'settings.deliveryData.identificationNumber',
                                    'settings.deliveryData.identificationNumber',
                                    values,
                                    errors,
                                    setFieldTouched,
                                    setFieldValue
                                )}

                                {this.renderInputFormControl(
                                    'invoiceStreet',
                                    'text',
                                    'settings.deliveryData.streetHouseNo',
                                    'settings.deliveryData.streetHouseNo',
                                    values,
                                    errors,
                                    setFieldTouched,
                                    setFieldValue
                                )}

                                {this.renderInputFormControl(
                                    'invoicePostcode',
                                    'text',
                                    'settings.deliveryData.postcode',
                                    'settings.deliveryData.postcode',
                                    values,
                                    errors,
                                    setFieldTouched,
                                    setFieldValue
                                )}

                                {this.renderInputFormControl(
                                    'invoiceCity',
                                    'text',
                                    'settings.deliveryData.city',
                                    'settings.deliveryData.city',
                                    values,
                                    errors,
                                    setFieldTouched,
                                    setFieldValue
                                )}

                                {this.renderInputFormControl(
                                    'invoiceCountry',
                                    'text',
                                    'settings.deliveryData.country',
                                    'settings.deliveryData.country',
                                    values,
                                    errors,
                                    setFieldTouched,
                                    setFieldValue
                                )}
                            </form>
                        </>
                    )}
                </Formik>

                {this.state.isEditPasswordShown ? <EditPasswordModal authToken={this.props.authToken}
                                                                     isModalShown={this.state.isEditPasswordShown}
                                                                     toggleModal={this.toggleEditPasswordModal}/> : null}
            </div>
        );
    }


    private toggleEditMode = (values: any) => {
        this.setState({isFormEditable: !this.state.isFormEditable});
        if (!this.state.isFormEditable) {
            this.updateData(values);
        }
    };

    private toggleEditPasswordModal = () => {
        this.setState({isEditPasswordShown: !this.state.isEditPasswordShown})
    };

    private renderInputFormControl = (
        name: string,
        type: string,
        label: string,
        placeholder: string,
        values: { [key: string]: any },
        errors: { [key: string]: any },
        setFieldTouched: (name: string) => void,
        setFieldValue: (name: string, value: string) => void
    ) => {
        const {t} = this.props;

        return (
            <div className={`form-control horizontal ${isMobile ? 'mobile-horizontal' : ''}`}>
                <label><Translation text={label}/></label>
                <input
                    type={type}
                    value={values[name]}
                    onChange={changeEvent => {
                        setFieldTouched(name);
                        setFieldValue(name, changeEvent.target.value);
                    }}
                    disabled={name === 'email' || name === 'deliveryCountry' || name === 'invoiceCountry' ? true : !this.state.isFormEditable}
                    name={name}
                    placeholder={t(placeholder)}
                />
                {errors[name] && <div className="form-error">{errors[name]}</div>}
            </div>
        )
    };

    private renderRadioControl = () => {
        if (this.state.isFormEditable) {
            return (
                <div className={`form-control horizontal ${isMobile ? 'mobile-horizontal' : ''}`}>
                    <label><Translation text="settings.personalData.isBlindPerson"/></label>
                    <div role="group" aria-labelledby="radio-group" className="radio-group">
                        <label className="radio-label">
                            <Field type="radio" name="isBlindPerson" value="yes"/>
                            Tak
                        </label>
                        <label className="radio-label">
                            <Field type="radio" name="isBlindPerson" value="no"/>
                            Nie
                        </label>
                    </div>
                </div>
            )
        }

        return (
            <div className={`${styles.dataItem} ${isMobile ? styles.mobileItem : ''}`}>
                <p className={styles.label}><Translation text="settings.personalData.isBlindPerson"/></p>
                <p className={styles.info}>
                    {this.state.formValues.isBlindPerson === 'yes' ?
                        <Translation text="settings.personalData.yesOption"/> :
                        <Translation text="settings.personalData.noOption"/>
                    }
                </p>
            </div>
        )
    };

    private updateData = (values: any) => {
        if (null === this.props.account) {
            return;
        }
        const formValues = deepCloneObject(values);
        this.setState({formValues});

        const payload: IUpdateAccountPayload = {
            firstName: values.firstName,
            lastName: values.lastName,
            phone: values.phone,
            blind: values.isBlindPerson === 'yes',
            shippingAddress: {
                street: values.deliveryStreet,
                city: values.deliveryCity,
                postCode: values.deliveryPostcode,
                company: '',
                companyCode: '',
            },
            billingAddress: {
                street: values.invoiceStreet,
                city: values.invoiceCity,
                postCode: values.invoicePostcode,
                company: values.companyName,
                companyCode: values.companyIdentificationNumber
            }
        };

        this.props.updateAccount(payload);
    };

    private getInitialFormValues = () => {
        const account = this.props.account,
            billingAddress = account?.billingAddressOutput,
            shippingAddress = account?.shippingAddressOutput;

        return {
            email: account?.email ? account.email : '',
            firstName: account?.firstName ? account.firstName : '',
            lastName: account?.lastName ? account.lastName : '',
            phone: account?.phoneNumber ? account.phoneNumber : '',
            isBlindPerson: isNotNullOrUndefined(this.props.account?.blind) && this.props.account?.blind ? 'yes' : 'no',
            deliveryStreet: shippingAddress?.street ? shippingAddress?.street : '',
            deliveryPostcode: shippingAddress?.postCode ? shippingAddress?.postCode : '',
            deliveryCity: shippingAddress?.city ? shippingAddress?.city : '',
            deliveryCountry: 'Polska',
            companyName: billingAddress?.company ? billingAddress?.company : '',
            companyIdentificationNumber: billingAddress?.companyCode ? billingAddress?.companyCode : '',
            invoiceStreet: billingAddress?.street ? billingAddress?.street : '',
            invoicePostcode: billingAddress?.postCode ? billingAddress?.postCode : '',
            invoiceCity: billingAddress?.city ? billingAddress?.city : '',
            invoiceCountry: 'Polska',
        };
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        isLoading: accountLoadingSelector(state)
    }),
    {
        updateAccount
    }
)(withTranslation()(AccountForm));
