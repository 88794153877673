import {Observable} from 'rxjs';
import {okoUserPanelAPI} from './provider/okoUserPanelAPI';
import {RestQueryParams} from './base/queryParams';

export function deleteAcquaintanceAPI(authToken: string | null, id: string): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return okoUserPanelAPI.delete(
        `new-api/face_recognition/friend/${id}`,
        new RestQueryParams(),
        headers
    );
}
