import {isNotNullOrUndefined} from "./runtimeUtils";


export function deepCloneObject(object: any): any {
    return JSON.parse(JSON.stringify(object));
}

export function isObjectEmpty(obj: any) {
    return isNotNullOrUndefined(obj) && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;
}
