import React from 'react';
import Loader from "../Loader";
import Translation from "../Translation";
import CustomCard from "../CustomCard";
import styles from "./styles.module.scss";
import {Formik} from "formik";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {
    changeApplicationSettings,
    changeCurrentView,
    CurrentViewType,
    IApplicationSettings
} from '../../store/reducers/sagaSlice';
import {of, Subscription} from "rxjs";
import {getApplicationSettingsAPI} from "../../api/getApplicationSettings";
import {fixInjectedProperties, lazyInject} from "../../ioc";
import {IAlertManagerService} from "../../service/alertManagerService";
import {RootState} from "../../store/reducers";
import {authTokenSelector} from "../../store/selectors/authSelectors";
import {catchError, tap} from "rxjs/operators";
import {isNotNullOrUndefined, isNullOrUndefined} from "../../utils/runtimeUtils";
import {changeApplicationSettingsAPI} from "../../api/changeApplicationSettings";
import {applicationSettingsSelector} from "../../store/selectors/sagaSelectors";


interface ISettingsValues {
    faceRecognition: boolean;
    trafficLightsRecognition: boolean;
    busNumberRecognition: boolean;
    textRecognition: boolean;
    personRecognition: boolean;
    trafficRecognition: boolean;
}

const formInitValues: ISettingsValues = {
    faceRecognition: false,
    trafficLightsRecognition: false,
    busNumberRecognition: false,
    textRecognition: false,
    personRecognition: false,
    trafficRecognition: false,
};

interface IConnectedFunctionalityProps {
    readonly authToken: string | null;
    readonly applicationSettings: IApplicationSettings | null;
    readonly changeCurrentView: typeof changeCurrentView;
    readonly changeApplicationSettings: typeof changeApplicationSettings;
}

interface IExternalFunctionalityProps {
}

interface IFunctionalityProps extends IConnectedFunctionalityProps, IExternalFunctionalityProps, RouteComponentProps {
}

interface IFunctionalityState {
    readonly isProcessing: boolean;
    readonly values: ISettingsValues;
}

class Functionality extends React.Component<IFunctionalityProps, IFunctionalityState> {
    private readonly subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IFunctionalityProps) {
        super(props);

        this.state = {
            isProcessing: true,
            values: formInitValues
        };
        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.getApplicationSettings();
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <section className="section">
                <Loader showLoader={this.state.isProcessing}/>

                <h1 className="section-title">
                    <Translation text="functionality.title"/>
                </h1>

                <div className="section-content section-functionality">
                    {Object.keys(this.state.values).map((key: string, index: number) => {
                        return (
                            <CustomCard key={index} containerStyle="functionality-card">
                                <CustomCard.Header>
                                    <div className={styles.functionalityHeader}>
                                        <Translation text={`functionality.${key}.title`}/>

                                        {key === 'faceRecognition' ?
                                            (<button onClick={() => this.redirectToAcquaintances()}
                                                     className="btn btn-secondary">
                                                <Translation text="functionality.recognizedPeople"/>
                                            </button>) : null
                                        }

                                        {this.renderSwitch(key)}
                                    </div>
                                </CustomCard.Header>
                                <CustomCard.Body>
                                    <p className={styles.functionalityDescription}>
                                        <Translation text={`functionality.${key}.description`}/>
                                    </p>
                                    <p className={styles.label}><Translation text="functionality.aboutFunctionality"/>
                                    </p>

                                    <button className="btn btn-primary">
                                        <Translation text="functionality.turnVideo"/>
                                    </button>
                                </CustomCard.Body>
                            </CustomCard>
                        )
                    })}
                </div>
            </section>
        );
    }

    private renderSwitch(item: any) {
        const formControlValue = (this.state.values as any)[item];
        return (
            <Formik
                initialValues={{
                    isActive: formControlValue,
                }}
                onSubmit={values => {
                    this.onInputChange('', values.isActive);
                }}
            >
                {({setFieldValue}) => (
                    <form>
                        <label className={`switch`}>
                            <input type={"checkbox"}
                                   name={item}
                                   checked={formControlValue}
                                   onChange={changeEvent => {
                                       setFieldValue('isActive', changeEvent.target.checked);
                                       this.onInputChange(item, changeEvent.target.checked);
                                   }}
                                   disabled={isNullOrUndefined(formControlValue)}/>
                            <span className="slider"/>
                        </label>
                    </form>
                )}
            </Formik>
        )
    }

    private onInputChange = (name: string, value: boolean) => {
        if (isNotNullOrUndefined(this.props.applicationSettings)) {
            this.setState({isProcessing: true});
            const payload: IApplicationSettings = this.prepareApplicationServicePayload(name, value);
            this.changeApplicationSettings(payload);
        }
    };

    private redirectToAcquaintances = () => {
        this.props.history.push("/panel/acquaintances");
        this.props.changeCurrentView(CurrentViewType.ACQUAINTANCES);
    };

    private getApplicationSettings = () => {
        this.subscriptions.push(
            getApplicationSettingsAPI(this.props.authToken).pipe(
                tap((response: any) => {
                    const applicationSettings = this.updateApplicationSettingsValues(response);
                    this.updateFormValuesFromState(response);
                    this.setState({isProcessing: false});
                    this.props.changeApplicationSettings(applicationSettings);
                }),
                catchError((error: any) => {
                    this.setState({isProcessing: false});
                    this.alertManager?.handleApiError(error);
                    return of();
                })
            ).subscribe()
        )
    };

    private changeApplicationSettings = (payload: IApplicationSettings) => {
        this.subscriptions.push(
            changeApplicationSettingsAPI(this.props.authToken, payload).pipe(
                tap((response: any) => {
                    const applicationSettings = this.updateApplicationSettingsValues(response);
                    this.props.changeApplicationSettings(applicationSettings);
                    this.updateFormValuesFromState(response);
                    this.setState({isProcessing: false});
                    this.alertManager?.addAlert('functionality.functionalityUpdated');
                }),
                catchError((error: any) => {
                    this.setState({isProcessing: false});
                    this.alertManager?.handleApiError(error);
                    return of();
                })
            ).subscribe()
        )
    };

    private updateApplicationSettingsValues = (response: {[key: string]: any}): IApplicationSettings => {
        const applicationSettings: IApplicationSettings = {
            serviceOn: response.serviceOn,
            busNumberRecognition: response.busNumberRecognition,
            busNumberRecognitionWithDistance: response.busNumberRecognitionWithDistance,
            vehiclesDetection: response.vehiclesDetection,
            carDetection: response.carDetection,
            carDetectionWithDistance: response.carDetectionWithDistance,
            tramDetection: response.tramDetection,
            tramDetectionWithDistance: response.tramDetectionWithDistance,
            busDetection: response.busDetection,
            busDetectionWithDistance: response.busDetectionWithDistance,
            trainDetection: response.trainDetection,
            trainDetectionWithDistance: response.trainDetectionWithDistance,
            personDetection: response.personDetection,
            personDetectionWithDistance: response.personDetectionWithDistance,
            trafficLightsRecognition: response.trafficLightsRecognition,
            faceRecognition: response.faceRecognition,
            faceRecognitionWithDistance: response.faceRecognitionWithDistance,
            banknotesRecognition: response.banknotesRecognition,
            textRecognition: response.textRecognition,
            addressRecognition: response.addressRecognition,
            focalLength: response.focalLength,
        };

        return applicationSettings;
    };

    private updateFormValuesFromState = (response: {[key: string]: any}) => {
        const values: ISettingsValues = {
            faceRecognition: response.faceRecognition && response.faceRecognitionWithDistance,
            trafficLightsRecognition: response.trafficLightsRecognition,
            busNumberRecognition: response.busNumberRecognition && response.busNumberRecognitionWithDistance,
            textRecognition: response.textRecognition,
            personRecognition: response.personDetection && response.personDetectionWithDistance,
            trafficRecognition: response.trainDetection && response.trainDetectionWithDistance && response.tramDetection
                && response.tramDetectionWithDistance && response.carDetection && response.carDetectionWithDistance &&
                response.busDetection && response.busDetectionWithDistance
        };

        this.setState({values});
    };

    private prepareApplicationServicePayload = (name: string, value: boolean): IApplicationSettings => {
        const payload: IApplicationSettings = Object.assign({}, this.props.applicationSettings);
        if (name === 'faceRecognition') {
            payload.faceRecognition = value;
            payload.faceRecognitionWithDistance = value;
        }

        if (name === 'trafficLightsRecognition' || name === 'textRecognition') {
            (payload as any)[name] = value;
        }

        if (name === 'busNumberRecognition') {
            payload.busNumberRecognition = value;
            payload.busNumberRecognitionWithDistance = value;
        }

        if (name === 'personRecognition') {
            payload.personDetection = value;
            payload.personDetectionWithDistance = value;
        }

        if (name === 'trafficRecognition') {
            payload.trainDetection = value;
            payload.trainDetectionWithDistance = value;
            payload.tramDetection = value;
            payload.tramDetectionWithDistance = value;
            payload.carDetection = value;
            payload.carDetectionWithDistance = value;
            payload.busDetection = value;
            payload.busDetectionWithDistance = value;
        }

        (name === 'trafficLightsRecognition' && value || name === 'personRecognition' && value || name === 'trafficRecognition' && value) ? payload.vehiclesDetection = true : payload.vehiclesDetection = false;

        return payload;
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        applicationSettings: applicationSettingsSelector(state)
    }),
    {
        changeCurrentView,
        changeApplicationSettings
    }
)(withRouter(Functionality));
