import React from 'react';
import styles from './styles.module.scss';
import Translation from '../../Translation';
import ContactItemMenu from "./ContactItemMenu";
import RemoveContactModal, {RemoveContactType} from "../RemoveContact";
import ContactProfileModal from "../ContactProfileModal";
import {IModelFriend} from "../../../model/friend";


export enum ContactStatus {
    Active = 'active',
    Inactive = 'inactive',
    Invited = 'invited'
}

interface IConnectedContactListItemProps {
}

interface IExternalContactListItemProps {
    readonly item: IModelFriend;
}

interface IContactListItemProps extends IConnectedContactListItemProps, IExternalContactListItemProps {
    readonly setSelectedChatRoomId: (id: string | number | null) => void;
    readonly inviteUser: (invitedId: string | number, invite?: boolean) => void
}

interface IContactListItemState {
    isMenuOpened: boolean;
    selectedItem: any;
    isRemoveContactModalShown: boolean;
    isContactProfileShown: boolean;

}

class ContactListItem extends React.Component<IContactListItemProps, IContactListItemState> {
    constructor(props: IContactListItemProps) {
        super(props);

        this.state = {
            isMenuOpened: false,
            selectedItem: null,
            isRemoveContactModalShown: false,
            isContactProfileShown: false
        }
    }

    render() {
        const item = this.props.item,
            status = item.accepted === null ? ContactStatus.Invited :
            (!item.accepted ? ContactStatus.Inactive : ContactStatus.Invited),
            avatar = item?.user?.avatar,
            username = item.user && item.user.firstName ? `${item.user?.firstName} ${item.user?.lastName}` : item.invitationEmail;
        return (
            <div className={styles.contact}>
                <div className={styles.contactDetailsWrapper}>
                    <div className={styles.avatar}
                         style={avatar ? {backgroundImage: `url(${avatar})`, backgroundColor: 'transparent', backgroundSize: 'contain'} : undefined}/>
                    <div className={styles.contactDetails}>
                        <p className={styles.name}>{username}</p>
                        {!item.accepted && <p className={`${styles.status} ${styles[status]}`}><Translation text={`contacts.status.${status}`} /></p>}
                    </div>
                </div>
                {this.props.item.accepted &&
                    <div onClick={() => this.openMenu(this.props.item)}>
                        <button className={styles.btnMenu}/>
                    </div>
                }


                {this.state.isMenuOpened ? <ContactItemMenu item={this.state.selectedItem}
                                                            setSelectedChatRoomId={this.props.setSelectedChatRoomId}
                                                            inviteUser={this.props.inviteUser}
                                                            toggleMenu={() => this.openMenu(null)}
                                                            toggleRemoveContactModal={this.toggleRemoveContactModal}
                                                            toggleContactProfileModal={this.toggleContactModal}/> : null}

                {this.state.isRemoveContactModalShown ?
                    <RemoveContactModal isModalShown={this.state.isRemoveContactModalShown}
                                        item={this.props.item}
                                        type={RemoveContactType.FRIEND}
                                        toggleModal={() => this.toggleRemoveContactModal()} /> : null}

                {this.state.isContactProfileShown ?
                    <ContactProfileModal isModalShown={this.state.isContactProfileShown}
                                         toggleModal={this.toggleContactModal}
                                         item={this.props.item} /> : null}
            </div>
        );
    };

    private openMenu = (item: any) => {
        this.setState({isMenuOpened: !this.state.isMenuOpened, selectedItem: item})
    };

    private toggleRemoveContactModal = () => {
        this.setState({isRemoveContactModalShown: !this.state.isRemoveContactModalShown})
    };

    private toggleContactModal = () => {
        this.setState({isContactProfileShown: !this.state.isContactProfileShown})
    }
}

export default ContactListItem;
