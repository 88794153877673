import {Observable} from 'rxjs';
import {okoUserPanelAPI} from './provider/okoUserPanelAPI';
import {RestQueryParams} from './base/queryParams';

export function addFriendAPI(authToken: string | null, email: string): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return okoUserPanelAPI.post(
        'new-api/friends',
        {email: email},
        new RestQueryParams(),
        headers
    );
}
