import React from "react";
import {PeerConnection} from "../../../webrtcVideoChat/chatTypes";
import styles from '../styles.module.scss';
import endCallIcon from '../../../assets/images/call-end.png';
import switchCameraIcon from '../../../assets/images/switch-video.png';
import micOnIcon from '../../../assets/images/microphone-on.png';
import micOffIcon from '../../../assets/images/microphone-off.png';
import noProfile from '../../../assets/images/no_profile_img.png';
import ConferencePartnerMenu from "./ConferencePartnerMenu";
import {changeCurrentView, CurrentViewType} from "../../../store/reducers/sagaSlice";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {IModelFriend} from "../../../model/friend";

interface IConnectedConferenceViewProps {
	readonly changeCurrentView: typeof changeCurrentView;
}

interface IExternalConferenceViewProps {
	readonly activeWebRTCConnection: PeerConnection | null;
	readonly localMediaDevices: MediaStream | undefined;
	readonly closeConnection: (room: string) => void;
	readonly micEnabled: boolean;
	readonly toggleMicrophone: () => void;
	readonly contact: IModelFriend | undefined;
	readonly kickUser: null | (() => void);
	readonly selectedChatRoomId: string | null;
}

interface IConferenceViewProps extends IExternalConferenceViewProps, IConnectedConferenceViewProps, RouteComponentProps {}

interface IConferenceViewState {
	isMenuOpened: boolean;
	isRemoteAudioMuted: boolean;
}


class ConferenceView extends React.Component<IConferenceViewProps, IConferenceViewState> {
	videoRef:  React.RefObject<HTMLVideoElement> = React.createRef();
	localVideoRef:  React.RefObject<HTMLVideoElement> = React.createRef();

	constructor(props: IConferenceViewProps) {
		super(props);
		this.state = {
			isMenuOpened: false,
			isRemoteAudioMuted: false,
		}
	}

	componentDidMount() {
		if(this.videoRef.current) {
			this.videoRef.current.srcObject = this.props.activeWebRTCConnection?.remoteStream || null;
		}
		if(this.localVideoRef.current) {
			this.localVideoRef.current.srcObject = this.props.localMediaDevices || null;
		}
	}


	componentDidUpdate(prevProps: Readonly<IConferenceViewProps> ) {
		if(prevProps.activeWebRTCConnection?.remoteStream !== this.props.activeWebRTCConnection?.remoteStream && this.videoRef.current) {
			this.videoRef.current.srcObject = this.props.activeWebRTCConnection?.remoteStream || null;
		}
		if(prevProps.selectedChatRoomId && !this.props.selectedChatRoomId) {
			//Reakcja na kicka - opuszczenie konferencji.
			this.leaveConference()
		}
	}

	render() {
		return (
			<>
				<div className={styles.videoWrapper}>
					<video muted={this.state.isRemoteAudioMuted} autoPlay ref={this.videoRef}/>
					<div className={styles.conferenceButtons}>
						{/*<button>*/}
						{/*	<img className={styles.icon} src={switchCameraIcon}/>*/}
						{/*</button>*/}
						<button onClick={() => this.props.activeWebRTCConnection?.channelId ?
							this.leaveConference() : null}
								className={styles.endCall}>
							<img className={styles.icon} src={endCallIcon}/>
						</button>
						<button onClick={this.props.toggleMicrophone}>
							<img className={styles.icon} src={this.props.micEnabled ? micOnIcon : micOffIcon}/>
						</button>
					</div>
					<div className={styles.localVideoWrapper}>
						<video autoPlay muted ref={this.localVideoRef}/>
					</div>
					<div className={styles.contactDetailsWrapper}
						 onClick={() => this.setState(state => ({isMenuOpened: !state.isMenuOpened}))}>
							{this.state.isMenuOpened && (
								<ConferencePartnerMenu toggleSound={this.toggleRemoteSound}
													   kickUser={this.props.kickUser}
													   remoteStreamMuted={this.state.isRemoteAudioMuted}
													   toggleMenu={() => this.setState(state => ({isMenuOpened: !state.isMenuOpened}))}/>
							)}
						<img className={styles.avatar} src={this.props.contact?.user?.avatar || noProfile}/>
						<div className={styles.contactDetails}>
							{this.props.contact && <p className={styles.name}>{this.props.contact?.user?.firstName} {this.props.contact?.user?.lastName}</p>}
						</div>
					</div>
				</div>
			</>
		);
	}

	private toggleRemoteSound = () => {
		this.setState(state =>({
			isRemoteAudioMuted: !state.isRemoteAudioMuted
		}))
	}

	private leaveConference() {
		this.props.history.push("/panel/dashboard");
		this.props.changeCurrentView(CurrentViewType.DASHBOARD);
	}
}

export default connect(null, {changeCurrentView})(withRouter(ConferenceView));
