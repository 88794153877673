import {PayloadAction} from "@reduxjs/toolkit";
import {combineEpics, Epic, ofType, StateObservable} from "redux-observable";
import {of} from "rxjs";
import {catchError, debounceTime, mergeMap, switchMap} from 'rxjs/operators';
import {
    changeAccountStateLoading,
    IAccountPayload,
    IUpdateAccountPayload,
    refreshAccountStateData,
    setAccountState,
    setAccountStateFailure,
    updateAccount,
} from '../reducers/accountSlice';
import {addAlert, AlertType} from "../reducers/alertSlice";
import {isNotNullOrUndefined} from "../../utils/runtimeUtils";
import {updateUserDataAPI} from "../../api/updateUserData";
import {authTokenSelector} from "../selectors/authSelectors";
import {getUserAPI} from "../../api/getUser";

const refreshAccountStateDataEpic: Epic = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(refreshAccountStateData.type),
        debounceTime(250),
        switchMap(() => {
            const authToken = authTokenSelector(state$.value);

            return getUserAPI(authToken).pipe(
                mergeMap((resp: any) => {
                    const actions: any[] = [
                        changeAccountStateLoading(false),
                    ];

                    actions.push(setAccountState(mapResponseAccountToStateAccount(resp)));

                    return of(...actions);
                }),
                catchError((error: any) => {
                    return of(
                        changeAccountStateLoading(false),
                        setAccountStateFailure(error.toString()),
                        addAlert({
                            message: error.response ? error.response.message : 'alerts.baseError',
                            type: AlertType.WARNING,
                        }),
                    );
                })
            );
        }),
    );

const updateAccountData: Epic = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(updateAccount.type),
        switchMap((action: PayloadAction<IUpdateAccountPayload>): any => {
            return updateUserDataAPI(
                state$.value.auth.authToken,
                state$.value.account.account.id,
                (action.payload as any).account
            ).pipe(
                mergeMap((account: any) => {
                    const stateAccount = state$.value.account.account,
                        accountPayload = {
                            id: account.id,
                            firstName: account.firstName,
                            lastName: account.lastName,
                            phoneNumber: account.phoneNumber,
                            email: stateAccount.email,
                            avatar: stateAccount.avatar,
                            blind: account.blind,
                            shippingAddressOutput: account.shippingAddressOutput ? {
                                street: account.shippingAddressOutput.street,
                                city: account.shippingAddressOutput.city,
                                postCode: account.shippingAddressOutput.postCode,
                                company: account.shippingAddressOutput.company,
                                companyCode: account.shippingAddressOutput.companyCode
                            } : null,
                            billingAddressOutput: account.billingAddressOutput ? {
                                street: account.billingAddressOutput.street,
                                city: account.billingAddressOutput.city,
                                postCode: account.billingAddressOutput.postCode,
                                company: account.billingAddressOutput.company,
                                companyCode: account.billingAddressOutput.companyCode
                            } : null
                        },
                        actions: any[] = updateAccountActions(accountPayload);
                    return of(...actions);
                }),
                catchError((error: any) => {
                    return of(
                        changeAccountStateLoading(false),
                        setAccountStateFailure(getErrorMessage(error)),
                        addAlert({message: getErrorMessage(error), type: AlertType.WARNING})
                    )
                }))
        })
    );

const mapResponseAccountToStateAccount = (response: { [key: string]: any }): IAccountPayload => {
    const shopUser = isNotNullOrUndefined(response.shopUser) ? response.shopUser : null;

    return {
        id: shopUser.id,
        firstName: shopUser?.firstName,
        lastName: shopUser?.lastName,
        phoneNumber: shopUser?.phoneNumber,
        email: shopUser?.email,
        avatar: shopUser?.avatar,
        blind: shopUser?.blind,
        shippingAddressOutput: shopUser.shippingAddressOutput ? {
            street: shopUser.shippingAddressOutput.street,
            city: shopUser.shippingAddressOutput.city,
            postCode: shopUser.shippingAddressOutput.postCode,
            company: shopUser.shippingAddressOutput.company,
            companyCode: shopUser.shippingAddressOutput.companyCode
        } : null,
        billingAddressOutput: shopUser.billingAddressOutput ? {
            street: shopUser.billingAddressOutput.street,
            city: shopUser.billingAddressOutput.city,
            postCode: shopUser.billingAddressOutput.postCode,
            company: shopUser.billingAddressOutput.company,
            companyCode: shopUser.billingAddressOutput.companyCode
        } : null
    };
};

const updateAccountActions = (accountPayload: IAccountPayload, alertMessage?: string): any[] => {
    const message = alertMessage ? alertMessage : "alerts.accountUpdated";
    return [
        setAccountState(accountPayload),
        addAlert({message: message}),
        changeAccountStateLoading(false)
    ]
};

function getErrorMessage(error: any): string {
    let errorMessage = "alerts.baseError";

    if (error) {
        if (error.response && error.response['hydra:description']) {
            errorMessage = error.response['hydra:description']
        } else if (error['hydra:description']) {
            errorMessage = error['hydra:description']
        } else if (error.message) {
            errorMessage = error.message
        }
    }

    return errorMessage;
}

const accountEpic = combineEpics(
    updateAccountData,
    refreshAccountStateDataEpic,
);

export default accountEpic;
