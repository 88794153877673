import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {LanguageLocale} from "../../constants/locales";


export enum CurrentViewType {
    DASHBOARD = 'dashboard',
    VIDEO = 'video',
    PAYMENTS_HISTORY = 'payments',
    FUNCTIONALITY = 'functionality',
    SETTINGS = 'settings',
    FRIENDS = 'friends',
    ACQUAINTANCES = 'acquaintances'
}

export interface IApplicationSettings {
    serviceOn: boolean;
    busNumberRecognition: boolean;
    busNumberRecognitionWithDistance: boolean;
    vehiclesDetection: boolean;
    carDetection: boolean;
    carDetectionWithDistance: boolean;
    tramDetection: boolean;
    tramDetectionWithDistance: boolean;
    busDetection: boolean;
    busDetectionWithDistance: boolean;
    trainDetection: boolean;
    trainDetectionWithDistance: boolean;
    personDetection: boolean;
    personDetectionWithDistance: boolean;
    trafficLightsRecognition: boolean;
    faceRecognition: boolean;
    faceRecognitionWithDistance: boolean;
    banknotesRecognition: boolean;
    textRecognition: boolean;
    addressRecognition: boolean;
    focalLength: number;
}
export interface ISagaState {
    activeLanguage: LanguageLocale;
    currentView: CurrentViewType;
    applicationSettings: IApplicationSettings | null;
    showLoader: boolean;
    isProfilePhotoSet: boolean;
}

export interface IChangeLanguage {
    activeLanguage: LanguageLocale;
}

export interface IChangeCurrentView {
    currentView: CurrentViewType;
}

export interface IChangeShowLoader {
    showLoader: boolean;
}

const initialState: ISagaState = {
    activeLanguage: LanguageLocale.PL,
    currentView: CurrentViewType.DASHBOARD,
    applicationSettings: null,
    showLoader: false,
    isProfilePhotoSet: false
};

const sagaSlice = createSlice({
    name: "saga",
    initialState: initialState,
    reducers: {
        changeActiveLanguage: {
            reducer: (state: ISagaState, action: PayloadAction<IChangeLanguage>) => {
                return {
                    activeLanguage: action.payload.activeLanguage,
                    currentView: state.currentView,
                    applicationSettings: state.applicationSettings,
                    showLoader: state.showLoader,
                    isProfilePhotoSet: state.isProfilePhotoSet
                }
            },
            prepare(activeLanguage: LanguageLocale) {
                return {
                    payload: {activeLanguage: activeLanguage}
                };
            }
        },
        changeCurrentView: {
            reducer: (state: ISagaState, action: PayloadAction<IChangeCurrentView>) => {
                return {
                    activeLanguage: state.activeLanguage,
                    currentView: action.payload.currentView,
                    applicationSettings: state.applicationSettings,
                    showLoader: state.showLoader,
                    isProfilePhotoSet: state.isProfilePhotoSet
                }
            },
            prepare(currentView: CurrentViewType) {
                return {
                    payload: {currentView: currentView}
                };
            }
        },
        changeShowLoader: {
            reducer: (state: ISagaState, action: PayloadAction<IChangeShowLoader>) => {
                return {
                    activeLanguage: state.activeLanguage,
                    currentView: state.currentView,
                    applicationSettings: state.applicationSettings,
                    showLoader: action.payload.showLoader,
                    isProfilePhotoSet: state.isProfilePhotoSet
                };
            },
            prepare(showLoader: boolean) {
                return {
                    payload: {showLoader: showLoader}
                };
            }
        },
        changeIsProfilePhotoSet: {
            reducer: (state: ISagaState, action: PayloadAction<{isPhotoSet: boolean}>) => {
                return {
                    activeLanguage: state.activeLanguage,
                    currentView: state.currentView,
                    applicationSettings: state.applicationSettings,
                    showLoader: state.showLoader,
                    isProfilePhotoSet: action.payload.isPhotoSet
                };
            },
            prepare(isPhotoSet: boolean) {
                return {
                    payload: {isPhotoSet: isPhotoSet}
                };
            }
        },
        changeApplicationSettings: {
            reducer: (state: ISagaState, action: PayloadAction<{applicationSettings: IApplicationSettings}>) => {
                return {
                    activeLanguage: state.activeLanguage,
                    currentView: state.currentView,
                    applicationSettings: action.payload.applicationSettings,
                    showLoader: state.showLoader,
                    isProfilePhotoSet: state.isProfilePhotoSet
                };
            },
            prepare(applicationSettings: IApplicationSettings) {
                return {
                    payload: {applicationSettings}
                };
            }
        },
    }
});

export const {
    changeActiveLanguage,
    changeCurrentView,
    changeShowLoader,
    changeIsProfilePhotoSet,
    changeApplicationSettings
} = sagaSlice.actions;

export default sagaSlice.reducer;
