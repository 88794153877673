import React from 'react';
import Translation from "../../Translation";
import CustomCard from "../../CustomCard";
import styles from "./styles.module.scss";
import RemoveContactModal, {RemoveContactType} from "../../Contacts/RemoveContact";
import AddPhotoModal from "../AddPhotoModal";
import Moment from "react-moment";


interface IConnectedAcquaintancesItemProps {}

interface IExternalAcquaintancesItemProps {
    readonly contact: any;
    readonly removeAcquaintance?: (id: string, loaderCallback: () => void, ) => void;
}

interface IAcquaintancesItemProps extends IConnectedAcquaintancesItemProps, IExternalAcquaintancesItemProps {}

interface IAcquaintancesItemState {
    readonly isProcessing: boolean;
    readonly isRemoveContactModalShown: boolean;
    readonly isAddPhotoModalShown: boolean;
}

class AcquaintancesItem extends React.Component<IAcquaintancesItemProps, IAcquaintancesItemState> {
    constructor(props: IAcquaintancesItemProps) {
        super(props);

        this.state = {
            isProcessing: false,
            isRemoveContactModalShown: false,
            isAddPhotoModalShown: false
        };
    }

    render() {
        return (
            <>
                <CustomCard>
                    <CustomCard.Body>
                        <div className={styles.acquaintances}>
                           <div className={styles.contactDetails}>
                                <div className={styles.avatarPlaceholder}>
                                </div>

                               <div className={styles.contact}>
                                   <p className={styles.contactName}>{this.props.contact.name} {this.props.contact.surname}</p>
                                   <p className={styles.date}>
                                       <span><Translation text="acquaintances.added" /></span>
                                       <Moment interval={0}
                                               locale={"pl"}
                                               format={"DD.MM.YYYY"}>
                                           {this.props.contact.created}
                                       </Moment>
                                   </p>
                               </div>
                           </div>
                            <div className={styles.contactActions}>
                                <button className="btn btn-no-outline"
                                        onClick={() => this.toggleAddPhotoModal()}>
                                    <img src={require("../../../assets/images/plus_circle_outline.png")} alt="Dodaj zdjęcie"/>
                                    <Translation text="acquaintances.addPhoto" />
                                </button>

                                <button className="btn btn-no-outline"
                                        onClick={() => this.toggleRemoveContactModal()}>
                                    <img src={require("../../../assets/images/remove_user_icon.png")} alt="Dodaj zdjęcie"/>
                                    <Translation text="acquaintances.remove" />
                                </button>
                            </div>
                        </div>
                    </CustomCard.Body>
                </CustomCard>

                {this.state.isRemoveContactModalShown ?
                    <RemoveContactModal isModalShown={this.state.isRemoveContactModalShown}
                                        item={this.props.contact}
                                        type={RemoveContactType.ACQUAINTANCE}
                                        toggleModal={() => this.toggleRemoveContactModal()}
                                        removeAcquaintance={this.props.removeAcquaintance}/>
                    : null
                }

                {this.state.isAddPhotoModalShown ?
                    <AddPhotoModal isModalShown={this.state.isAddPhotoModalShown}
                                   toggleModal={this.toggleAddPhotoModal}
                                   selectedContact={this.props.contact} />
                    : null
                }
            </>
        );
    }

    private toggleRemoveContactModal = () => {
        this.setState({isRemoveContactModalShown: !this.state.isRemoveContactModalShown})
    };

    private toggleAddPhotoModal = () => {
        this.setState({isAddPhotoModalShown: !this.state.isAddPhotoModalShown})
    }
}

export default AcquaintancesItem;
