import React from 'react';
import BasicModal from "../../BasicModal";
import Translation from "../../Translation";
import {Formik} from "formik";
import {WithTranslation, withTranslation} from "react-i18next";
import * as Yup from 'yup';
import Loader, {LoaderType} from "../../Loader";
import styles from "./styles.module.scss";
import {isMobile} from 'react-device-detect';
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {IAlertManagerService} from "../../../service/alertManagerService";
import {of, Subscription} from 'rxjs';
import {changePasswordAPI} from "../../../api/changePassword";
import {catchError, tap} from "rxjs/operators";


const passwordValidationSchema = Yup.object().shape({
    currentPassword: Yup.string().required('form.errors.isRequired'),
    newPassword: Yup.string().required('form.errors.isRequired'),
    confirmPassword: Yup.string().required('form.errors.isRequired')
        .oneOf([Yup.ref('newPassword'), null], 'form.errors.samePasswords')
});

interface IConnectedEditPasswordModalProps {
}

interface IExternalEditPasswordModalProps {
    readonly authToken: string | null;
    readonly isModalShown: boolean;
    readonly toggleModal: () => void;
}

interface IEditPasswordModalProps extends IConnectedEditPasswordModalProps, IExternalEditPasswordModalProps, WithTranslation {
}

interface IEditPasswordModalState {
    isLoading: boolean;
}

class EditPasswordModal extends React.Component<IEditPasswordModalProps, IEditPasswordModalState> {
    private readonly subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IEditPasswordModalProps) {
        super(props);

        this.state = {
            isLoading: false
        };

        fixInjectedProperties(this);
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        const {t} = this.props;

        return (
            <BasicModal isModalShown={this.props.isModalShown} closeModal={this.props.toggleModal}>
                <BasicModal.Header>
                    <Translation text="settings.editPassword.title"/>

                    <button className="btn btn-close" onClick={() => this.props.toggleModal()}/>
                </BasicModal.Header>

                <BasicModal.Body>
                    <div className={styles.modalContent}>
                        <Formik
                            initialValues={{currentPassword: '', newPassword: '', confirmPassword: ''}}
                            validationSchema={passwordValidationSchema}
                            onSubmit={(values) => {
                                this.changePassword(values);
                            }}>
                            {({
                                  values,
                                  errors,
                                  touched,
                                  isValid,
                                  handleSubmit,
                                  setFieldValue,
                                  setFieldTouched,

                              }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="form-control">
                                        <label><Translation text="form.labels.currentPassword"/></label>
                                        <input
                                            type="password"
                                            value={values.currentPassword}
                                            onChange={changeEvent => {
                                                setFieldTouched('currentPassword');
                                                setFieldValue('currentPassword', changeEvent.target.value);
                                            }}
                                            name="currentPassword"
                                            placeholder={t('form.placeholders.type')}
                                        />
                                        {errors.currentPassword &&
                                        <div className="form-error">{t(`${errors.currentPassword}`)}</div>}
                                    </div>


                                    <div className="form-control">
                                        <label><Translation text="form.labels.newPassword"/></label>
                                        <input
                                            type="password"
                                            value={values.newPassword}
                                            onChange={changeEvent => {
                                                setFieldTouched('newPassword');
                                                setFieldValue('newPassword', changeEvent.target.value);
                                            }}
                                            name="newPassword"
                                            placeholder={t('form.placeholders.type')}
                                        />
                                        {errors.newPassword &&
                                        <div className="form-error">{t(`${errors.newPassword}`)}</div>}
                                    </div>

                                    <div className="form-control">
                                        <label><Translation text="form.labels.confirmPassword"/></label>
                                        <input
                                            type="password"
                                            value={values.confirmPassword}
                                            onChange={changeEvent => {
                                                setFieldTouched('confirmPassword');
                                                setFieldValue('confirmPassword', changeEvent.target.value);
                                            }}
                                            name="confirmPassword"
                                            placeholder={t('form.placeholders.type')}
                                        />
                                        {errors.confirmPassword &&
                                        <div className="form-error">{t(`${errors.confirmPassword}`)}</div>}
                                    </div>

                                    <div className={`form-btn-wrapper ${isMobile ? "mobile-btn-wrapper" : ""}`}>
                                        <button className="btn btn-primary"
                                                onClick={() => console.log('Add contact')}
                                                disabled={!isValid || (Object.keys(touched).length === 0 && touched.constructor === Object)}>
                                            <Translation text="settings.editPassword.changePassword"/>
                                        </button>
                                    </div>
                                </form>
                            )}
                        </Formik>

                        <Loader showLoader={this.state.isLoading} type={LoaderType.Local}/>
                    </div>
                </BasicModal.Body>
            </BasicModal>
        );
    }

    private changePassword = (values: { [key: string]: any }) => {
        this.setState({isLoading: true});

        this.subscriptions.push(
            changePasswordAPI(this.props.authToken, values.currentPassword, values.newPassword).pipe(
                tap(() => {
                    this.setState({isLoading: false});
                    this.alertManager?.addAlert('settings.editPassword.passwordChanged');
                    this.props.toggleModal();
                }),
                catchError((error: any) => {
                    this.setState({isLoading: false});
                    this.props.toggleModal();
                    this.alertManager?.handleApiError(error);
                    return of();
                })
            ).subscribe()
        )
    }
}

export default withTranslation()(EditPasswordModal);
